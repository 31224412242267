
import { Component, Vue, Prop } from 'vue-property-decorator';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpAmmatillinenArvioinninKohdealueet from '@/components/EpAmmatillinen/EpAmmatillinenArvioinninKohdealueet.vue';
import EpAmmatillinenOsaalueet from '@/components/EpAmmatillinen/EpAmmatillinenOsaalueet.vue';
import GeneerinenArviointiTaulukko from '@/components/EpAmmatillinen/GeneerinenArviointiTaulukko.vue';
import EpOsaAlueListaus from '@/components/EpToteutussuunnitelma/EpOsaAlueListaus.vue';
import { OmaOsaAlueDtoTyyppiEnum, OpetussuunnitelmaKaikkiDtoJulkaisukieletEnum, Koodistot } from '@shared/api/amosaa';
import EpToteutukset from '@/components/EpToteutussuunnitelma/EpToteutukset.vue';
import EpAmmattitaitovaatimukset from '@shared/components/EpAmmattitaitovaatimukset/EpAmmattitaitovaatimukset.vue';
import * as _ from 'lodash';
import { Kielet } from '@shared/stores/kieli';

@Component({
  components: {
    EpFormContent,
    EpContentViewer,
    EpCollapse,
    EpAmmatillinenArvioinninKohdealueet,
    EpAmmatillinenOsaalueet,
    GeneerinenArviointiTaulukko,
    EpOsaAlueListaus,
    EpToteutukset,
    EpAmmattitaitovaatimukset,
  },
})
export default class EpToteutussuunnitelmaTutkinnonosa extends Vue {
  @Prop({ required: true })
  private sisaltoviite!: any;

  @Prop({ required: true })
  private perusteenTutkinnonosaViite!: any;

  @Prop({ required: true })
  private perusteenTutkinnonosa!: any;

  @Prop({ required: true })
  private kuvat!: any[];

  @Prop({ required: true })
  private arviointiasteikot!: any[];

  @Prop({ required: false })
  private julkaisukielet?: OpetussuunnitelmaKaikkiDtoJulkaisukieletEnum[];

  private tutkintonimikkeetJaOsaamisalatKoodit: any | null = null;
  private toteutukset: any | null = null;

  async mounted() {
    this.tutkintonimikkeetJaOsaamisalatKoodit = _.chain(await Promise.all(
      _.chain(this.sisaltoviite.tosa?.toteutukset)
        .map(toteutus => toteutus.koodit)
        .flatten()
        .uniq()
        .map(koodi => Koodistot.getKoodistoKoodiByUri(koodi))
        .value()),
    ).map('data')
      .map(koodi => {
        return {
          ...koodi,
          nimi: _.mapValues(_.keyBy(koodi.metadata, v => _.toLower(v.kieli)), v => v.nimi),
        };
      })
      .keyBy('koodiUri')
      .value();

    this.toteutukset = _.map(this.sisaltoviite.tosa?.toteutukset, toteutus => {
      return {
        ...toteutus,
        tutkintonimikkeetJaOsaamisalat: _.map(toteutus.koodit, koodi => this.tutkintonimikkeetJaOsaamisalatKoodit[koodi]),
      };
    });
  }

  get hasKuvaus() {
    return this.sisaltoviite.tekstiKappale.teksti || (this.perusteenTutkinnonosa && this.perusteenTutkinnonosa.kuvaus);
  }

  get luotu() {
    if (this.perusteenTutkinnonosa) {
      return this.perusteenTutkinnonosa.luotu;
    }
  }

  get muokattu() {
    if (this.perusteenTutkinnonosa) {
      return this.perusteenTutkinnonosa.muokattu;
    }
    else if (this.sisaltoviite.tosa) {
      return this.sisaltoviite.tosa.muokattu;
    }
  }

  get laajuus() {
    if (this.perusteenTutkinnonosaViite) {
      return this.perusteenTutkinnonosaViite.laajuus;
    }
    else if (this.sisaltoviite.tosa.omatutkinnonosa) {
      return this.sisaltoviite.tosa.omatutkinnonosa.laajuus;
    }
  }

  get koodiFields() {
    return [{
      key: 'nimi',
      label: this.$t('nimi') as string,
      thStyle: { width: '40%' },
      formatter: (value:any) => {
        return this.$kaanna(value);
      },
    }, {
      key: 'koodiArvo',
      label: this.$t('koodi') as string,
    }] as any[];
  }

  get kieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get osaAlueet() {
    return _.chain(this.sisaltoviite.osaAlueet)
      .map(osaAlue => {
        return {
          ...osaAlue,
          perusteenOsaAlue: _.find(this.perusteenOsaAlueet, pOsaAlue => pOsaAlue.id === osaAlue.perusteenOsaAlueId),
        };
      })
      .filter(osaAlue => !!osaAlue.nimi[this.kieli])
      .value();
  }

  get pakollisetOsaAlueet() {
    return _.filter(this.osaAlueet, oa => oa.tyyppi === _.toLower(OmaOsaAlueDtoTyyppiEnum.PAKOLLINEN));
  }

  get valinnaisetOsaAlueet() {
    return _.filter(this.osaAlueet, oa => oa.tyyppi === _.toLower(OmaOsaAlueDtoTyyppiEnum.VALINNAINEN));
  }

  get paikallisetOsaAlueet() {
    return _.filter(this.osaAlueet, oa => oa.tyyppi === _.toLower(OmaOsaAlueDtoTyyppiEnum.PAIKALLINEN));
  }

  get perusteenPakollisetOsaAlueet() {
    if (this.perusteenTutkinnonosa) {
      return this.perusteenOsaAlueetFiltered(['pakollinen', true]);
    }
  }
  get perusteenValinnaisetOsaAlueet() {
    if (this.perusteenTutkinnonosa) {
      return this.perusteenOsaAlueetFiltered(['pakollinen', false]);
    }
  }

  perusteenOsaAlueetFiltered(osaamistavoiteFilter) {
    return _.chain(this.perusteenOsaAlueet)
      .map(osaAlue => {
        return {
          ...osaAlue,
          osaamistavoitteet: _.filter(osaAlue.osaamistavoitteet, osaamistavoiteFilter),
        };
      })
      .filter(osaAlue => _.size(osaAlue.osaamistavoitteet) > 0)
      .filter(osaAlue => !!osaAlue.nimi[this.kieli])
      .value();
  }

  get perusteenOsaAlueet() {
    return this.perusteenTutkinnonosa?.osaAlueet;
  }

  get hasAmmattitaitovaatimukset() {
    return !_.isEmpty(this.sisaltoviite.tosa.omatutkinnonosa.ammattitaitovaatimukset?.kohdealueet) || !_.isEmpty(this.sisaltoviite.tosa.omatutkinnonosa.ammattitaitovaatimukset?.vaatimukset);
  }

  get hasAmmattitaitovaatimuksetLista() {
    return !_.chain(this.sisaltoviite.tosa.omatutkinnonosa.ammattitaitovaatimuksetLista)
      .map('vaatimuksenKohteet')
      .flatMap()
      .isEmpty()
      .value();
  }

  get hasTutkinnonosakohtainenArviointi() {
    return !_.isEmpty(this.perusteenTutkinnonosa?.arviointi?.arvioinninKohdealueet);
  }

  get arvoinninTyyppi() {
    if (this.sisaltoviite.tosa.omatutkinnonosa.geneerinenarviointi) {
      return 'geneerinen';
    }

    if (_.size(_.get(this.sisaltoviite.tosa, 'omaTutkinnonosa.arviointi.arvioinninKohdealueet')) > 0) {
      return 'tutkinnonosakohtainen';
    }
  }
}
