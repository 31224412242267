// tslint:disable
/**
 * ePerusteet ylops rajapinta
 * Spring MVC API based on the swagger 3.0 specification
 *
 * The version of the OpenAPI document: v3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AihekokonaisuudetBaseDto
 */
export interface AihekokonaisuudetBaseDto {
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetBaseDto
     */
    uuidTunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuudetBaseDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuudetBaseDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuudetBaseDto
     */
    yleiskuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof AihekokonaisuudetBaseDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetBaseDto
     */
    muokkaaja?: string;
}
/**
 * 
 * @export
 * @interface AihekokonaisuudetDto
 */
export interface AihekokonaisuudetDto {
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetDto
     */
    uuidTunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuudetDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuudetDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuudetDto
     */
    yleiskuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof AihekokonaisuudetDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {AihekokonaisuudetDto}
     * @memberof AihekokonaisuudetDto
     */
    parent?: AihekokonaisuudetDto;
    /**
     * 
     * @type {Array<AihekokonaisuusDto>}
     * @memberof AihekokonaisuudetDto
     */
    aihekokonaisuudet?: Array<AihekokonaisuusDto>;
}
/**
 * 
 * @export
 * @interface AihekokonaisuudetJarjestaDto
 */
export interface AihekokonaisuudetJarjestaDto {
    /**
     * 
     * @type {Array<AihekokonaisuusJarjestysDto>}
     * @memberof AihekokonaisuudetJarjestaDto
     */
    aihekokonaisuudet: Array<AihekokonaisuusJarjestysDto>;
}
/**
 * 
 * @export
 * @interface AihekokonaisuudetOpsDto
 */
export interface AihekokonaisuudetOpsDto {
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetOpsDto
     */
    uuidTunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuudetOpsDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuudetOpsDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuudetOpsDto
     */
    yleiskuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof AihekokonaisuudetOpsDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetOpsDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {AihekokonaisuudetBaseDto}
     * @memberof AihekokonaisuudetOpsDto
     */
    parent?: AihekokonaisuudetBaseDto;
    /**
     * 
     * @type {Array<AihekokonaisuusOpsDto>}
     * @memberof AihekokonaisuudetOpsDto
     */
    aihekokonaisuudet?: Array<AihekokonaisuusOpsDto>;
}
/**
 * 
 * @export
 * @interface AihekokonaisuudetPerusteOpsDto
 */
export interface AihekokonaisuudetPerusteOpsDto {
    /**
     * 
     * @type {AihekokonaisuudetDto}
     * @memberof AihekokonaisuudetPerusteOpsDto
     */
    perusteen?: AihekokonaisuudetDto;
    /**
     * 
     * @type {AihekokonaisuudetOpsDto}
     * @memberof AihekokonaisuudetPerusteOpsDto
     */
    paikallinen?: AihekokonaisuudetOpsDto;
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetPerusteOpsDto
     */
    kommentti?: string;
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetPerusteOpsDto
     */
    tunniste?: string;
}
/**
 * 
 * @export
 * @interface AihekokonaisuusDto
 */
export interface AihekokonaisuusDto {
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuusDto
     */
    tunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuusDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuusDto
     */
    yleiskuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuusDto
     */
    jnro?: number;
    /**
     * 
     * @type {AihekokonaisuusDto}
     * @memberof AihekokonaisuusDto
     */
    parent?: AihekokonaisuusDto;
    /**
     * 
     * @type {Date}
     * @memberof AihekokonaisuusDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuusDto
     */
    muokkaaja?: string;
}
/**
 * 
 * @export
 * @interface AihekokonaisuusJarjestysDto
 */
export interface AihekokonaisuusJarjestysDto {
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuusJarjestysDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface AihekokonaisuusOpsDto
 */
export interface AihekokonaisuusOpsDto {
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuusOpsDto
     */
    tunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuusOpsDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuusOpsDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuusOpsDto
     */
    yleiskuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuusOpsDto
     */
    jnro?: number;
    /**
     * 
     * @type {AihekokonaisuusDto}
     * @memberof AihekokonaisuusOpsDto
     */
    parent?: AihekokonaisuusDto;
    /**
     * 
     * @type {Date}
     * @memberof AihekokonaisuusOpsDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuusOpsDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {AihekokonaisuusDto}
     * @memberof AihekokonaisuusOpsDto
     */
    perusteen?: AihekokonaisuusDto;
}
/**
 * 
 * @export
 * @interface AihekokonaisuusSaveDto
 */
export interface AihekokonaisuusSaveDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuusSaveDto
     */
    otsikko: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AihekokonaisuusSaveDto
     */
    yleiskuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface AipePerusteenSisaltoDto
 */
export interface AipePerusteenSisaltoDto {
    /**
     * 
     * @type {TekstiKappaleViiteDto}
     * @memberof AipePerusteenSisaltoDto
     */
    sisalto?: TekstiKappaleViiteDto;
    /**
     * 
     * @type {Array<PerusteLaajaalainenosaaminenDto>}
     * @memberof AipePerusteenSisaltoDto
     */
    laajaalaisetosaamiset?: Array<PerusteLaajaalainenosaaminenDto>;
}
/**
 * 
 * @export
 * @interface DokumenttiDto
 */
export interface DokumenttiDto {
    /**
     * 
     * @type {number}
     * @memberof DokumenttiDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DokumenttiDto
     */
    opsId?: number;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    luoja?: string;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    kieli?: DokumenttiDtoKieliEnum;
    /**
     * 
     * @type {Date}
     * @memberof DokumenttiDto
     */
    aloitusaika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DokumenttiDto
     */
    valmistumisaika?: Date;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    tila?: DokumenttiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    virhekoodi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiDto
     */
    julkaisuDokumentti?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoTilaEnum {
    EIOLE = 'EI_OLE',
    JONOSSA = 'JONOSSA',
    LUODAAN = 'LUODAAN',
    EPAONNISTUI = 'EPAONNISTUI',
    VALMIS = 'VALMIS'
}

/**
 * 
 * @export
 * @interface DokumenttiKuvaDto
 */
export interface DokumenttiKuvaDto {
    /**
     * 
     * @type {number}
     * @memberof DokumenttiKuvaDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DokumenttiKuvaDto
     */
    opsId?: number;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiKuvaDto
     */
    kieli?: DokumenttiKuvaDtoKieliEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiKuvaDto
     */
    kansikuva?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiKuvaDto
     */
    ylatunniste?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiKuvaDto
     */
    alatunniste?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DokumenttiKuvaDtoKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface EsiopetuksenPerusteenSisaltoDto
 */
export interface EsiopetuksenPerusteenSisaltoDto {
    /**
     * 
     * @type {TekstiKappaleViiteDto}
     * @memberof EsiopetuksenPerusteenSisaltoDto
     */
    sisalto?: TekstiKappaleViiteDto;
}
/**
 * 
 * @export
 * @interface EtusivuDto
 */
export interface EtusivuDto {
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    opetussuunnitelmatJulkaistut?: number;
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    opetussuunnitelmatKeskeneraiset?: number;
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    pohjatJulkaistut?: number;
    /**
     * 
     * @type {number}
     * @memberof EtusivuDto
     */
    pohjatKeskeneraiset?: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    file: any;
}
/**
 * 
 * @export
 * @interface JarjestysDto
 */
export interface JarjestysDto {
    /**
     * 
     * @type {number}
     * @memberof JarjestysDto
     */
    oppiaineId?: number;
    /**
     * 
     * @type {number}
     * @memberof JarjestysDto
     */
    jnro?: number;
}
/**
 * 
 * @export
 * @interface KayttajanTietoDto
 */
export interface KayttajanTietoDto {
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    kayttajanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    kutsumanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    etunimet?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    sukunimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    oidHenkilo?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    kieliKoodi?: string;
    /**
     * 
     * @type {object}
     * @memberof KayttajanTietoDto
     */
    yhteystiedot?: object;
}
/**
 * 
 * @export
 * @interface KeskeinenSisaltoalueDto
 */
export interface KeskeinenSisaltoalueDto {
    /**
     * 
     * @type {number}
     * @memberof KeskeinenSisaltoalueDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KeskeinenSisaltoalueDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KeskeinenSisaltoalueDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KeskeinenSisaltoalueDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof KeskeinenSisaltoalueDto
     */
    piilotettu?: boolean;
}
/**
 * 
 * @export
 * @interface Kommentti2019Dto
 */
export interface Kommentti2019Dto {
    /**
     * 
     * @type {string}
     * @memberof Kommentti2019Dto
     */
    thread?: string;
    /**
     * 
     * @type {string}
     * @memberof Kommentti2019Dto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof Kommentti2019Dto
     */
    reply?: string;
    /**
     * 
     * @type {string}
     * @memberof Kommentti2019Dto
     */
    sisalto?: string;
    /**
     * 
     * @type {number}
     * @memberof Kommentti2019Dto
     */
    opsId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Kommentti2019Dto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Kommentti2019Dto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Kommentti2019Dto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof Kommentti2019Dto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof Kommentti2019Dto
     */
    luoja?: string;
}
/**
 * 
 * @export
 * @interface KommenttiDto
 */
export interface KommenttiDto {
    /**
     * 
     * @type {string}
     * @memberof KommenttiDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KommenttiDto
     */
    sisalto?: string;
    /**
     * 
     * @type {string}
     * @memberof KommenttiDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {Date}
     * @memberof KommenttiDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof KommenttiDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {number}
     * @memberof KommenttiDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KommenttiDto
     */
    ylinId?: number;
    /**
     * 
     * @type {number}
     * @memberof KommenttiDto
     */
    parentId?: number;
    /**
     * 
     * @type {number}
     * @memberof KommenttiDto
     */
    opetussuunnitelmaId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KommenttiDto
     */
    poistettu?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KommenttiDto
     */
    tekstiKappaleViiteId?: number;
    /**
     * 
     * @type {number}
     * @memberof KommenttiDto
     */
    oppiaineId?: number;
    /**
     * 
     * @type {number}
     * @memberof KommenttiDto
     */
    vlkId?: number;
    /**
     * 
     * @type {number}
     * @memberof KommenttiDto
     */
    vlId?: number;
}
/**
 * 
 * @export
 * @interface KommenttiKahvaDto
 */
export interface KommenttiKahvaDto {
    /**
     * 
     * @type {string}
     * @memberof KommenttiKahvaDto
     */
    thread?: string;
    /**
     * 
     * @type {number}
     * @memberof KommenttiKahvaDto
     */
    opsId?: number;
    /**
     * 
     * @type {number}
     * @memberof KommenttiKahvaDto
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof KommenttiKahvaDto
     */
    stop?: number;
    /**
     * 
     * @type {string}
     * @memberof KommenttiKahvaDto
     */
    kieli?: KommenttiKahvaDtoKieliEnum;
    /**
     * 
     * @type {number}
     * @memberof KommenttiKahvaDto
     */
    tekstiId?: number;
    /**
     * 
     * @type {Kommentti2019Dto}
     * @memberof KommenttiKahvaDto
     */
    aloituskommentti?: Kommentti2019Dto;
}

/**
    * @export
    * @enum {string}
    */
export enum KommenttiKahvaDtoKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface KoodiDto
 */
export interface KoodiDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoodiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KoodiDto
     */
    arvo?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodiDto
     */
    uri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodiDto
     */
    koodisto?: string;
    /**
     * 
     * @type {number}
     * @memberof KoodiDto
     */
    versio?: number;
}
/**
 * 
 * @export
 * @interface KoodistoDto
 */
export interface KoodistoDto {
    /**
     * 
     * @type {number}
     * @memberof KoodistoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoodistoDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoodistoDto
     */
    nimi?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface KoodistoKoodiDto
 */
export interface KoodistoKoodiDto {
    /**
     * 
     * @type {number}
     * @memberof KoodistoKoodiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    versio?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    voimassaAlkuPvm?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    voimassaLoppuPvm?: string;
    /**
     * 
     * @type {Array<KoodistoMetadataDto>}
     * @memberof KoodistoKoodiDto
     */
    metadata?: Array<KoodistoMetadataDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KoodistoKoodiDto
     */
    nimi?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface KoodistoMetadataDto
 */
export interface KoodistoMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof KoodistoMetadataDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoMetadataDto
     */
    kieli?: string;
}
/**
 * 
 * @export
 * @interface KopioOppimaaraDto
 */
export interface KopioOppimaaraDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KopioOppimaaraDto
     */
    omaNimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KopioOppimaaraDto
     */
    tunniste?: string;
}
/**
 * 
 * @export
 * @interface KurssinOppiaineDto
 */
export interface KurssinOppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof KurssinOppiaineDto
     */
    oppiaineId?: number;
    /**
     * 
     * @type {number}
     * @memberof KurssinOppiaineDto
     */
    jarjestys?: number;
}
/**
 * 
 * @export
 * @interface KysymysDto
 */
export interface KysymysDto {
    /**
     * 
     * @type {number}
     * @memberof KysymysDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KysymysDto
     */
    kysymys?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KysymysDto
     */
    vastaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<OrganisaatioDto>}
     * @memberof KysymysDto
     */
    organisaatiot?: Array<OrganisaatioDto>;
    /**
     * 
     * @type {Date}
     * @memberof KysymysDto
     */
    luotu?: Date;
}
/**
 * 
 * @export
 * @interface LaajaalainenosaaminenDto
 */
export interface LaajaalainenosaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof LaajaalainenosaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LaajaalainenosaaminenDto
     */
    laajaalainenosaaminen?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LaajaalainenosaaminenDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof LaajaalainenosaaminenDto
     */
    naytaPerusteenPaatasonLao?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LaajaalainenosaaminenDto
     */
    naytaPerusteenVlkTarkennettuLao?: boolean;
}
/**
 * 
 * @export
 * @interface LiiteDto
 */
export interface LiiteDto {
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    tyyppi?: string;
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    nimi?: string;
    /**
     * 
     * @type {Date}
     * @memberof LiiteDto
     */
    luotu?: Date;
}
/**
 * 
 * @export
 * @interface LokalisointiDto
 */
export interface LokalisointiDto {
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    key?: string;
    /**
     * 
     * @type {number}
     * @memberof LokalisointiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    category?: string;
    /**
     * 
     * @type {Date}
     * @memberof LokalisointiDto
     */
    created?: Date;
}
/**
 * 
 * @export
 * @interface LongIdResultDto
 */
export interface LongIdResultDto {
    /**
     * 
     * @type {number}
     * @memberof LongIdResultDto
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface Lops2019ArviointiDto
 */
export interface Lops2019ArviointiDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019ArviointiDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019LaajaAlainenOsaaminenDto
 */
export interface Lops2019LaajaAlainenOsaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019LaajaAlainenOsaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019LaajaAlainenOsaaminenDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {KoodiDto}
     * @memberof Lops2019LaajaAlainenOsaaminenDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019LaajaAlainenOsaaminenDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019LaajaAlainenOsaaminenKokonaisuusDto
 */
export interface Lops2019LaajaAlainenOsaaminenKokonaisuusDto {
    /**
     * 
     * @type {Array<Lops2019LaajaAlainenOsaaminenDto>}
     * @memberof Lops2019LaajaAlainenOsaaminenKokonaisuusDto
     */
    laajaAlaisetOsaamiset?: Array<Lops2019LaajaAlainenOsaaminenDto>;
}
/**
 * 
 * @export
 * @interface Lops2019ModuuliDto
 */
export interface Lops2019ModuuliDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019ModuuliDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019ModuuliDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof Lops2019ModuuliDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {KoodiDto}
     * @memberof Lops2019ModuuliDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019ModuuliDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof Lops2019ModuuliDto
     */
    laajuus?: number;
    /**
     * 
     * @type {Lops2019ModuuliTavoiteDto}
     * @memberof Lops2019ModuuliDto
     */
    tavoitteet?: Lops2019ModuuliTavoiteDto;
    /**
     * 
     * @type {Array<Lops2019ModuuliSisaltoDto>}
     * @memberof Lops2019ModuuliDto
     */
    sisallot?: Array<Lops2019ModuuliSisaltoDto>;
    /**
     * 
     * @type {string}
     * @memberof Lops2019ModuuliDto
     */
    oppiaine?: string;
}
/**
 * 
 * @export
 * @interface Lops2019ModuuliSisaltoDto
 */
export interface Lops2019ModuuliSisaltoDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019ModuuliSisaltoDto
     */
    kohde?: { [key: string]: string; };
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof Lops2019ModuuliSisaltoDto
     */
    sisallot?: Array<{ [key: string]: string; }>;
}
/**
 * 
 * @export
 * @interface Lops2019ModuuliTavoiteDto
 */
export interface Lops2019ModuuliTavoiteDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019ModuuliTavoiteDto
     */
    kohde?: { [key: string]: string; };
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof Lops2019ModuuliTavoiteDto
     */
    tavoitteet?: Array<{ [key: string]: string; }>;
}
/**
 * 
 * @export
 * @interface Lops2019OpintojaksoDto
 */
export interface Lops2019OpintojaksoDto {
    /**
     * 
     * @type {boolean}
     * @memberof Lops2019OpintojaksoDto
     */
    tuotu?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Lops2019OpintojaksoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Lops2019OpintojaksoDto
     */
    koodi?: string;
    /**
     * 
     * @type {number}
     * @memberof Lops2019OpintojaksoDto
     */
    laajuus?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OpintojaksoDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OpintojaksoDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OpintojaksoDto
     */
    arviointi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OpintojaksoDto
     */
    opiskeluymparistoTyotavat?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Lops2019OpintojaksonOppiaineDto>}
     * @memberof Lops2019OpintojaksoDto
     */
    oppiaineet?: Array<Lops2019OpintojaksonOppiaineDto>;
    /**
     * 
     * @type {Array<Lops2019OpintojaksonTavoiteDto>}
     * @memberof Lops2019OpintojaksoDto
     */
    tavoitteet?: Array<Lops2019OpintojaksonTavoiteDto>;
    /**
     * 
     * @type {Array<Lops2019OpintojaksonKeskeinenSisaltoDto>}
     * @memberof Lops2019OpintojaksoDto
     */
    keskeisetSisallot?: Array<Lops2019OpintojaksonKeskeinenSisaltoDto>;
    /**
     * 
     * @type {Array<Lops2019PaikallinenLaajaAlainenDto>}
     * @memberof Lops2019OpintojaksoDto
     */
    laajaAlainenOsaaminen?: Array<Lops2019PaikallinenLaajaAlainenDto>;
    /**
     * 
     * @type {Array<Lops2019OpintojaksonModuuliDto>}
     * @memberof Lops2019OpintojaksoDto
     */
    moduulit?: Array<Lops2019OpintojaksonModuuliDto>;
    /**
     * 
     * @type {Array<Lops2019OpintojaksoDto>}
     * @memberof Lops2019OpintojaksoDto
     */
    paikallisetOpintojaksot?: Array<Lops2019OpintojaksoDto>;
}
/**
 * 
 * @export
 * @interface Lops2019OpintojaksoPerusteDto
 */
export interface Lops2019OpintojaksoPerusteDto {
    /**
     * 
     * @type {Array<Lops2019ModuuliDto>}
     * @memberof Lops2019OpintojaksoPerusteDto
     */
    moduulit?: Array<Lops2019ModuuliDto>;
}
/**
 * 
 * @export
 * @interface Lops2019OpintojaksonKeskeinenSisaltoDto
 */
export interface Lops2019OpintojaksonKeskeinenSisaltoDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OpintojaksonKeskeinenSisaltoDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019OpintojaksonModuuliDto
 */
export interface Lops2019OpintojaksonModuuliDto {
    /**
     * 
     * @type {string}
     * @memberof Lops2019OpintojaksonModuuliDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OpintojaksonModuuliDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019OpintojaksonOppiaineDto
 */
export interface Lops2019OpintojaksonOppiaineDto {
    /**
     * 
     * @type {string}
     * @memberof Lops2019OpintojaksonOppiaineDto
     */
    koodi?: string;
    /**
     * 
     * @type {number}
     * @memberof Lops2019OpintojaksonOppiaineDto
     */
    laajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof Lops2019OpintojaksonOppiaineDto
     */
    jarjestys?: number;
}
/**
 * 
 * @export
 * @interface Lops2019OpintojaksonTavoiteDto
 */
export interface Lops2019OpintojaksonTavoiteDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OpintojaksonTavoiteDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019OpiskeluymparistoTyotavatDto
 */
export interface Lops2019OpiskeluymparistoTyotavatDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OpiskeluymparistoTyotavatDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineJarjestysDto
 */
export interface Lops2019OppiaineJarjestysDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019OppiaineJarjestysDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Lops2019OppiaineJarjestysDto
     */
    koodi?: string;
    /**
     * 
     * @type {number}
     * @memberof Lops2019OppiaineJarjestysDto
     */
    jarjestys?: number;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineKaikkiDto
 */
export interface Lops2019OppiaineKaikkiDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {KoodiDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    oppiaine?: string;
    /**
     * 
     * @type {Lops2019ArviointiDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    arviointi?: Lops2019ArviointiDto;
    /**
     * 
     * @type {Lops2019TehtavaDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    tehtava?: Lops2019TehtavaDto;
    /**
     * 
     * @type {Lops2019OpiskeluymparistoTyotavatDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    opiskeluymparistoTyotavat?: Lops2019OpiskeluymparistoTyotavatDto;
    /**
     * 
     * @type {Lops2019OppiaineLaajaAlainenOsaaminenDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    laajaAlaisetOsaamiset?: Lops2019OppiaineLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {Lops2019OppiaineTavoitteetDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    tavoitteet?: Lops2019OppiaineTavoitteetDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    pakollisetModuulitKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    valinnaisetModuulitKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Lops2019ModuuliDto>}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    moduulit?: Array<Lops2019ModuuliDto>;
    /**
     * 
     * @type {Array<Lops2019OppiaineKaikkiDto>}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    oppimaarat?: Array<Lops2019OppiaineKaikkiDto>;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineLaajaAlainenOsaaminenDto
 */
export interface Lops2019OppiaineLaajaAlainenOsaaminenDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineLaajaAlainenOsaaminenDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineTavoitealueDto
 */
export interface Lops2019OppiaineTavoitealueDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineTavoitealueDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineTavoitealueDto
     */
    kohde?: { [key: string]: string; };
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof Lops2019OppiaineTavoitealueDto
     */
    tavoitteet?: Array<{ [key: string]: string; }>;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineTavoitteetDto
 */
export interface Lops2019OppiaineTavoitteetDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineTavoitteetDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Lops2019OppiaineTavoitealueDto>}
     * @memberof Lops2019OppiaineTavoitteetDto
     */
    tavoitealueet?: Array<Lops2019OppiaineTavoitealueDto>;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineenTavoitealueDto
 */
export interface Lops2019OppiaineenTavoitealueDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineenTavoitealueDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineenTavoitealueDto
     */
    kohde?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Lops2019TavoitealueenTavoite>}
     * @memberof Lops2019OppiaineenTavoitealueDto
     */
    tavoitteet?: Array<Lops2019TavoitealueenTavoite>;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineenTavoitteetDto
 */
export interface Lops2019OppiaineenTavoitteetDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019OppiaineenTavoitteetDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Lops2019OppiaineenTavoitealueDto>}
     * @memberof Lops2019OppiaineenTavoitteetDto
     */
    tavoitealueet?: Array<Lops2019OppiaineenTavoitealueDto>;
}
/**
 * 
 * @export
 * @interface Lops2019PaikallinenLaajaAlainenDto
 */
export interface Lops2019PaikallinenLaajaAlainenDto {
    /**
     * 
     * @type {string}
     * @memberof Lops2019PaikallinenLaajaAlainenDto
     */
    koodi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019PaikallinenLaajaAlainenDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019PaikallinenOppiaineDto
 */
export interface Lops2019PaikallinenOppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    koodi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Lops2019PaikallinenLaajaAlainenDto>}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    laajaAlainenOsaaminen?: Array<Lops2019PaikallinenLaajaAlainenDto>;
    /**
     * 
     * @type {Lops2019TehtavaDto}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    tehtava?: Lops2019TehtavaDto;
    /**
     * 
     * @type {Lops2019OpiskeluymparistoTyotavatDto}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    opiskeluymparistoTyotavat?: Lops2019OpiskeluymparistoTyotavatDto;
    /**
     * 
     * @type {Lops2019ArviointiDto}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    arviointi?: Lops2019ArviointiDto;
    /**
     * 
     * @type {Lops2019OppiaineenTavoitteetDto}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    tavoitteet?: Lops2019OppiaineenTavoitteetDto;
    /**
     * 
     * @type {string}
     * @memberof Lops2019PaikallinenOppiaineDto
     */
    perusteenOppiaineUri?: string;
}
/**
 * 
 * @export
 * @interface Lops2019PoistettuDto
 */
export interface Lops2019PoistettuDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019PoistettuDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Lops2019PoistettuDto
     */
    opetussuunnitelma?: string;
    /**
     * 
     * @type {number}
     * @memberof Lops2019PoistettuDto
     */
    poistettuId?: number;
    /**
     * 
     * @type {string}
     * @memberof Lops2019PoistettuDto
     */
    tyyppi?: Lops2019PoistettuDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019PoistettuDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019PoistettuDto
     */
    parent?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Lops2019PoistettuDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof Lops2019PoistettuDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Lops2019PoistettuDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {Date}
     * @memberof Lops2019PoistettuDto
     */
    muokattu?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum Lops2019PoistettuDtoTyyppiEnum {
    TUOTUOPINTOJAKSO = 'TUOTU_OPINTOJAKSO',
    TUOTUOPPIMAARA = 'TUOTU_OPPIMAARA',
    LOPS2019OPPIAINE = 'LOPS2019OPPIAINE',
    OPINTOJAKSO = 'OPINTOJAKSO',
    OPPIAINE = 'OPPIAINE'
}

/**
 * 
 * @export
 * @interface Lops2019SisaltoDto
 */
export interface Lops2019SisaltoDto {
    /**
     * 
     * @type {Lops2019LaajaAlainenOsaaminenKokonaisuusDto}
     * @memberof Lops2019SisaltoDto
     */
    laajaAlainenOsaaminen?: Lops2019LaajaAlainenOsaaminenKokonaisuusDto;
    /**
     * 
     * @type {Array<Lops2019OppiaineKaikkiDto>}
     * @memberof Lops2019SisaltoDto
     */
    oppiaineet?: Array<Lops2019OppiaineKaikkiDto>;
    /**
     * 
     * @type {TekstiKappaleViiteDto}
     * @memberof Lops2019SisaltoDto
     */
    sisalto?: TekstiKappaleViiteDto;
}
/**
 * 
 * @export
 * @interface Lops2019TavoitealueenTavoite
 */
export interface Lops2019TavoitealueenTavoite {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019TavoitealueenTavoite
     */
    tavoite?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019TehtavaDto
 */
export interface Lops2019TehtavaDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lops2019TehtavaDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface LukioAbstraktiOppiaineTuontiDto
 */
export interface LukioAbstraktiOppiaineTuontiDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioAbstraktiOppiaineTuontiDto
     */
    nimi: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LukioAbstraktiOppiaineTuontiDto
     */
    tunniste: string;
}
/**
 * 
 * @export
 * @interface LukioKopioiOppimaaraDto
 */
export interface LukioKopioiOppimaaraDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioKopioiOppimaaraDto
     */
    nimi: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LukioKopioiOppimaaraDto
     */
    tunniste: string;
    /**
     * 
     * @type {string}
     * @memberof LukioKopioiOppimaaraDto
     */
    kieliKoodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioKopioiOppimaaraDto
     */
    kieliKoodiArvo?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioKopioiOppimaaraDto
     */
    kieli?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface LukioOpetussuunnitelmaRakenneDto
 */
export interface LukioOpetussuunnitelmaRakenneDto {
    /**
     * 
     * @type {number}
     * @memberof LukioOpetussuunnitelmaRakenneDto
     */
    perusteId?: number;
    /**
     * 
     * @type {Array<LukioPerusteOppiaineDto>}
     * @memberof LukioOpetussuunnitelmaRakenneDto
     */
    oppiaineet?: Array<LukioPerusteOppiaineDto>;
}
/**
 * 
 * @export
 * @interface LukioOpetussuunnitelmaRakenneOpsDto
 */
export interface LukioOpetussuunnitelmaRakenneOpsDto {
    /**
     * 
     * @type {boolean}
     * @memberof LukioOpetussuunnitelmaRakenneOpsDto
     */
    root?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof LukioOpetussuunnitelmaRakenneOpsDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {number}
     * @memberof LukioOpetussuunnitelmaRakenneOpsDto
     */
    opsId?: number;
    /**
     * 
     * @type {Array<LukioOppiaineRakenneListausDto>}
     * @memberof LukioOpetussuunnitelmaRakenneOpsDto
     */
    oppiaineet?: Array<LukioOppiaineRakenneListausDto>;
    /**
     * 
     * @type {Array<LukioOppiaineRakenneListausDto>}
     * @memberof LukioOpetussuunnitelmaRakenneOpsDto
     */
    pohjanTarjonta?: Array<LukioOppiaineRakenneListausDto>;
}
/**
 * 
 * @export
 * @interface LukioOppiaineRakenneListausDto
 */
export interface LukioOppiaineRakenneListausDto {
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineRakenneListausDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineRakenneListausDto
     */
    oppiaineId?: number;
    /**
     * 
     * @type {Date}
     * @memberof LukioOppiaineRakenneListausDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineRakenneListausDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineRakenneListausDto
     */
    tila?: LukioOppiaineRakenneListausDtoTilaEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineRakenneListausDto
     */
    oma?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineRakenneListausDto
     */
    maariteltyPohjassa?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineRakenneListausDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineRakenneListausDto
     */
    tyyppi?: LukioOppiaineRakenneListausDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineRakenneListausDto
     */
    laajuus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineRakenneListausDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioOppiaineRakenneListausDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineRakenneListausDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineRakenneListausDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineRakenneListausDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineRakenneListausDto
     */
    kieliKoodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineRakenneListausDto
     */
    kieliKoodiArvo?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioOppiaineRakenneListausDto
     */
    kieli?: { [key: string]: string; };
    /**
     * 
     * @type {Array<LukiokurssiListausOpsDto>}
     * @memberof LukioOppiaineRakenneListausDto
     */
    kurssit?: Array<LukiokurssiListausOpsDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum LukioOppiaineRakenneListausDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum LukioOppiaineRakenneListausDtoTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    TAIDETAITOAINE = 'TAIDE_TAITOAINE',
    MUUVALINNAINEN = 'MUU_VALINNAINEN',
    LUKIO = 'LUKIO'
}

/**
 * 
 * @export
 * @interface LukioOppiaineSaveDto
 */
export interface LukioOppiaineSaveDto {
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineSaveDto
     */
    oppiaineId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioOppiaineSaveDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineSaveDto
     */
    laajuus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineSaveDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineSaveDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineSaveDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof LukioOppiaineSaveDto
     */
    tehtava?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof LukioOppiaineSaveDto
     */
    tavoitteet?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof LukioOppiaineSaveDto
     */
    arviointi?: TekstiosaDto;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof LukioOppiaineSaveDto
     */
    kurssiTyyppiKuvaukset?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface LukioOppiaineTiedotDto
 */
export interface LukioOppiaineTiedotDto {
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineTiedotDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineTiedotDto
     */
    oppiaineId?: number;
    /**
     * 
     * @type {Date}
     * @memberof LukioOppiaineTiedotDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineTiedotDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineTiedotDto
     */
    tila?: LukioOppiaineTiedotDtoTilaEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineTiedotDto
     */
    oma?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineTiedotDto
     */
    maariteltyPohjassa?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineTiedotDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineTiedotDto
     */
    tyyppi?: LukioOppiaineTiedotDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineTiedotDto
     */
    laajuus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineTiedotDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioOppiaineTiedotDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineTiedotDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineTiedotDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineTiedotDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineTiedotDto
     */
    kieliKoodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineTiedotDto
     */
    kieliKoodiArvo?: string;
    /**
     * 
     * @type {Array<LukioOppimaaraPerusTiedotDto>}
     * @memberof LukioOppiaineTiedotDto
     */
    oppimaarat?: Array<LukioOppimaaraPerusTiedotDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioOppiaineTiedotDto
     */
    kieli?: { [key: string]: string; };
    /**
     * 
     * @type {Array<LukiokurssiOpsDto>}
     * @memberof LukioOppiaineTiedotDto
     */
    kurssit?: Array<LukiokurssiOpsDto>;
    /**
     * 
     * @type {LukioPerusteOppiaineDto}
     * @memberof LukioOppiaineTiedotDto
     */
    perusteen?: LukioPerusteOppiaineDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof LukioOppiaineTiedotDto
     */
    tehtava?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof LukioOppiaineTiedotDto
     */
    tavoitteet?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof LukioOppiaineTiedotDto
     */
    arviointi?: TekstiosaDto;
    /**
     * 
     * @type {Array<LukioOppimaaraPerusTiedotDto>}
     * @memberof LukioOppiaineTiedotDto
     */
    pohjanTarjonta?: Array<LukioOppimaaraPerusTiedotDto>;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof LukioOppiaineTiedotDto
     */
    kurssiTyyppiKuvaukset?: { [key: string]: { [key: string]: string; }; };
}

/**
    * @export
    * @enum {string}
    */
export enum LukioOppiaineTiedotDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum LukioOppiaineTiedotDtoTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    TAIDETAITOAINE = 'TAIDE_TAITOAINE',
    MUUVALINNAINEN = 'MUU_VALINNAINEN',
    LUKIO = 'LUKIO'
}

/**
 * 
 * @export
 * @interface LukioOppimaaraPerusTiedotDto
 */
export interface LukioOppimaaraPerusTiedotDto {
    /**
     * 
     * @type {number}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    oppiaineId?: number;
    /**
     * 
     * @type {Date}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    tila?: LukioOppimaaraPerusTiedotDtoTilaEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    oma?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    maariteltyPohjassa?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {string}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    tyyppi?: LukioOppimaaraPerusTiedotDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    laajuus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    kieliKoodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppimaaraPerusTiedotDto
     */
    kieliKoodiArvo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LukioOppimaaraPerusTiedotDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum LukioOppimaaraPerusTiedotDtoTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    TAIDETAITOAINE = 'TAIDE_TAITOAINE',
    MUUVALINNAINEN = 'MUU_VALINNAINEN',
    LUKIO = 'LUKIO'
}

/**
 * 
 * @export
 * @interface LukioPerusteOppiaineDto
 */
export interface LukioPerusteOppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof LukioPerusteOppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LukioPerusteOppiaineDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioPerusteOppiaineDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioPerusteOppiaineDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LukioPerusteOppiaineDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LukioPerusteOppiaineDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LukioPerusteOppiaineDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteOppiaineDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteOppiaineDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteOppiaineDto
     */
    pakollinenKurssiKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteOppiaineDto
     */
    syventavaKurssiKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteOppiaineDto
     */
    soveltavaKurssiKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof LukioPerusteOppiaineDto
     */
    tehtava?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof LukioPerusteOppiaineDto
     */
    tavoitteet?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof LukioPerusteOppiaineDto
     */
    arviointi?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {Array<LukiokurssiPerusteDto>}
     * @memberof LukioPerusteOppiaineDto
     */
    kurssit?: Array<LukiokurssiPerusteDto>;
}
/**
 * 
 * @export
 * @interface LukioPerusteSisaltoDto
 */
export interface LukioPerusteSisaltoDto {
    /**
     * 
     * @type {number}
     * @memberof LukioPerusteSisaltoDto
     */
    id?: number;
    /**
     * 
     * @type {Array<LukioPerusteTekstikappaleViiteDto>}
     * @memberof LukioPerusteSisaltoDto
     */
    lapset?: Array<LukioPerusteTekstikappaleViiteDto>;
}
/**
 * 
 * @export
 * @interface LukioPerusteTekstikappaleViiteDto
 */
export interface LukioPerusteTekstikappaleViiteDto {
    /**
     * 
     * @type {number}
     * @memberof LukioPerusteTekstikappaleViiteDto
     */
    id?: number;
    /**
     * 
     * @type {LukioPerusteenOsaDto}
     * @memberof LukioPerusteTekstikappaleViiteDto
     */
    perusteenOsa?: LukioPerusteenOsaDto;
}
/**
 * 
 * @export
 * @interface LukioPerusteenOsaDto
 */
export interface LukioPerusteenOsaDto {
    /**
     * 
     * @type {number}
     * @memberof LukioPerusteenOsaDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof LukioPerusteenOsaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LukioPerusteenOsaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof LukioPerusteenOsaDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioPerusteenOsaDto
     */
    muokkaajanNimi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteenOsaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteenOsaDto
     */
    teksti?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteenOsaDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteenOsaDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukioPerusteenOsaDto
     */
    yleiskuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LukioPerusteenOsaDto
     */
    tila?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioPerusteenOsaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioPerusteenOsaDto
     */
    osanTyyppi?: string;
}
/**
 * 
 * @export
 * @interface LukiokoulutuksenPerusteenSisaltoDto
 */
export interface LukiokoulutuksenPerusteenSisaltoDto {
    /**
     * 
     * @type {LukioPerusteSisaltoDto}
     * @memberof LukiokoulutuksenPerusteenSisaltoDto
     */
    sisalto?: LukioPerusteSisaltoDto;
    /**
     * 
     * @type {LukioOpetussuunnitelmaRakenneDto}
     * @memberof LukiokoulutuksenPerusteenSisaltoDto
     */
    rakenne?: LukioOpetussuunnitelmaRakenneDto;
    /**
     * 
     * @type {AihekokonaisuudetDto}
     * @memberof LukiokoulutuksenPerusteenSisaltoDto
     */
    aihekokonaisuudet?: AihekokonaisuudetDto;
    /**
     * 
     * @type {OpetuksenYleisetTavoitteetDto}
     * @memberof LukiokoulutuksenPerusteenSisaltoDto
     */
    opetuksenYleisetTavoitteet?: OpetuksenYleisetTavoitteetDto;
}
/**
 * 
 * @export
 * @interface LukiokurssiListausOpsDto
 */
export interface LukiokurssiListausOpsDto {
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiListausOpsDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof LukiokurssiListausOpsDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiListausOpsDto
     */
    tunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiListausOpsDto
     */
    laajuus?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiListausOpsDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof LukiokurssiListausOpsDto
     */
    oma?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LukiokurssiListausOpsDto
     */
    palautettava?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiListausOpsDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiListausOpsDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiListausOpsDto
     */
    tyyppi?: LukiokurssiListausOpsDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiListausOpsDto
     */
    lokalisoituKoodi?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum LukiokurssiListausOpsDtoTyyppiEnum {
    VALTAKUNNALLINENPAKOLLINEN = 'VALTAKUNNALLINEN_PAKOLLINEN',
    VALTAKUNNALLINENSYVENTAVA = 'VALTAKUNNALLINEN_SYVENTAVA',
    VALTAKUNNALLINENSOVELTAVA = 'VALTAKUNNALLINEN_SOVELTAVA',
    PAIKALLINENSYVENTAVA = 'PAIKALLINEN_SYVENTAVA',
    PAIKALLINENSOVELTAVA = 'PAIKALLINEN_SOVELTAVA'
}

/**
 * 
 * @export
 * @interface LukiokurssiOppaineMuokkausDto
 */
export interface LukiokurssiOppaineMuokkausDto {
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiOppaineMuokkausDto
     */
    id: number;
    /**
     * 
     * @type {Array<KurssinOppiaineDto>}
     * @memberof LukiokurssiOppaineMuokkausDto
     */
    oppiaineet?: Array<KurssinOppiaineDto>;
}
/**
 * 
 * @export
 * @interface LukiokurssiOpsDto
 */
export interface LukiokurssiOpsDto {
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiOpsDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof LukiokurssiOpsDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiOpsDto
     */
    tunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiOpsDto
     */
    laajuus?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiOpsDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof LukiokurssiOpsDto
     */
    oma?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LukiokurssiOpsDto
     */
    palautettava?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiOpsDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiOpsDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiOpsDto
     */
    tyyppi?: LukiokurssiOpsDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiOpsDto
     */
    lokalisoituKoodi?: { [key: string]: string; };
    /**
     * 
     * @type {LukiokurssiPerusteDto}
     * @memberof LukiokurssiOpsDto
     */
    perusteen?: LukiokurssiPerusteDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiOpsDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof LukiokurssiOpsDto
     */
    tavoitteet?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof LukiokurssiOpsDto
     */
    keskeinenSisalto?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof LukiokurssiOpsDto
     */
    tavoitteetJaKeskeinenSisalto?: TekstiosaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum LukiokurssiOpsDtoTyyppiEnum {
    VALTAKUNNALLINENPAKOLLINEN = 'VALTAKUNNALLINEN_PAKOLLINEN',
    VALTAKUNNALLINENSYVENTAVA = 'VALTAKUNNALLINEN_SYVENTAVA',
    VALTAKUNNALLINENSOVELTAVA = 'VALTAKUNNALLINEN_SOVELTAVA',
    PAIKALLINENSYVENTAVA = 'PAIKALLINEN_SYVENTAVA',
    PAIKALLINENSOVELTAVA = 'PAIKALLINEN_SOVELTAVA'
}

/**
 * 
 * @export
 * @interface LukiokurssiPerusteDto
 */
export interface LukiokurssiPerusteDto {
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiPerusteDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiPerusteDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiPerusteDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiPerusteDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiPerusteDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiPerusteDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiPerusteDto
     */
    lokalisoituKoodi?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiPerusteDto
     */
    oppiaineId?: number;
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiPerusteDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiPerusteDto
     */
    tyyppi?: LukiokurssiPerusteDtoTyyppiEnum;
    /**
     * 
     * @type {LukioOpetussuunnitelmaRakenneDto}
     * @memberof LukiokurssiPerusteDto
     */
    opetussuunnitelma?: LukioOpetussuunnitelmaRakenneDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof LukiokurssiPerusteDto
     */
    tavoitteet?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof LukiokurssiPerusteDto
     */
    keskeisetSisallot?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof LukiokurssiPerusteDto
     */
    tavoitteetJaKeskeisetSisallot?: PerusteTekstiOsaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum LukiokurssiPerusteDtoTyyppiEnum {
    PAKOLLINEN = 'PAKOLLINEN',
    VALTAKUNNALLINENSYVENTAVA = 'VALTAKUNNALLINEN_SYVENTAVA',
    VALTAKUNNALLINENSOVELTAVA = 'VALTAKUNNALLINEN_SOVELTAVA'
}

/**
 * 
 * @export
 * @interface LukiokurssiSaveDto
 */
export interface LukiokurssiSaveDto {
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiSaveDto
     */
    oppiaineId: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiSaveDto
     */
    nimi: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiSaveDto
     */
    tyyppi: LukiokurssiSaveDtoTyyppiEnum;
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiSaveDto
     */
    laajuus: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiSaveDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiSaveDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiSaveDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiSaveDto
     */
    lokalisoituKoodi?: { [key: string]: string; };
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof LukiokurssiSaveDto
     */
    tavoitteet?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof LukiokurssiSaveDto
     */
    keskeinenSisalto?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof LukiokurssiSaveDto
     */
    tavoitteetJaKeskeinenSisalto?: TekstiOsaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum LukiokurssiSaveDtoTyyppiEnum {
    SYVENTAVA = 'PAIKALLINEN_SYVENTAVA',
    SOVELTAVA = 'PAIKALLINEN_SOVELTAVA'
}

/**
 * 
 * @export
 * @interface LukiokurssiUpdateDto
 */
export interface LukiokurssiUpdateDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiUpdateDto
     */
    nimi: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiUpdateDto
     */
    tyyppi: LukiokurssiUpdateDtoTyyppiEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiUpdateDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiUpdateDto
     */
    laajuus: number;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiUpdateDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiUpdateDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LukiokurssiUpdateDto
     */
    lokalisoituKoodi?: { [key: string]: string; };
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof LukiokurssiUpdateDto
     */
    tavoitteet?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof LukiokurssiUpdateDto
     */
    keskeinenSisalto?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof LukiokurssiUpdateDto
     */
    tavoitteetJaKeskeinenSisalto?: TekstiOsaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum LukiokurssiUpdateDtoTyyppiEnum {
    VALTAKUNNALLINENPAKOLLINEN = 'VALTAKUNNALLINEN_PAKOLLINEN',
    VALTAKUNNALLINENSYVENTAVA = 'VALTAKUNNALLINEN_SYVENTAVA',
    VALTAKUNNALLINENSOVELTAVA = 'VALTAKUNNALLINEN_SOVELTAVA',
    PAIKALLINENSYVENTAVA = 'PAIKALLINEN_SYVENTAVA',
    PAIKALLINENSOVELTAVA = 'PAIKALLINEN_SOVELTAVA'
}

/**
 * 
 * @export
 * @interface Matala
 */
export interface Matala {
    /**
     * 
     * @type {number}
     * @memberof Matala
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof Matala
     */
    tekstiKappale?: TekstiKappaleDto;
    /**
     * 
     * @type {string}
     * @memberof Matala
     */
    omistussuhde?: MatalaOmistussuhdeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    valmis?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Matala
     */
    perusteTekstikappaleId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    naytaPerusteenTeksti?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    naytaPohjanTeksti?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    piilotettu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Matala
     */
    liite?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Matala
     */
    lapset?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Matala
     */
    _original?: string;
    /**
     * 
     * @type {string}
     * @memberof Matala
     */
    _tekstiKappale?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MatalaOmistussuhdeEnum {
    OMA = 'OMA',
    LAINATTU = 'LAINATTU'
}

/**
 * 
 * @export
 * @interface MuokkaustietoKayttajallaDto
 */
export interface MuokkaustietoKayttajallaDto {
    /**
     * 
     * @type {number}
     * @memberof MuokkaustietoKayttajallaDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MuokkaustietoKayttajallaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof MuokkaustietoKayttajallaDto
     */
    tapahtuma?: MuokkaustietoKayttajallaDtoTapahtumaEnum;
    /**
     * 
     * @type {number}
     * @memberof MuokkaustietoKayttajallaDto
     */
    opetussuunnitelmaId?: number;
    /**
     * 
     * @type {number}
     * @memberof MuokkaustietoKayttajallaDto
     */
    kohdeId?: number;
    /**
     * 
     * @type {string}
     * @memberof MuokkaustietoKayttajallaDto
     */
    kohde?: MuokkaustietoKayttajallaDtoKohdeEnum;
    /**
     * 
     * @type {Date}
     * @memberof MuokkaustietoKayttajallaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof MuokkaustietoKayttajallaDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof MuokkaustietoKayttajallaDto
     */
    lisatieto?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MuokkaustietoKayttajallaDto
     */
    poistettu?: boolean;
    /**
     * 
     * @type {Array<OpetussuunnitelmanMuokkaustietoLisaparametritDto>}
     * @memberof MuokkaustietoKayttajallaDto
     */
    lisaparametrit?: Array<OpetussuunnitelmanMuokkaustietoLisaparametritDto>;
    /**
     * 
     * @type {KayttajanTietoDto}
     * @memberof MuokkaustietoKayttajallaDto
     */
    kayttajanTieto?: KayttajanTietoDto;
}

/**
    * @export
    * @enum {string}
    */
export enum MuokkaustietoKayttajallaDtoTapahtumaEnum {
    LUONTI = 'LUONTI',
    PAIVITYS = 'PAIVITYS',
    PALAUTUS = 'PALAUTUS',
    POISTO = 'POISTO',
    JULKAISU = 'JULKAISU',
    VIRHE = 'VIRHE'
}
/**
    * @export
    * @enum {string}
    */
export enum MuokkaustietoKayttajallaDtoKohdeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Laajaalaiset = 'laajaalaiset',
    Laajaalainen = 'laajaalainen',
    Oppiaineet = 'oppiaineet',
    Oppimaarat = 'oppimaarat',
    Oppiaine = 'oppiaine',
    Poppiaine = 'poppiaine',
    Lukiooppiaineet2015 = 'lukiooppiaineet_2015',
    Lukiooppimaarat2015 = 'lukiooppimaarat_2015',
    Lukiooppiaine2015 = 'lukiooppiaine_2015',
    Lukiokurssit = 'lukiokurssit',
    Lukiokurssi = 'lukiokurssi',
    Moduulit = 'moduulit',
    Moduuli = 'moduuli',
    Opintojaksot = 'opintojaksot',
    Opintojakso = 'opintojakso',
    Opetussuunnitelma = 'opetussuunnitelma',
    OpetussuunnitelmaRakenne = 'opetussuunnitelma_rakenne',
    Termi = 'termi',
    Kommentti = 'kommentti',
    Perusopetusoppiaineet = 'perusopetusoppiaineet',
    Perusopetusoppiaine = 'perusopetusoppiaine',
    Perusopetuspaikallinenoppiaine = 'perusopetuspaikallinenoppiaine',
    Valinnaisetoppiaineet = 'valinnaisetoppiaineet',
    Vuosiluokkakokonaisuus = 'vuosiluokkakokonaisuus',
    Oppiaineenvuosiluokka = 'oppiaineenvuosiluokka',
    TavoitteetSisallotArviointi = 'tavoitteet_sisallot_arviointi',
    Peruste = 'peruste'
}

/**
 * 
 * @export
 * @interface NavigationNodeDto
 */
export interface NavigationNodeDto {
    /**
     * 
     * @type {number}
     * @memberof NavigationNodeDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NavigationNodeDto
     */
    label?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof NavigationNodeDto
     */
    type?: NavigationNodeDtoTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof NavigationNodeDto
     */
    meta?: { [key: string]: object; };
    /**
     * 
     * @type {Array<NavigationNodeDto>}
     * @memberof NavigationNodeDto
     */
    children?: Array<NavigationNodeDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum NavigationNodeDtoTypeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Laajaalaiset = 'laajaalaiset',
    Laajaalainen = 'laajaalainen',
    Oppiaineet = 'oppiaineet',
    Oppimaarat = 'oppimaarat',
    Oppiaine = 'oppiaine',
    Poppiaine = 'poppiaine',
    Lukiooppiaineet2015 = 'lukiooppiaineet_2015',
    Lukiooppimaarat2015 = 'lukiooppimaarat_2015',
    Lukiooppiaine2015 = 'lukiooppiaine_2015',
    Lukiokurssit = 'lukiokurssit',
    Lukiokurssi = 'lukiokurssi',
    Moduulit = 'moduulit',
    Moduuli = 'moduuli',
    Opintojaksot = 'opintojaksot',
    Opintojakso = 'opintojakso',
    Opetussuunnitelma = 'opetussuunnitelma',
    OpetussuunnitelmaRakenne = 'opetussuunnitelma_rakenne',
    Termi = 'termi',
    Kommentti = 'kommentti',
    Perusopetusoppiaineet = 'perusopetusoppiaineet',
    Perusopetusoppiaine = 'perusopetusoppiaine',
    Perusopetuspaikallinenoppiaine = 'perusopetuspaikallinenoppiaine',
    Valinnaisetoppiaineet = 'valinnaisetoppiaineet',
    Vuosiluokkakokonaisuus = 'vuosiluokkakokonaisuus',
    Oppiaineenvuosiluokka = 'oppiaineenvuosiluokka',
    TavoitteetSisallotArviointi = 'tavoitteet_sisallot_arviointi',
    Peruste = 'peruste'
}

/**
 * 
 * @export
 * @interface OpetuksenKeskeinensisaltoalueDto
 */
export interface OpetuksenKeskeinensisaltoalueDto {
    /**
     * 
     * @type {number}
     * @memberof OpetuksenKeskeinensisaltoalueDto
     */
    id?: number;
    /**
     * 
     * @type {KeskeinenSisaltoalueDto}
     * @memberof OpetuksenKeskeinensisaltoalueDto
     */
    sisaltoalueet?: KeskeinenSisaltoalueDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenKeskeinensisaltoalueDto
     */
    omaKuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OpetuksenKohdealueDto
 */
export interface OpetuksenKohdealueDto {
    /**
     * 
     * @type {number}
     * @memberof OpetuksenKohdealueDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenKohdealueDto
     */
    nimi?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OpetuksenTavoiteDto
 */
export interface OpetuksenTavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof OpetuksenTavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetuksenTavoiteDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenTavoiteDto
     */
    tavoite?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetuksenTavoiteDto
     */
    laajattavoitteet?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetuksenTavoiteDto
     */
    kohdealueet?: Array<string>;
    /**
     * 
     * @type {Array<TavoitteenArviointiDto>}
     * @memberof OpetuksenTavoiteDto
     */
    arvioinninkohteet?: Array<TavoitteenArviointiDto>;
    /**
     * 
     * @type {Array<OpetuksenKeskeinensisaltoalueDto>}
     * @memberof OpetuksenTavoiteDto
     */
    sisaltoalueet?: Array<OpetuksenKeskeinensisaltoalueDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenTavoiteDto
     */
    arvioinninKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenTavoiteDto
     */
    arvioinninOtsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenTavoiteDto
     */
    vapaaTeksti?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenTavoiteDto
     */
    tavoitteistaJohdetutOppimisenTavoitteet?: { [key: string]: string; };
    /**
     * 
     * @type {Array<OppiaineenTavoitteenOpetuksenTavoiteDto>}
     * @memberof OpetuksenTavoiteDto
     */
    oppiaineenTavoitteenOpetuksenTavoitteet?: Array<OppiaineenTavoitteenOpetuksenTavoiteDto>;
}
/**
 * 
 * @export
 * @interface OpetuksenYleisetTavoitteetDto
 */
export interface OpetuksenYleisetTavoitteetDto {
    /**
     * 
     * @type {OpetuksenYleisetTavoitteetDto}
     * @memberof OpetuksenYleisetTavoitteetDto
     */
    parent?: OpetuksenYleisetTavoitteetDto;
    /**
     * 
     * @type {string}
     * @memberof OpetuksenYleisetTavoitteetDto
     */
    uuidTunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetuksenYleisetTavoitteetDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenYleisetTavoitteetDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenYleisetTavoitteetDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof OpetuksenYleisetTavoitteetDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetuksenYleisetTavoitteetDto
     */
    muokkaaja?: string;
}
/**
 * 
 * @export
 * @interface OpetuksenYleisetTavoitteetOpsDto
 */
export interface OpetuksenYleisetTavoitteetOpsDto {
    /**
     * 
     * @type {OpetuksenYleisetTavoitteetDto}
     * @memberof OpetuksenYleisetTavoitteetOpsDto
     */
    parent?: OpetuksenYleisetTavoitteetDto;
    /**
     * 
     * @type {string}
     * @memberof OpetuksenYleisetTavoitteetOpsDto
     */
    uuidTunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetuksenYleisetTavoitteetOpsDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenYleisetTavoitteetOpsDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenYleisetTavoitteetOpsDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof OpetuksenYleisetTavoitteetOpsDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetuksenYleisetTavoitteetOpsDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {OpetuksenYleisetTavoitteetDto}
     * @memberof OpetuksenYleisetTavoitteetOpsDto
     */
    perusteen?: OpetuksenYleisetTavoitteetDto;
}
/**
 * 
 * @export
 * @interface OpetuksenYleisetTavoitteetPerusteOpsDto
 */
export interface OpetuksenYleisetTavoitteetPerusteOpsDto {
    /**
     * 
     * @type {OpetuksenYleisetTavoitteetDto}
     * @memberof OpetuksenYleisetTavoitteetPerusteOpsDto
     */
    perusteen?: OpetuksenYleisetTavoitteetDto;
    /**
     * 
     * @type {OpetuksenYleisetTavoitteetOpsDto}
     * @memberof OpetuksenYleisetTavoitteetPerusteOpsDto
     */
    paikallinen?: OpetuksenYleisetTavoitteetOpsDto;
    /**
     * 
     * @type {string}
     * @memberof OpetuksenYleisetTavoitteetPerusteOpsDto
     */
    kommentti?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetuksenYleisetTavoitteetPerusteOpsDto
     */
    tunniste?: string;
}
/**
 * 
 * @export
 * @interface OpetuksenYleisetTavoitteetUpdateDto
 */
export interface OpetuksenYleisetTavoitteetUpdateDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenYleisetTavoitteetUpdateDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetuksenYleisetTavoitteetUpdateDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OpetussuunnitelmaBaseDto
 */
export interface OpetussuunnitelmaBaseDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaBaseDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaBaseDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaBaseDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<OrganisaatioDto>}
     * @memberof OpetussuunnitelmaBaseDto
     */
    organisaatiot?: Array<OrganisaatioDto>;
    /**
     * 
     * @type {OrganisaatioDto}
     * @memberof OpetussuunnitelmaBaseDto
     */
    koulutuksenjarjestaja?: OrganisaatioDto;
    /**
     * 
     * @type {Array<KoodistoDto>}
     * @memberof OpetussuunnitelmaBaseDto
     */
    kunnat?: Array<KoodistoDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaBaseDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    hyvaksyjataho?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaBaseDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    perusteenDiaarinumero?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaBaseDto
     */
    perusteenId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    tila?: OpetussuunnitelmaBaseDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    tyyppi?: OpetussuunnitelmaBaseDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    koulutustyyppi?: OpetussuunnitelmaBaseDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    toteutus?: OpetussuunnitelmaBaseDtoToteutusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    ryhmaoid?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaBaseDto
     */
    ryhmanNimi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaBaseDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaBaseDto
     */
    ainepainoitteinen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    perusteenVoimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    perusteenVoimassaoloLoppuu?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaBaseDto
     */
    tuoPohjanOpintojaksot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaBaseDto
     */
    tuoPohjanOppimaarat?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    perusteDataTuontiPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    viimeisinSyncPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaBaseDto
     */
    viimeisinJulkaisuAika?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaBaseDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaBaseDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaBaseDtoTyyppiEnum {
    OPS = 'OPS',
    POHJA = 'POHJA'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaBaseDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaBaseDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaDto
 */
export interface OpetussuunnitelmaDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<OrganisaatioDto>}
     * @memberof OpetussuunnitelmaDto
     */
    organisaatiot?: Array<OrganisaatioDto>;
    /**
     * 
     * @type {OrganisaatioDto}
     * @memberof OpetussuunnitelmaDto
     */
    koulutuksenjarjestaja?: OrganisaatioDto;
    /**
     * 
     * @type {Array<KoodistoDto>}
     * @memberof OpetussuunnitelmaDto
     */
    kunnat?: Array<KoodistoDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    hyvaksyjataho?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    perusteenDiaarinumero?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaDto
     */
    perusteenId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    tila?: OpetussuunnitelmaDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    tyyppi?: OpetussuunnitelmaDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    koulutustyyppi?: OpetussuunnitelmaDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    toteutus?: OpetussuunnitelmaDtoToteutusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    ryhmaoid?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaDto
     */
    ryhmanNimi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaDto
     */
    ainepainoitteinen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    perusteenVoimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    perusteenVoimassaoloLoppuu?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaDto
     */
    tuoPohjanOpintojaksot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaDto
     */
    tuoPohjanOppimaarat?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    perusteDataTuontiPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    viimeisinSyncPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {OpetussuunnitelmaNimiDto}
     * @memberof OpetussuunnitelmaDto
     */
    pohja?: OpetussuunnitelmaNimiDto;
    /**
     * 
     * @type {Puu}
     * @memberof OpetussuunnitelmaDto
     */
    tekstit?: Puu;
    /**
     * 
     * @type {Array<OpsVuosiluokkakokonaisuusDto>}
     * @memberof OpetussuunnitelmaDto
     */
    vuosiluokkakokonaisuudet?: Array<OpsVuosiluokkakokonaisuusDto>;
    /**
     * 
     * @type {Array<OpsOppiaineDto>}
     * @memberof OpetussuunnitelmaDto
     */
    oppiaineet?: Array<OpsOppiaineDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoTyyppiEnum {
    OPS = 'OPS',
    POHJA = 'POHJA'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaExportDto
 */
export interface OpetussuunnitelmaExportDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaExportDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaExportDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaExportDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<OrganisaatioDto>}
     * @memberof OpetussuunnitelmaExportDto
     */
    organisaatiot?: Array<OrganisaatioDto>;
    /**
     * 
     * @type {OrganisaatioDto}
     * @memberof OpetussuunnitelmaExportDto
     */
    koulutuksenjarjestaja?: OrganisaatioDto;
    /**
     * 
     * @type {Array<KoodistoDto>}
     * @memberof OpetussuunnitelmaExportDto
     */
    kunnat?: Array<KoodistoDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaExportDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaExportDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaExportDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    hyvaksyjataho?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaExportDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    perusteenDiaarinumero?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaExportDto
     */
    perusteenId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    tila?: OpetussuunnitelmaExportDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    tyyppi?: OpetussuunnitelmaExportDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    koulutustyyppi?: OpetussuunnitelmaExportDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    toteutus?: OpetussuunnitelmaExportDtoToteutusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaExportDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    ryhmaoid?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaExportDto
     */
    ryhmanNimi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaExportDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaExportDto
     */
    ainepainoitteinen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaExportDto
     */
    perusteenVoimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaExportDto
     */
    perusteenVoimassaoloLoppuu?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaExportDto
     */
    tuoPohjanOpintojaksot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaExportDto
     */
    tuoPohjanOppimaarat?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaExportDto
     */
    perusteDataTuontiPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaExportDto
     */
    viimeisinSyncPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaExportDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {PerusteInfoDto}
     * @memberof OpetussuunnitelmaExportDto
     */
    peruste?: PerusteInfoDto;
    /**
     * 
     * @type {OpetussuunnitelmaNimiDto}
     * @memberof OpetussuunnitelmaExportDto
     */
    pohja?: OpetussuunnitelmaNimiDto;
    /**
     * 
     * @type {Puu}
     * @memberof OpetussuunnitelmaExportDto
     */
    tekstit?: Puu;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaExportDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaExportDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaExportDtoTyyppiEnum {
    OPS = 'OPS',
    POHJA = 'POHJA'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaExportDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaExportDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaInfoDto
 */
export interface OpetussuunnitelmaInfoDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaInfoDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaInfoDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaInfoDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<OrganisaatioDto>}
     * @memberof OpetussuunnitelmaInfoDto
     */
    organisaatiot?: Array<OrganisaatioDto>;
    /**
     * 
     * @type {OrganisaatioDto}
     * @memberof OpetussuunnitelmaInfoDto
     */
    koulutuksenjarjestaja?: OrganisaatioDto;
    /**
     * 
     * @type {Array<KoodistoDto>}
     * @memberof OpetussuunnitelmaInfoDto
     */
    kunnat?: Array<KoodistoDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaInfoDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    hyvaksyjataho?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaInfoDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    perusteenDiaarinumero?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaInfoDto
     */
    perusteenId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    tila?: OpetussuunnitelmaInfoDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    tyyppi?: OpetussuunnitelmaInfoDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    koulutustyyppi?: OpetussuunnitelmaInfoDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    toteutus?: OpetussuunnitelmaInfoDtoToteutusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    ryhmaoid?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaInfoDto
     */
    ryhmanNimi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaInfoDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaInfoDto
     */
    ainepainoitteinen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    perusteenVoimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    perusteenVoimassaoloLoppuu?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaInfoDto
     */
    tuoPohjanOpintojaksot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaInfoDto
     */
    tuoPohjanOppimaarat?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    perusteDataTuontiPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    viimeisinSyncPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    julkaistu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaInfoDto
     */
    ensijulkaisu?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaInfoDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaInfoDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaInfoDtoTyyppiEnum {
    OPS = 'OPS',
    POHJA = 'POHJA'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaInfoDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaInfoDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaJulkaistuQuery
 */
export interface OpetussuunnitelmaJulkaistuQuery {
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaJulkaistuQuery
     */
    koulutustyypit?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkaistuQuery
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkaistuQuery
     */
    kieli?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkaistuQuery
     */
    perusteenDiaarinumero?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaJulkaistuQuery
     */
    sivu?: number;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaJulkaistuQuery
     */
    sivukoko?: number;
}
/**
 * 
 * @export
 * @interface OpetussuunnitelmaJulkinenDto
 */
export interface OpetussuunnitelmaJulkinenDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaJulkinenDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<OrganisaatioDto>}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    organisaatiot?: Array<OrganisaatioDto>;
    /**
     * 
     * @type {Array<KoodistoDto>}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    kunnat?: Array<KoodistoDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    koulutustyyppi?: OpetussuunnitelmaJulkinenDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    tila?: OpetussuunnitelmaJulkinenDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    tyyppi?: OpetussuunnitelmaJulkinenDtoTyyppiEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaJulkinenDto
     */
    toteutus?: OpetussuunnitelmaJulkinenDtoToteutusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaJulkinenDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaJulkinenDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaJulkinenDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaJulkinenDtoTyyppiEnum {
    OPS = 'OPS',
    POHJA = 'POHJA'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaJulkinenDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaKevytDto
 */
export interface OpetussuunnitelmaKevytDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaKevytDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaKevytDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaKevytDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<OrganisaatioDto>}
     * @memberof OpetussuunnitelmaKevytDto
     */
    organisaatiot?: Array<OrganisaatioDto>;
    /**
     * 
     * @type {OrganisaatioDto}
     * @memberof OpetussuunnitelmaKevytDto
     */
    koulutuksenjarjestaja?: OrganisaatioDto;
    /**
     * 
     * @type {Array<KoodistoDto>}
     * @memberof OpetussuunnitelmaKevytDto
     */
    kunnat?: Array<KoodistoDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaKevytDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKevytDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKevytDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    hyvaksyjataho?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaKevytDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    perusteenDiaarinumero?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaKevytDto
     */
    perusteenId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    tila?: OpetussuunnitelmaKevytDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    tyyppi?: OpetussuunnitelmaKevytDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    koulutustyyppi?: OpetussuunnitelmaKevytDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    toteutus?: OpetussuunnitelmaKevytDtoToteutusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKevytDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    ryhmaoid?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaKevytDto
     */
    ryhmanNimi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaKevytDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaKevytDto
     */
    ainepainoitteinen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKevytDto
     */
    perusteenVoimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKevytDto
     */
    perusteenVoimassaoloLoppuu?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaKevytDto
     */
    tuoPohjanOpintojaksot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaKevytDto
     */
    tuoPohjanOppimaarat?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKevytDto
     */
    perusteDataTuontiPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKevytDto
     */
    viimeisinSyncPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaKevytDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {OpetussuunnitelmaBaseDto}
     * @memberof OpetussuunnitelmaKevytDto
     */
    pohja?: OpetussuunnitelmaBaseDto;
    /**
     * 
     * @type {Array<OpsVuosiluokkakokonaisuusKevytDto>}
     * @memberof OpetussuunnitelmaKevytDto
     */
    vuosiluokkakokonaisuudet?: Array<OpsVuosiluokkakokonaisuusKevytDto>;
    /**
     * 
     * @type {Array<OpsOppiaineKevytDto>}
     * @memberof OpetussuunnitelmaKevytDto
     */
    oppiaineet?: Array<OpsOppiaineKevytDto>;
    /**
     * 
     * @type {Array<OpetussuunnitelmaNimiDto>}
     * @memberof OpetussuunnitelmaKevytDto
     */
    periytyvatPohjat?: Array<OpetussuunnitelmaNimiDto>;
    /**
     * 
     * @type {Array<OpetussuunnitelmaNimiDto>}
     * @memberof OpetussuunnitelmaKevytDto
     */
    joissaPohjana?: Array<OpetussuunnitelmaNimiDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKevytDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKevytDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKevytDtoTyyppiEnum {
    OPS = 'OPS',
    POHJA = 'POHJA'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKevytDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaKevytDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaLuontiDto
 */
export interface OpetussuunnitelmaLuontiDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaLuontiDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<OrganisaatioDto>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    organisaatiot?: Array<OrganisaatioDto>;
    /**
     * 
     * @type {OrganisaatioDto}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    koulutuksenjarjestaja?: OrganisaatioDto;
    /**
     * 
     * @type {Array<KoodistoDto>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    kunnat?: Array<KoodistoDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    hyvaksyjataho?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    perusteenDiaarinumero?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    perusteenId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    tila?: OpetussuunnitelmaLuontiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    tyyppi?: OpetussuunnitelmaLuontiDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    koulutustyyppi?: OpetussuunnitelmaLuontiDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    toteutus?: OpetussuunnitelmaLuontiDtoToteutusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    ryhmaoid?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    ryhmanNimi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    ainepainoitteinen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    perusteenVoimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    perusteenVoimassaoloLoppuu?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    tuoPohjanOpintojaksot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    tuoPohjanOppimaarat?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    perusteDataTuontiPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    viimeisinSyncPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    pohja?: string;
    /**
     * 
     * @type {Puu}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    tekstit?: Puu;
    /**
     * 
     * @type {Array<OpsVuosiluokkakokonaisuusDto>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    vuosiluokkakokonaisuudet?: Array<OpsVuosiluokkakokonaisuusDto>;
    /**
     * 
     * @type {Array<OpsOppiaineDto>}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    oppiaineet?: Array<OpsOppiaineDto>;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaLuontiDto
     */
    luontityyppi?: OpetussuunnitelmaLuontiDtoLuontityyppiEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoTyyppiEnum {
    OPS = 'OPS',
    POHJA = 'POHJA'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaLuontiDtoLuontityyppiEnum {
    KOPIO = 'KOPIO',
    VIITTEILLA = 'VIITTEILLA',
    LEGACY = 'LEGACY'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmaNimiDto
 */
export interface OpetussuunnitelmaNimiDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaNimiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaNimiDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaNimiDto
     */
    _pohja?: string;
}
/**
 * 
 * @export
 * @interface OpetussuunnitelmaStatistiikkaDto
 */
export interface OpetussuunnitelmaStatistiikkaDto {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof OpetussuunnitelmaStatistiikkaDto
     */
    kielittain?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof OpetussuunnitelmaStatistiikkaDto
     */
    koulutustyypeittain?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof OpetussuunnitelmaStatistiikkaDto
     */
    tasoittain?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof OpetussuunnitelmaStatistiikkaDto
     */
    tiloittain?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface OpetussuunnitelmaTilastoDto
 */
export interface OpetussuunnitelmaTilastoDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    julkaisukielet?: Array<OpetussuunnitelmaTilastoDtoJulkaisukieletEnum>;
    /**
     * 
     * @type {Array<OrganisaatioDto>}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    organisaatiot?: Array<OrganisaatioDto>;
    /**
     * 
     * @type {OrganisaatioDto}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    koulutuksenjarjestaja?: OrganisaatioDto;
    /**
     * 
     * @type {Array<KoodistoDto>}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    kunnat?: Array<KoodistoDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    hyvaksyjataho?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    perusteenDiaarinumero?: string;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    perusteenId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    tila?: OpetussuunnitelmaTilastoDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    tyyppi?: OpetussuunnitelmaTilastoDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    koulutustyyppi?: OpetussuunnitelmaTilastoDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    toteutus?: OpetussuunnitelmaTilastoDtoToteutusEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    paatospaivamaara?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    ryhmaoid?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    ryhmanNimi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    ainepainoitteinen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    perusteenVoimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    perusteenVoimassaoloLoppuu?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    tuoPohjanOpintojaksot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    tuoPohjanOppimaarat?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    perusteDataTuontiPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    viimeisinSyncPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    julkaistu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    ensijulkaisu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmaTilastoDto
     */
    viimeisinTilaMuutosAika?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoJulkaisukieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoTyyppiEnum {
    OPS = 'OPS',
    POHJA = 'POHJA'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmaTilastoDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmanAikatauluDto
 */
export interface OpetussuunnitelmanAikatauluDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmanAikatauluDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmanAikatauluDto
     */
    opetussuunnitelmaId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmanAikatauluDto
     */
    tavoite?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmanAikatauluDto
     */
    tapahtuma?: OpetussuunnitelmanAikatauluDtoTapahtumaEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmanAikatauluDto
     */
    tapahtumapaiva?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmanAikatauluDtoTapahtumaEnum {
    LUOMINEN = 'LUOMINEN',
    TAVOITE = 'TAVOITE',
    JULKAISU = 'JULKAISU'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmanJulkaisuDto
 */
export interface OpetussuunnitelmanJulkaisuDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmanJulkaisuDto
     */
    id?: number;
    /**
     * 
     * @type {OpetussuunnitelmaInfoDto}
     * @memberof OpetussuunnitelmanJulkaisuDto
     */
    opetussuunnitelma?: OpetussuunnitelmaInfoDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmanJulkaisuDto
     */
    tiedote?: { [key: string]: string; };
    /**
     * 
     * @type {Array<number>}
     * @memberof OpetussuunnitelmanJulkaisuDto
     */
    dokumentit?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmanJulkaisuDto
     */
    revision?: number;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmanJulkaisuDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmanJulkaisuDto
     */
    luoja?: string;
    /**
     * 
     * @type {KayttajanTietoDto}
     * @memberof OpetussuunnitelmanJulkaisuDto
     */
    kayttajanTieto?: KayttajanTietoDto;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmanJulkaisuDto
     */
    tila?: OpetussuunnitelmanJulkaisuDtoTilaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmanJulkaisuDtoTilaEnum {
    JULKAISEMATON = 'JULKAISEMATON',
    KESKEN = 'KESKEN',
    JULKAISTU = 'JULKAISTU',
    VIRHE = 'VIRHE'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmanMuokkaustietoDto
 */
export interface OpetussuunnitelmanMuokkaustietoDto {
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    tapahtuma?: OpetussuunnitelmanMuokkaustietoDtoTapahtumaEnum;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    opetussuunnitelmaId?: number;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    kohdeId?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    kohde?: OpetussuunnitelmanMuokkaustietoDtoKohdeEnum;
    /**
     * 
     * @type {Date}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    lisatieto?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    poistettu?: boolean;
    /**
     * 
     * @type {Array<OpetussuunnitelmanMuokkaustietoLisaparametritDto>}
     * @memberof OpetussuunnitelmanMuokkaustietoDto
     */
    lisaparametrit?: Array<OpetussuunnitelmanMuokkaustietoLisaparametritDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmanMuokkaustietoDtoTapahtumaEnum {
    LUONTI = 'LUONTI',
    PAIVITYS = 'PAIVITYS',
    PALAUTUS = 'PALAUTUS',
    POISTO = 'POISTO',
    JULKAISU = 'JULKAISU',
    VIRHE = 'VIRHE'
}
/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmanMuokkaustietoDtoKohdeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Laajaalaiset = 'laajaalaiset',
    Laajaalainen = 'laajaalainen',
    Oppiaineet = 'oppiaineet',
    Oppimaarat = 'oppimaarat',
    Oppiaine = 'oppiaine',
    Poppiaine = 'poppiaine',
    Lukiooppiaineet2015 = 'lukiooppiaineet_2015',
    Lukiooppimaarat2015 = 'lukiooppimaarat_2015',
    Lukiooppiaine2015 = 'lukiooppiaine_2015',
    Lukiokurssit = 'lukiokurssit',
    Lukiokurssi = 'lukiokurssi',
    Moduulit = 'moduulit',
    Moduuli = 'moduuli',
    Opintojaksot = 'opintojaksot',
    Opintojakso = 'opintojakso',
    Opetussuunnitelma = 'opetussuunnitelma',
    OpetussuunnitelmaRakenne = 'opetussuunnitelma_rakenne',
    Termi = 'termi',
    Kommentti = 'kommentti',
    Perusopetusoppiaineet = 'perusopetusoppiaineet',
    Perusopetusoppiaine = 'perusopetusoppiaine',
    Perusopetuspaikallinenoppiaine = 'perusopetuspaikallinenoppiaine',
    Valinnaisetoppiaineet = 'valinnaisetoppiaineet',
    Vuosiluokkakokonaisuus = 'vuosiluokkakokonaisuus',
    Oppiaineenvuosiluokka = 'oppiaineenvuosiluokka',
    TavoitteetSisallotArviointi = 'tavoitteet_sisallot_arviointi',
    Peruste = 'peruste'
}

/**
 * 
 * @export
 * @interface OpetussuunnitelmanMuokkaustietoLisaparametritDto
 */
export interface OpetussuunnitelmanMuokkaustietoLisaparametritDto {
    /**
     * 
     * @type {string}
     * @memberof OpetussuunnitelmanMuokkaustietoLisaparametritDto
     */
    kohde?: OpetussuunnitelmanMuokkaustietoLisaparametritDtoKohdeEnum;
    /**
     * 
     * @type {number}
     * @memberof OpetussuunnitelmanMuokkaustietoLisaparametritDto
     */
    kohdeId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum OpetussuunnitelmanMuokkaustietoLisaparametritDtoKohdeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Laajaalaiset = 'laajaalaiset',
    Laajaalainen = 'laajaalainen',
    Oppiaineet = 'oppiaineet',
    Oppimaarat = 'oppimaarat',
    Oppiaine = 'oppiaine',
    Poppiaine = 'poppiaine',
    Lukiooppiaineet2015 = 'lukiooppiaineet_2015',
    Lukiooppimaarat2015 = 'lukiooppimaarat_2015',
    Lukiooppiaine2015 = 'lukiooppiaine_2015',
    Lukiokurssit = 'lukiokurssit',
    Lukiokurssi = 'lukiokurssi',
    Moduulit = 'moduulit',
    Moduuli = 'moduuli',
    Opintojaksot = 'opintojaksot',
    Opintojakso = 'opintojakso',
    Opetussuunnitelma = 'opetussuunnitelma',
    OpetussuunnitelmaRakenne = 'opetussuunnitelma_rakenne',
    Termi = 'termi',
    Kommentti = 'kommentti',
    Perusopetusoppiaineet = 'perusopetusoppiaineet',
    Perusopetusoppiaine = 'perusopetusoppiaine',
    Perusopetuspaikallinenoppiaine = 'perusopetuspaikallinenoppiaine',
    Valinnaisetoppiaineet = 'valinnaisetoppiaineet',
    Vuosiluokkakokonaisuus = 'vuosiluokkakokonaisuus',
    Oppiaineenvuosiluokka = 'oppiaineenvuosiluokka',
    TavoitteetSisallotArviointi = 'tavoitteet_sisallot_arviointi',
    Peruste = 'peruste'
}

/**
 * 
 * @export
 * @interface OppaineKurssiTreeStructureDto
 */
export interface OppaineKurssiTreeStructureDto {
    /**
     * 
     * @type {string}
     * @memberof OppaineKurssiTreeStructureDto
     */
    kommentti?: string;
    /**
     * 
     * @type {Array<LukiokurssiOppaineMuokkausDto>}
     * @memberof OppaineKurssiTreeStructureDto
     */
    kurssit?: Array<LukiokurssiOppaineMuokkausDto>;
    /**
     * 
     * @type {Array<OppiaineJarjestysDto>}
     * @memberof OppaineKurssiTreeStructureDto
     */
    oppiaineet?: Array<OppiaineJarjestysDto>;
}
/**
 * 
 * @export
 * @interface OppiaineDto
 */
export interface OppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    tila?: OppiaineDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    tyyppi?: OppiaineDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    valinnainenTyyppi?: OppiaineDtoValinnainenTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    laajuus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OppiaineDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OppiaineDto
     */
    tehtava?: TekstiosaDto;
    /**
     * 
     * @type {Array<OppiaineSuppeaDto>}
     * @memberof OppiaineDto
     */
    oppimaarat?: Array<OppiaineSuppeaDto>;
    /**
     * 
     * @type {Array<OpetuksenKohdealueDto>}
     * @memberof OppiaineDto
     */
    kohdealueet?: Array<OpetuksenKohdealueDto>;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkakokonaisuusDto>}
     * @memberof OppiaineDto
     */
    vuosiluokkakokonaisuudet?: Array<OppiaineenVuosiluokkakokonaisuusDto>;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {Date}
     * @memberof OppiaineDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {OppiaineDto}
     * @memberof OppiaineDto
     */
    pohjanOppiaine?: OppiaineDto;
    /**
     * 
     * @type {Array<VapaatekstiPaikallinentarkennusDto>}
     * @memberof OppiaineDto
     */
    vapaatTekstit?: Array<VapaatekstiPaikallinentarkennusDto>;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    _liittyvaOppiaine?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OppiaineDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OppiaineDtoTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    TAIDETAITOAINE = 'TAIDE_TAITOAINE',
    MUUVALINNAINEN = 'MUU_VALINNAINEN',
    LUKIO = 'LUKIO'
}
/**
    * @export
    * @enum {string}
    */
export enum OppiaineDtoValinnainenTyyppiEnum {
    SYVENTAVA = 'SYVENTAVA',
    SOVELTAVA = 'SOVELTAVA',
    EIMAARITETTY = 'EI_MAARITETTY'
}

/**
 * 
 * @export
 * @interface OppiaineJarjestysDto
 */
export interface OppiaineJarjestysDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineJarjestysDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OppiaineJarjestysDto
     */
    jarjestys?: number;
}
/**
 * 
 * @export
 * @interface OppiaineOpintojaksoDto
 */
export interface OppiaineOpintojaksoDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineOpintojaksoDto
     */
    id?: number;
    /**
     * 
     * @type {Array<OppiaineOpintojaksoDto>}
     * @memberof OppiaineOpintojaksoDto
     */
    lapset?: Array<OppiaineOpintojaksoDto>;
}
/**
 * 
 * @export
 * @interface OppiainePalautettuDto
 */
export interface OppiainePalautettuDto {
    /**
     * 
     * @type {number}
     * @memberof OppiainePalautettuDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiainePalautettuDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiainePalautettuDto
     */
    tila?: OppiainePalautettuDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OppiainePalautettuDto
     */
    tyyppi?: OppiainePalautettuDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OppiainePalautettuDto
     */
    valinnainenTyyppi?: OppiainePalautettuDtoValinnainenTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OppiainePalautettuDto
     */
    laajuus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OppiainePalautettuDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OppiainePalautettuDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof OppiainePalautettuDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OppiainePalautettuDto
     */
    vlkId?: number;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OppiainePalautettuDto
     */
    tehtava?: TekstiosaDto;
    /**
     * 
     * @type {Array<OppiaineDto>}
     * @memberof OppiainePalautettuDto
     */
    oppimaarat?: Array<OppiaineDto>;
    /**
     * 
     * @type {Array<OpetuksenKohdealueDto>}
     * @memberof OppiainePalautettuDto
     */
    kohdealueet?: Array<OpetuksenKohdealueDto>;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkakokonaisuusDto>}
     * @memberof OppiainePalautettuDto
     */
    vuosiluokkakokonaisuudet?: Array<OppiaineenVuosiluokkakokonaisuusDto>;
    /**
     * 
     * @type {string}
     * @memberof OppiainePalautettuDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiainePalautettuDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiainePalautettuDto
     */
    _liittyvaOppiaine?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OppiainePalautettuDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OppiainePalautettuDtoTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    TAIDETAITOAINE = 'TAIDE_TAITOAINE',
    MUUVALINNAINEN = 'MUU_VALINNAINEN',
    LUKIO = 'LUKIO'
}
/**
    * @export
    * @enum {string}
    */
export enum OppiainePalautettuDtoValinnainenTyyppiEnum {
    SYVENTAVA = 'SYVENTAVA',
    SOVELTAVA = 'SOVELTAVA',
    EIMAARITETTY = 'EI_MAARITETTY'
}

/**
 * 
 * @export
 * @interface OppiaineSuppeaDto
 */
export interface OppiaineSuppeaDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineSuppeaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    tila?: OppiaineSuppeaDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    tyyppi?: OppiaineSuppeaDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    valinnainenTyyppi?: OppiaineSuppeaDtoValinnainenTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    laajuus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineSuppeaDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OppiaineSuppeaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineSuppeaDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {Array<OppiaineSuppeaDto>}
     * @memberof OppiaineSuppeaDto
     */
    oppimaarat?: Array<OppiaineSuppeaDto>;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkakokonaisuusSuppeaDto>}
     * @memberof OppiaineSuppeaDto
     */
    vuosiluokkakokonaisuudet?: Array<OppiaineenVuosiluokkakokonaisuusSuppeaDto>;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    _liittyvaOppiaine?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OppiaineSuppeaDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OppiaineSuppeaDtoTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    TAIDETAITOAINE = 'TAIDE_TAITOAINE',
    MUUVALINNAINEN = 'MUU_VALINNAINEN',
    LUKIO = 'LUKIO'
}
/**
    * @export
    * @enum {string}
    */
export enum OppiaineSuppeaDtoValinnainenTyyppiEnum {
    SYVENTAVA = 'SYVENTAVA',
    SOVELTAVA = 'SOVELTAVA',
    EIMAARITETTY = 'EI_MAARITETTY'
}

/**
 * 
 * @export
 * @interface OppiaineenTallennusDto
 */
export interface OppiaineenTallennusDto {
    /**
     * 
     * @type {OppiaineDto}
     * @memberof OppiaineenTallennusDto
     */
    oppiaine?: OppiaineDto;
    /**
     * 
     * @type {number}
     * @memberof OppiaineenTallennusDto
     */
    vuosiluokkakokonaisuusId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OppiaineenTallennusDto
     */
    vuosiluokat?: Array<OppiaineenTallennusDtoVuosiluokatEnum>;
    /**
     * 
     * @type {Array<OpetuksenTavoiteDto>}
     * @memberof OppiaineenTallennusDto
     */
    tavoitteet?: Array<OpetuksenTavoiteDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OppiaineenTallennusDtoVuosiluokatEnum {
    _1 = 'VUOSILUOKKA_1',
    _2 = 'VUOSILUOKKA_2',
    _3 = 'VUOSILUOKKA_3',
    _4 = 'VUOSILUOKKA_4',
    _5 = 'VUOSILUOKKA_5',
    _6 = 'VUOSILUOKKA_6',
    _7 = 'VUOSILUOKKA_7',
    _8 = 'VUOSILUOKKA_8',
    _9 = 'VUOSILUOKKA_9'
}

/**
 * 
 * @export
 * @interface OppiaineenTavoitteenOpetuksenTavoiteDto
 */
export interface OppiaineenTavoitteenOpetuksenTavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineenTavoitteenOpetuksenTavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OppiaineenTavoitteenOpetuksenTavoiteDto
     */
    tavoite?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OppiaineenVuosiluokkaDto
 */
export interface OppiaineenVuosiluokkaDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineenVuosiluokkaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiaineenVuosiluokkaDto
     */
    vuosiluokka?: OppiaineenVuosiluokkaDtoVuosiluokkaEnum;
    /**
     * 
     * @type {Array<KeskeinenSisaltoalueDto>}
     * @memberof OppiaineenVuosiluokkaDto
     */
    sisaltoalueet?: Array<KeskeinenSisaltoalueDto>;
    /**
     * 
     * @type {Array<OpetuksenTavoiteDto>}
     * @memberof OppiaineenVuosiluokkaDto
     */
    tavoitteet?: Array<OpetuksenTavoiteDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OppiaineenVuosiluokkaDto
     */
    vapaaTeksti?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum OppiaineenVuosiluokkaDtoVuosiluokkaEnum {
    _1 = 'VUOSILUOKKA_1',
    _2 = 'VUOSILUOKKA_2',
    _3 = 'VUOSILUOKKA_3',
    _4 = 'VUOSILUOKKA_4',
    _5 = 'VUOSILUOKKA_5',
    _6 = 'VUOSILUOKKA_6',
    _7 = 'VUOSILUOKKA_7',
    _8 = 'VUOSILUOKKA_8',
    _9 = 'VUOSILUOKKA_9'
}

/**
 * 
 * @export
 * @interface OppiaineenVuosiluokkaKevytDto
 */
export interface OppiaineenVuosiluokkaKevytDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineenVuosiluokkaKevytDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiaineenVuosiluokkaKevytDto
     */
    vuosiluokka?: OppiaineenVuosiluokkaKevytDtoVuosiluokkaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OppiaineenVuosiluokkaKevytDtoVuosiluokkaEnum {
    _1 = 'VUOSILUOKKA_1',
    _2 = 'VUOSILUOKKA_2',
    _3 = 'VUOSILUOKKA_3',
    _4 = 'VUOSILUOKKA_4',
    _5 = 'VUOSILUOKKA_5',
    _6 = 'VUOSILUOKKA_6',
    _7 = 'VUOSILUOKKA_7',
    _8 = 'VUOSILUOKKA_8',
    _9 = 'VUOSILUOKKA_9'
}

/**
 * 
 * @export
 * @interface OppiaineenVuosiluokkakokonaisuusDto
 */
export interface OppiaineenVuosiluokkakokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    vuosiluokkakokonaisuus?: string;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    tehtava?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    yleistavoitteet?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    tyotavat?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    ohjaus?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    arviointi?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    tavoitteistaJohdetutOppimisenTavoitteet?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    sisaltoalueinfo?: TekstiosaDto;
    /**
     * 
     * @type {number}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    jnro?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    piilotettu?: boolean;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkaDto>}
     * @memberof OppiaineenVuosiluokkakokonaisuusDto
     */
    vuosiluokat?: Array<OppiaineenVuosiluokkaDto>;
}
/**
 * 
 * @export
 * @interface OppiaineenVuosiluokkakokonaisuusSuppeaDto
 */
export interface OppiaineenVuosiluokkakokonaisuusSuppeaDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineenVuosiluokkakokonaisuusSuppeaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiaineenVuosiluokkakokonaisuusSuppeaDto
     */
    vuosiluokkakokonaisuus?: string;
    /**
     * 
     * @type {number}
     * @memberof OppiaineenVuosiluokkakokonaisuusSuppeaDto
     */
    jnro?: number;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkaKevytDto>}
     * @memberof OppiaineenVuosiluokkakokonaisuusSuppeaDto
     */
    vuosiluokat?: Array<OppiaineenVuosiluokkaKevytDto>;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineenVuosiluokkakokonaisuusSuppeaDto
     */
    piilotettu?: boolean;
}
/**
 * 
 * @export
 * @interface OpsOppiaineDto
 */
export interface OpsOppiaineDto {
    /**
     * 
     * @type {boolean}
     * @memberof OpsOppiaineDto
     */
    oma?: boolean;
    /**
     * 
     * @type {OppiaineDto}
     * @memberof OpsOppiaineDto
     */
    oppiaine?: OppiaineDto;
    /**
     * 
     * @type {number}
     * @memberof OpsOppiaineDto
     */
    jnro?: number;
}
/**
 * 
 * @export
 * @interface OpsOppiaineKevytDto
 */
export interface OpsOppiaineKevytDto {
    /**
     * 
     * @type {boolean}
     * @memberof OpsOppiaineKevytDto
     */
    oma?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OpsOppiaineKevytDto
     */
    jnro?: number;
    /**
     * 
     * @type {OppiaineSuppeaDto}
     * @memberof OpsOppiaineKevytDto
     */
    oppiaine?: OppiaineSuppeaDto;
}
/**
 * 
 * @export
 * @interface OpsVuosiluokkakokonaisuusDto
 */
export interface OpsVuosiluokkakokonaisuusDto {
    /**
     * 
     * @type {boolean}
     * @memberof OpsVuosiluokkakokonaisuusDto
     */
    oma?: boolean;
    /**
     * 
     * @type {VuosiluokkakokonaisuusDto}
     * @memberof OpsVuosiluokkakokonaisuusDto
     */
    vuosiluokkakokonaisuus?: VuosiluokkakokonaisuusDto;
    /**
     * 
     * @type {OpsVuosiluokkakokonaisuusLisatietoDto}
     * @memberof OpsVuosiluokkakokonaisuusDto
     */
    lisatieto?: OpsVuosiluokkakokonaisuusLisatietoDto;
}
/**
 * 
 * @export
 * @interface OpsVuosiluokkakokonaisuusKevytDto
 */
export interface OpsVuosiluokkakokonaisuusKevytDto {
    /**
     * 
     * @type {boolean}
     * @memberof OpsVuosiluokkakokonaisuusKevytDto
     */
    oma?: boolean;
    /**
     * 
     * @type {VuosiluokkakokonaisuusSuppeaDto}
     * @memberof OpsVuosiluokkakokonaisuusKevytDto
     */
    vuosiluokkakokonaisuus?: VuosiluokkakokonaisuusSuppeaDto;
    /**
     * 
     * @type {OpsVuosiluokkakokonaisuusLisatietoDto}
     * @memberof OpsVuosiluokkakokonaisuusKevytDto
     */
    lisatieto?: OpsVuosiluokkakokonaisuusLisatietoDto;
}
/**
 * 
 * @export
 * @interface OpsVuosiluokkakokonaisuusLisatietoDto
 */
export interface OpsVuosiluokkakokonaisuusLisatietoDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof OpsVuosiluokkakokonaisuusLisatietoDto
     */
    piilotetutOppiaineet?: Array<number>;
}
/**
 * 
 * @export
 * @interface OrganisaatioDto
 */
export interface OrganisaatioDto {
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioDto
     */
    oid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisaatioDto
     */
    tyypit?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrganisaatioDto
     */
    nimi?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OrganisaatioLaajaDto
 */
export interface OrganisaatioLaajaDto {
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioLaajaDto
     */
    oid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisaatioLaajaDto
     */
    tyypit?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrganisaatioLaajaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioLaajaDto
     */
    kotipaikkaUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioLaajaDto
     */
    oppilaitosKoodi?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioLaajaDto
     */
    oppilaitostyyppi?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisaatioLaajaDto
     */
    organisaatiotyypit?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioLaajaDto
     */
    parentOid?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisaatioLaajaDto
     */
    parentOidPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisaatioLaajaDto
     */
    parentPath?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageOpetussuunnitelmaInfoDto
 */
export interface PageOpetussuunnitelmaInfoDto {
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    size?: number;
    /**
     * 
     * @type {Array<OpetussuunnitelmaInfoDto>}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    content?: Array<OpetussuunnitelmaInfoDto>;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    last?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaInfoDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageOpetussuunnitelmaJulkinenDto
 */
export interface PageOpetussuunnitelmaJulkinenDto {
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    size?: number;
    /**
     * 
     * @type {Array<OpetussuunnitelmaJulkinenDto>}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    content?: Array<OpetussuunnitelmaJulkinenDto>;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    last?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageOpetussuunnitelmaJulkinenDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    offset?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    sort?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    unpaged?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    paged?: boolean;
}
/**
 * 
 * @export
 * @interface Part
 */
export interface Part {
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Part
     */
    size?: number;
    /**
     * 
     * @type {object}
     * @memberof Part
     */
    inputStream?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof Part
     */
    headerNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    submittedFileName?: string;
}
/**
 * 
 * @export
 * @interface PdfData
 */
export interface PdfData {
    /**
     * 
     * @type {string}
     * @memberof PdfData
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof PdfData
     */
    tila?: string;
}
/**
 * 
 * @export
 * @interface PerusopetuksenPerusteenSisaltoDto
 */
export interface PerusopetuksenPerusteenSisaltoDto {
    /**
     * 
     * @type {Array<PerusteLaajaalainenosaaminenDto>}
     * @memberof PerusopetuksenPerusteenSisaltoDto
     */
    laajaalaisetosaamiset?: Array<PerusteLaajaalainenosaaminenDto>;
    /**
     * 
     * @type {Array<PerusteVuosiluokkakokonaisuusDto>}
     * @memberof PerusopetuksenPerusteenSisaltoDto
     */
    vuosiluokkakokonaisuudet?: Array<PerusteVuosiluokkakokonaisuusDto>;
    /**
     * 
     * @type {Array<PerusteOppiaineDto>}
     * @memberof PerusopetuksenPerusteenSisaltoDto
     */
    oppiaineet?: Array<PerusteOppiaineDto>;
    /**
     * 
     * @type {TekstiKappaleViiteDto}
     * @memberof PerusopetuksenPerusteenSisaltoDto
     */
    sisalto?: TekstiKappaleViiteDto;
}
/**
 * 
 * @export
 * @interface PerusteDto
 */
export interface PerusteDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    koulutustyyppi?: PerusteDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {Array<PerusteKoulutusDto>}
     * @memberof PerusteDto
     */
    koulutukset?: Array<PerusteKoulutusDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteDto
     */
    kielet?: Array<PerusteDtoKieletEnum>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    siirtymaPaattyy?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    tila?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    tyyppi?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    toteutus?: PerusteDtoToteutusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteDto
     */
    korvattavatDiaarinumerot?: Array<string>;
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {PerusopetuksenPerusteenSisaltoDto}
     * @memberof PerusteDto
     */
    perusopetus?: PerusopetuksenPerusteenSisaltoDto;
    /**
     * 
     * @type {LukiokoulutuksenPerusteenSisaltoDto}
     * @memberof PerusteDto
     */
    lukiokoulutus?: LukiokoulutuksenPerusteenSisaltoDto;
    /**
     * 
     * @type {Lops2019SisaltoDto}
     * @memberof PerusteDto
     */
    lops2019?: Lops2019SisaltoDto;
    /**
     * 
     * @type {EsiopetuksenPerusteenSisaltoDto}
     * @memberof PerusteDto
     */
    esiopetus?: EsiopetuksenPerusteenSisaltoDto;
    /**
     * 
     * @type {TPOOpetuksenSisaltoDto}
     * @memberof PerusteDto
     */
    tpo?: TPOOpetuksenSisaltoDto;
    /**
     * 
     * @type {AipePerusteenSisaltoDto}
     * @memberof PerusteDto
     */
    aipe?: AipePerusteenSisaltoDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}

/**
 * 
 * @export
 * @interface PerusteInfoDto
 */
export interface PerusteInfoDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteInfoDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteInfoDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteInfoDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PerusteInfoDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteInfoDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteInfoDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteInfoDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteInfoDto
     */
    tila?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteInfoDto
     */
    koulutustyyppi?: PerusteInfoDtoKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteInfoDto
     */
    toteutus?: PerusteInfoDtoToteutusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteInfoDtoKoulutustyyppiEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    ESIOPETUS = 'ESIOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    AIKUISLUKIOKOULUTUS = 'AIKUISLUKIOKOULUTUS',
    TPO = 'TPO'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteInfoDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    TPO = 'TPO',
    LOPS = 'LOPS',
    LOPS2019 = 'LOPS2019'
}

/**
 * 
 * @export
 * @interface PerusteKeskeinensisaltoalueDto
 */
export interface PerusteKeskeinensisaltoalueDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteKeskeinensisaltoalueDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteKeskeinensisaltoalueDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteKeskeinensisaltoalueDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteKeskeinensisaltoalueDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PerusteKoulutusDto
 */
export interface PerusteKoulutusDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteKoulutusDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PerusteKoulutusDto
     */
    koulutuskoodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteKoulutusDto
     */
    koulutuskoodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteKoulutusDto
     */
    koulutusalakoodi?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteKoulutusDto
     */
    opintoalakoodi?: string;
}
/**
 * 
 * @export
 * @interface PerusteLaajaalainenosaaminenDto
 */
export interface PerusteLaajaalainenosaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteLaajaalainenosaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteLaajaalainenosaaminenDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteLaajaalainenosaaminenDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteLaajaalainenosaaminenDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PerusteOpetuksenkohdealueDto
 */
export interface PerusteOpetuksenkohdealueDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteOpetuksenkohdealueDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOpetuksenkohdealueDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOpetuksenkohdealueDto
     */
    kuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PerusteOpetuksentavoiteDto
 */
export interface PerusteOpetuksentavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteOpetuksentavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteOpetuksentavoiteDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOpetuksentavoiteDto
     */
    tavoite?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteOpetuksentavoiteDto
     */
    sisaltoalueet?: Array<string>;
    /**
     * 
     * @type {Array<PerusteOpetuksenkohdealueDto>}
     * @memberof PerusteOpetuksentavoiteDto
     */
    kohdealueet?: Array<PerusteOpetuksenkohdealueDto>;
    /**
     * 
     * @type {Array<PerusteTavoitteenArviointiDto>}
     * @memberof PerusteOpetuksentavoiteDto
     */
    arvioinninkohteet?: Array<PerusteTavoitteenArviointiDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOpetuksentavoiteDto
     */
    arvioinninKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOpetuksentavoiteDto
     */
    arvioinninOtsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOpetuksentavoiteDto
     */
    vapaaTeksti?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOpetuksentavoiteDto
     */
    tavoitteistaJohdetutOppimisenTavoitteet?: { [key: string]: string; };
    /**
     * 
     * @type {Array<PerusteOppiaineenTavoitteenOpetuksenTavoiteDto>}
     * @memberof PerusteOpetuksentavoiteDto
     */
    oppiaineenTavoitteenOpetuksenTavoitteet?: Array<PerusteOppiaineenTavoitteenOpetuksenTavoiteDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteOpetuksentavoiteDto
     */
    laajaalaisetosaamiset?: Array<string>;
}
/**
 * 
 * @export
 * @interface PerusteOppiaineDto
 */
export interface PerusteOppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteOppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteOppiaineDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteOppiaineDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteOppiaineDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteOppiaineDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteOppiaineDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOppiaineDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteOppiaineDto
     */
    tehtava?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {Array<TekstiKappaleDto>}
     * @memberof PerusteOppiaineDto
     */
    vapaatTekstit?: Array<TekstiKappaleDto>;
    /**
     * 
     * @type {Array<PerusteOppiaineDto>}
     * @memberof PerusteOppiaineDto
     */
    oppimaarat?: Array<PerusteOppiaineDto>;
    /**
     * 
     * @type {Array<PerusteOpetuksenkohdealueDto>}
     * @memberof PerusteOppiaineDto
     */
    kohdealueet?: Array<PerusteOpetuksenkohdealueDto>;
    /**
     * 
     * @type {Array<PerusteOppiaineenVuosiluokkakokonaisuusDto>}
     * @memberof PerusteOppiaineDto
     */
    vuosiluokkakokonaisuudet?: Array<PerusteOppiaineenVuosiluokkakokonaisuusDto>;
}
/**
 * 
 * @export
 * @interface PerusteOppiaineenTavoitteenOpetuksenTavoiteDto
 */
export interface PerusteOppiaineenTavoitteenOpetuksenTavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteOppiaineenTavoitteenOpetuksenTavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOppiaineenTavoitteenOpetuksenTavoiteDto
     */
    tavoite?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PerusteOppiaineenVuosiluokkakokonaisuusDto
 */
export interface PerusteOppiaineenVuosiluokkakokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    tehtava?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    tyotavat?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    ohjaus?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    arviointi?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    sisaltoalueinfo?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    opetuksenTavoitteetOtsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    vapaaTeksti?: { [key: string]: string; };
    /**
     * 
     * @type {Array<TekstiKappaleDto>}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    vapaatTekstit?: Array<TekstiKappaleDto>;
    /**
     * 
     * @type {Array<PerusteKeskeinensisaltoalueDto>}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    sisaltoalueet?: Array<PerusteKeskeinensisaltoalueDto>;
    /**
     * 
     * @type {Array<PerusteOpetuksentavoiteDto>}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    tavoitteet?: Array<PerusteOpetuksentavoiteDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    vuosiluokat?: Array<PerusteOppiaineenVuosiluokkakokonaisuusDtoVuosiluokatEnum>;
    /**
     * 
     * @type {string}
     * @memberof PerusteOppiaineenVuosiluokkakokonaisuusDto
     */
    _vuosiluokkakokonaisuus?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteOppiaineenVuosiluokkakokonaisuusDtoVuosiluokatEnum {
    _1 = 'VUOSILUOKKA_1',
    _2 = 'VUOSILUOKKA_2',
    _3 = 'VUOSILUOKKA_3',
    _4 = 'VUOSILUOKKA_4',
    _5 = 'VUOSILUOKKA_5',
    _6 = 'VUOSILUOKKA_6',
    _7 = 'VUOSILUOKKA_7',
    _8 = 'VUOSILUOKKA_8',
    _9 = 'VUOSILUOKKA_9'
}

/**
 * 
 * @export
 * @interface PerusteTavoitteenArviointiDto
 */
export interface PerusteTavoitteenArviointiDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteTavoitteenArviointiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteTavoitteenArviointiDto
     */
    arvioinninKohde?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteTavoitteenArviointiDto
     */
    osaamisenKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof PerusteTavoitteenArviointiDto
     */
    arvosana?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteTavoitteenArviointiDto
     */
    hyvanOsaamisenKuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PerusteTekstiOsaDto
 */
export interface PerusteTekstiOsaDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteTekstiOsaDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteTekstiOsaDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteTekstiOsaDto
     */
    teksti?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface PerusteVersionDto
 */
export interface PerusteVersionDto {
    /**
     * 
     * @type {Date}
     * @memberof PerusteVersionDto
     */
    aikaleima?: Date;
}
/**
 * 
 * @export
 * @interface PerusteVuosiluokkakokonaisuudenLaajaalainenosaaminenDto
 */
export interface PerusteVuosiluokkakokonaisuudenLaajaalainenosaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteVuosiluokkakokonaisuudenLaajaalainenosaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteVuosiluokkakokonaisuudenLaajaalainenosaaminenDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PerusteVuosiluokkakokonaisuudenLaajaalainenosaaminenDto
     */
    _laajaalainenosaaminen?: string;
}
/**
 * 
 * @export
 * @interface PerusteVuosiluokkakokonaisuusDto
 */
export interface PerusteVuosiluokkakokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    tunniste?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    vuosiluokat?: Array<PerusteVuosiluokkakokonaisuusDtoVuosiluokatEnum>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    siirtymaEdellisesta?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    tehtava?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    siirtymaSeuraavaan?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    laajaalainenOsaaminen?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {PerusteTekstiOsaDto}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    paikallisestiPaatettavatAsiat?: PerusteTekstiOsaDto;
    /**
     * 
     * @type {Array<TekstiKappaleDto>}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    vapaatTekstit?: Array<TekstiKappaleDto>;
    /**
     * 
     * @type {Array<PerusteVuosiluokkakokonaisuudenLaajaalainenosaaminenDto>}
     * @memberof PerusteVuosiluokkakokonaisuusDto
     */
    laajaalaisetosaamiset?: Array<PerusteVuosiluokkakokonaisuudenLaajaalainenosaaminenDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteVuosiluokkakokonaisuusDtoVuosiluokatEnum {
    _1 = 'VUOSILUOKKA_1',
    _2 = 'VUOSILUOKKA_2',
    _3 = 'VUOSILUOKKA_3',
    _4 = 'VUOSILUOKKA_4',
    _5 = 'VUOSILUOKKA_5',
    _6 = 'VUOSILUOKKA_6',
    _7 = 'VUOSILUOKKA_7',
    _8 = 'VUOSILUOKKA_8',
    _9 = 'VUOSILUOKKA_9'
}

/**
 * 
 * @export
 * @interface PoistettuDto
 */
export interface PoistettuDto {
    /**
     * 
     * @type {number}
     * @memberof PoistettuDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PoistettuDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof PoistettuDto
     */
    palautettu?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PoistettuDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof PoistettuDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PoistettuDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {Date}
     * @memberof PoistettuDto
     */
    muokattu?: Date;
}
/**
 * 
 * @export
 * @interface PoistettuOppiaineDto
 */
export interface PoistettuOppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof PoistettuOppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PoistettuOppiaineDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof PoistettuOppiaineDto
     */
    palautettu?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PoistettuOppiaineDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof PoistettuOppiaineDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PoistettuOppiaineDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {Date}
     * @memberof PoistettuOppiaineDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {number}
     * @memberof PoistettuOppiaineDto
     */
    oppiaine?: number;
}
/**
 * 
 * @export
 * @interface PoistettuTekstiKappaleDto
 */
export interface PoistettuTekstiKappaleDto {
    /**
     * 
     * @type {number}
     * @memberof PoistettuTekstiKappaleDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PoistettuTekstiKappaleDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof PoistettuTekstiKappaleDto
     */
    palautettu?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PoistettuTekstiKappaleDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof PoistettuTekstiKappaleDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PoistettuTekstiKappaleDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {Date}
     * @memberof PoistettuTekstiKappaleDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {number}
     * @memberof PoistettuTekstiKappaleDto
     */
    tekstiKappale?: number;
}
/**
 * 
 * @export
 * @interface Puu
 */
export interface Puu {
    /**
     * 
     * @type {number}
     * @memberof Puu
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof Puu
     */
    tekstiKappale?: TekstiKappaleDto;
    /**
     * 
     * @type {string}
     * @memberof Puu
     */
    omistussuhde?: PuuOmistussuhdeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Puu
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Puu
     */
    valmis?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Puu
     */
    perusteTekstikappaleId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Puu
     */
    naytaPerusteenTeksti?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Puu
     */
    naytaPohjanTeksti?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Puu
     */
    piilotettu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Puu
     */
    liite?: boolean;
    /**
     * 
     * @type {Array<Puu>}
     * @memberof Puu
     */
    lapset?: Array<Puu>;
    /**
     * 
     * @type {string}
     * @memberof Puu
     */
    _original?: string;
    /**
     * 
     * @type {string}
     * @memberof Puu
     */
    _tekstiKappale?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PuuOmistussuhdeEnum {
    OMA = 'OMA',
    LAINATTU = 'LAINATTU'
}

/**
 * 
 * @export
 * @interface RevisionDto
 */
export interface RevisionDto {
    /**
     * 
     * @type {number}
     * @memberof RevisionDto
     */
    numero?: number;
    /**
     * 
     * @type {Date}
     * @memberof RevisionDto
     */
    pvm?: Date;
    /**
     * 
     * @type {string}
     * @memberof RevisionDto
     */
    muokkaajaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionDto
     */
    kommentti?: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionDto
     */
    nimi?: string;
}
/**
 * 
 * @export
 * @interface RevisionKayttajaDto
 */
export interface RevisionKayttajaDto {
    /**
     * 
     * @type {number}
     * @memberof RevisionKayttajaDto
     */
    numero?: number;
    /**
     * 
     * @type {Date}
     * @memberof RevisionKayttajaDto
     */
    pvm?: Date;
    /**
     * 
     * @type {string}
     * @memberof RevisionKayttajaDto
     */
    muokkaajaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionKayttajaDto
     */
    kommentti?: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionKayttajaDto
     */
    nimi?: string;
    /**
     * 
     * @type {KayttajanTietoDto}
     * @memberof RevisionKayttajaDto
     */
    kayttajanTieto?: KayttajanTietoDto;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    unsorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    sorted?: boolean;
}
/**
 * 
 * @export
 * @interface TPOOpetuksenSisaltoDto
 */
export interface TPOOpetuksenSisaltoDto {
    /**
     * 
     * @type {TekstiKappaleViiteDto}
     * @memberof TPOOpetuksenSisaltoDto
     */
    sisalto?: TekstiKappaleViiteDto;
}
/**
 * 
 * @export
 * @interface TavoitteenArviointiDto
 */
export interface TavoitteenArviointiDto {
    /**
     * 
     * @type {number}
     * @memberof TavoitteenArviointiDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TavoitteenArviointiDto
     */
    arvioinninKohde?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TavoitteenArviointiDto
     */
    osaamisenKuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof TavoitteenArviointiDto
     */
    arvosana?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TavoitteenArviointiDto
     */
    hyvanOsaamisenKuvaus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface TekstiKappaleDto
 */
export interface TekstiKappaleDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    muokkaajanNimi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiKappaleDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiKappaleDto
     */
    teksti?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    tila?: TekstiKappaleDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleDto
     */
    valmis?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TekstiKappaleDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface TekstiKappaleKevytDto
 */
export interface TekstiKappaleKevytDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleKevytDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleKevytDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleKevytDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleKevytDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleKevytDto
     */
    muokkaajanNimi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiKappaleKevytDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleKevytDto
     */
    tila?: TekstiKappaleKevytDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleKevytDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleKevytDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleKevytDto
     */
    valmis?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TekstiKappaleKevytDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface TekstiKappaleViiteDto
 */
export interface TekstiKappaleViiteDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleViiteDto
     */
    id?: number;
    /**
     * 
     * @type {Array<TekstiKappaleViiteDto>}
     * @memberof TekstiKappaleViiteDto
     */
    lapset?: Array<TekstiKappaleViiteDto>;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof TekstiKappaleViiteDto
     */
    perusteenOsa?: TekstiKappaleDto;
}
/**
 * 
 * @export
 * @interface TekstiKappaleViitePerusteTekstillaDto
 */
export interface TekstiKappaleViitePerusteTekstillaDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    id?: number;
    /**
     * 
     * @type {TekstiKappaleKevytDto}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    tekstiKappale?: TekstiKappaleKevytDto;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    omistussuhde?: TekstiKappaleViitePerusteTekstillaDtoOmistussuhdeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    valmis?: boolean;
    /**
     * 
     * @type {Array<TekstiKappaleViitePerusteTekstillaDto>}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    lapset?: Array<TekstiKappaleViitePerusteTekstillaDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    liite?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    perusteTekstikappaleId?: number;
    /**
     * 
     * @type {TekstiKappaleDto}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    perusteenTekstikappale?: TekstiKappaleDto;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    piilotettu?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleViitePerusteTekstillaDto
     */
    _tekstiKappale?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TekstiKappaleViitePerusteTekstillaDtoOmistussuhdeEnum {
    OMA = 'OMA',
    LAINATTU = 'LAINATTU'
}

/**
 * 
 * @export
 * @interface TekstiOsaDto
 */
export interface TekstiOsaDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiOsaDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiOsaDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiOsaDto
     */
    teksti?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface TekstiosaDto
 */
export interface TekstiosaDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiosaDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiosaDto
     */
    otsikko?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TekstiosaDto
     */
    teksti?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface TermiDto
 */
export interface TermiDto {
    /**
     * 
     * @type {number}
     * @memberof TermiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TermiDto
     */
    avain?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TermiDto
     */
    termi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TermiDto
     */
    selitys?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof TermiDto
     */
    alaviite?: boolean;
}
/**
 * 
 * @export
 * @interface UnwrappedOpsOppiaineDto
 */
export interface UnwrappedOpsOppiaineDto {
    /**
     * 
     * @type {boolean}
     * @memberof UnwrappedOpsOppiaineDto
     */
    oma?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UnwrappedOpsOppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsOppiaineDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsOppiaineDto
     */
    tila?: UnwrappedOpsOppiaineDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsOppiaineDto
     */
    tyyppi?: UnwrappedOpsOppiaineDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsOppiaineDto
     */
    valinnainenTyyppi?: UnwrappedOpsOppiaineDtoValinnainenTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsOppiaineDto
     */
    laajuus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UnwrappedOpsOppiaineDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UnwrappedOpsOppiaineDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof UnwrappedOpsOppiaineDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof UnwrappedOpsOppiaineDto
     */
    tehtava?: TekstiosaDto;
    /**
     * 
     * @type {Array<OppiaineSuppeaDto>}
     * @memberof UnwrappedOpsOppiaineDto
     */
    oppimaarat?: Array<OppiaineSuppeaDto>;
    /**
     * 
     * @type {Array<OpetuksenKohdealueDto>}
     * @memberof UnwrappedOpsOppiaineDto
     */
    kohdealueet?: Array<OpetuksenKohdealueDto>;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkakokonaisuusDto>}
     * @memberof UnwrappedOpsOppiaineDto
     */
    vuosiluokkakokonaisuudet?: Array<OppiaineenVuosiluokkakokonaisuusDto>;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsOppiaineDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsOppiaineDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsOppiaineDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {Date}
     * @memberof UnwrappedOpsOppiaineDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {OppiaineDto}
     * @memberof UnwrappedOpsOppiaineDto
     */
    pohjanOppiaine?: OppiaineDto;
    /**
     * 
     * @type {Array<VapaatekstiPaikallinentarkennusDto>}
     * @memberof UnwrappedOpsOppiaineDto
     */
    vapaatTekstit?: Array<VapaatekstiPaikallinentarkennusDto>;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsOppiaineDto
     */
    _liittyvaOppiaine?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UnwrappedOpsOppiaineDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum UnwrappedOpsOppiaineDtoTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    TAIDETAITOAINE = 'TAIDE_TAITOAINE',
    MUUVALINNAINEN = 'MUU_VALINNAINEN',
    LUKIO = 'LUKIO'
}
/**
    * @export
    * @enum {string}
    */
export enum UnwrappedOpsOppiaineDtoValinnainenTyyppiEnum {
    SYVENTAVA = 'SYVENTAVA',
    SOVELTAVA = 'SOVELTAVA',
    EIMAARITETTY = 'EI_MAARITETTY'
}

/**
 * 
 * @export
 * @interface UnwrappedOpsVuosiluokkakokonaisuusDto
 */
export interface UnwrappedOpsVuosiluokkakokonaisuusDto {
    /**
     * 
     * @type {boolean}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    oma?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    siirtymaEdellisesta?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    tehtava?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    siirtymaSeuraavaan?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    laajaalainenosaaminen?: TekstiosaDto;
    /**
     * 
     * @type {string}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    tila?: UnwrappedOpsVuosiluokkakokonaisuusDtoTilaEnum;
    /**
     * 
     * @type {Array<LaajaalainenosaaminenDto>}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    laajaalaisetosaamiset?: Array<LaajaalainenosaaminenDto>;
    /**
     * 
     * @type {Array<VapaatekstiPaikallinentarkennusDto>}
     * @memberof UnwrappedOpsVuosiluokkakokonaisuusDto
     */
    vapaatTekstit?: Array<VapaatekstiPaikallinentarkennusDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum UnwrappedOpsVuosiluokkakokonaisuusDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface UpdateWrapperDtoLops2019OpintojaksoDto
 */
export interface UpdateWrapperDtoLops2019OpintojaksoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    kommentti?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    tuotu?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    koodi?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    laajuus?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    arviointi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    opiskeluymparistoTyotavat?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Lops2019OpintojaksonOppiaineDto>}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    oppiaineet?: Array<Lops2019OpintojaksonOppiaineDto>;
    /**
     * 
     * @type {Array<Lops2019OpintojaksonTavoiteDto>}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    tavoitteet?: Array<Lops2019OpintojaksonTavoiteDto>;
    /**
     * 
     * @type {Array<Lops2019OpintojaksonKeskeinenSisaltoDto>}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    keskeisetSisallot?: Array<Lops2019OpintojaksonKeskeinenSisaltoDto>;
    /**
     * 
     * @type {Array<Lops2019PaikallinenLaajaAlainenDto>}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    laajaAlainenOsaaminen?: Array<Lops2019PaikallinenLaajaAlainenDto>;
    /**
     * 
     * @type {Array<Lops2019OpintojaksonModuuliDto>}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    moduulit?: Array<Lops2019OpintojaksonModuuliDto>;
    /**
     * 
     * @type {Array<Lops2019OpintojaksoDto>}
     * @memberof UpdateWrapperDtoLops2019OpintojaksoDto
     */
    paikallisetOpintojaksot?: Array<Lops2019OpintojaksoDto>;
}
/**
 * 
 * @export
 * @interface UpdateWrapperDtoLops2019PaikallinenOppiaineDto
 */
export interface UpdateWrapperDtoLops2019PaikallinenOppiaineDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    kommentti?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    koodi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    kuvaus?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Lops2019PaikallinenLaajaAlainenDto>}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    laajaAlainenOsaaminen?: Array<Lops2019PaikallinenLaajaAlainenDto>;
    /**
     * 
     * @type {Lops2019TehtavaDto}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    tehtava?: Lops2019TehtavaDto;
    /**
     * 
     * @type {Lops2019OpiskeluymparistoTyotavatDto}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    opiskeluymparistoTyotavat?: Lops2019OpiskeluymparistoTyotavatDto;
    /**
     * 
     * @type {Lops2019ArviointiDto}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    arviointi?: Lops2019ArviointiDto;
    /**
     * 
     * @type {Lops2019OppiaineenTavoitteetDto}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    tavoitteet?: Lops2019OppiaineenTavoitteetDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateWrapperDtoLops2019PaikallinenOppiaineDto
     */
    perusteenOppiaineUri?: string;
}
/**
 * 
 * @export
 * @interface UusiJulkaisuDto
 */
export interface UusiJulkaisuDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UusiJulkaisuDto
     */
    julkaisutiedote?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Validointi
 */
export interface Validointi {
    /**
     * 
     * @type {string}
     * @memberof Validointi
     */
    kategoria?: ValidointiKategoriaEnum;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof Validointi
     */
    virheet?: Array<Virhe>;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof Validointi
     */
    huomautukset?: Array<Virhe>;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof Validointi
     */
    huomiot?: Array<Virhe>;
}

/**
    * @export
    * @enum {string}
    */
export enum ValidointiKategoriaEnum {
    OPINTOJAKSO = 'OPINTOJAKSO',
    OPPIAINE = 'OPPIAINE',
    MODUULI = 'MODUULI',
    OPETUSSUUNNITELMA = 'OPETUSSUUNNITELMA'
}

/**
 * 
 * @export
 * @interface VapaatekstiPaikallinentarkennusDto
 */
export interface VapaatekstiPaikallinentarkennusDto {
    /**
     * 
     * @type {number}
     * @memberof VapaatekstiPaikallinentarkennusDto
     */
    perusteenVapaaTekstiId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VapaatekstiPaikallinentarkennusDto
     */
    paikallinenTarkennus?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Virhe
 */
export interface Virhe {
    /**
     * 
     * @type {string}
     * @memberof Virhe
     */
    kuvaus?: string;
    /**
     * 
     * @type {NavigationNodeDto}
     * @memberof Virhe
     */
    navigationNode?: NavigationNodeDto;
}
/**
 * 
 * @export
 * @interface VuosiluokkakokonaisuusDto
 */
export interface VuosiluokkakokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof VuosiluokkakokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VuosiluokkakokonaisuusDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VuosiluokkakokonaisuusDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof VuosiluokkakokonaisuusDto
     */
    siirtymaEdellisesta?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof VuosiluokkakokonaisuusDto
     */
    tehtava?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof VuosiluokkakokonaisuusDto
     */
    siirtymaSeuraavaan?: TekstiosaDto;
    /**
     * 
     * @type {TekstiosaDto}
     * @memberof VuosiluokkakokonaisuusDto
     */
    laajaalainenosaaminen?: TekstiosaDto;
    /**
     * 
     * @type {string}
     * @memberof VuosiluokkakokonaisuusDto
     */
    tila?: VuosiluokkakokonaisuusDtoTilaEnum;
    /**
     * 
     * @type {Array<LaajaalainenosaaminenDto>}
     * @memberof VuosiluokkakokonaisuusDto
     */
    laajaalaisetosaamiset?: Array<LaajaalainenosaaminenDto>;
    /**
     * 
     * @type {Array<VapaatekstiPaikallinentarkennusDto>}
     * @memberof VuosiluokkakokonaisuusDto
     */
    vapaatTekstit?: Array<VapaatekstiPaikallinentarkennusDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum VuosiluokkakokonaisuusDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface VuosiluokkakokonaisuusSuppeaDto
 */
export interface VuosiluokkakokonaisuusSuppeaDto {
    /**
     * 
     * @type {number}
     * @memberof VuosiluokkakokonaisuusSuppeaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VuosiluokkakokonaisuusSuppeaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VuosiluokkakokonaisuusSuppeaDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof VuosiluokkakokonaisuusSuppeaDto
     */
    tila?: VuosiluokkakokonaisuusSuppeaDtoTilaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VuosiluokkakokonaisuusSuppeaDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU',
    JULKAISTU = 'JULKAISTU'
}


/**
 * AikatauluApi - axios parameter creator
 * @export
 */
export const AikatauluApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling _delete.');
            }
            // verify required parameter 'OpetussuunnitelmanAikatauluDto' is not null or undefined
            if (OpetussuunnitelmanAikatauluDto === null || OpetussuunnitelmanAikatauluDto === undefined) {
                throw new RequiredError('OpetussuunnitelmanAikatauluDto','Required parameter OpetussuunnitelmanAikatauluDto was null or undefined when calling _delete.');
            }
            const localVarPath = `/api/aikataulu/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OpetussuunnitelmanAikatauluDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OpetussuunnitelmanAikatauluDto !== undefined ? OpetussuunnitelmanAikatauluDto : {}) : (OpetussuunnitelmanAikatauluDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAikataulu(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAikataulu.');
            }
            const localVarPath = `/api/aikataulu/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling save.');
            }
            // verify required parameter 'OpetussuunnitelmanAikatauluDto' is not null or undefined
            if (OpetussuunnitelmanAikatauluDto === null || OpetussuunnitelmanAikatauluDto === undefined) {
                throw new RequiredError('OpetussuunnitelmanAikatauluDto','Required parameter OpetussuunnitelmanAikatauluDto was null or undefined when calling save.');
            }
            const localVarPath = `/api/aikataulu/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OpetussuunnitelmanAikatauluDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OpetussuunnitelmanAikatauluDto !== undefined ? OpetussuunnitelmanAikatauluDto : {}) : (OpetussuunnitelmanAikatauluDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling update.');
            }
            // verify required parameter 'OpetussuunnitelmanAikatauluDto' is not null or undefined
            if (OpetussuunnitelmanAikatauluDto === null || OpetussuunnitelmanAikatauluDto === undefined) {
                throw new RequiredError('OpetussuunnitelmanAikatauluDto','Required parameter OpetussuunnitelmanAikatauluDto was null or undefined when calling update.');
            }
            const localVarPath = `/api/aikataulu/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OpetussuunnitelmanAikatauluDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OpetussuunnitelmanAikatauluDto !== undefined ? OpetussuunnitelmanAikatauluDto : {}) : (OpetussuunnitelmanAikatauluDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AikatauluApi - functional programming interface
 * @export
 */
export const AikatauluApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AikatauluApiAxiosParamCreator(configuration)._delete(opsId, OpetussuunnitelmanAikatauluDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAikataulu(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmanAikatauluDto>> {
            const localVarAxiosArgs = AikatauluApiAxiosParamCreator(configuration).getAikataulu(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmanAikatauluDto> {
            const localVarAxiosArgs = AikatauluApiAxiosParamCreator(configuration).save(opsId, OpetussuunnitelmanAikatauluDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmanAikatauluDto> {
            const localVarAxiosArgs = AikatauluApiAxiosParamCreator(configuration).update(opsId, OpetussuunnitelmanAikatauluDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AikatauluApi - factory interface
 * @export
 */
export const AikatauluApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options?: any): AxiosPromise<void> {
            return AikatauluApiFp(configuration)._delete(opsId, OpetussuunnitelmanAikatauluDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAikataulu(opsId: number, options?: any): AxiosPromise<Array<OpetussuunnitelmanAikatauluDto>> {
            return AikatauluApiFp(configuration).getAikataulu(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options?: any): AxiosPromise<OpetussuunnitelmanAikatauluDto> {
            return AikatauluApiFp(configuration).save(opsId, OpetussuunnitelmanAikatauluDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options?: any): AxiosPromise<OpetussuunnitelmanAikatauluDto> {
            return AikatauluApiFp(configuration).update(opsId, OpetussuunnitelmanAikatauluDto, options)(axios, basePath);
        },
    };
};

/**
 * AikatauluApi - object-oriented interface
 * @export
 * @class AikatauluApi
 * @extends {BaseAPI}
 */
export class AikatauluApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AikatauluApi
     */
    public _delete(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options?: any) {
        return AikatauluApiFp(this.configuration)._delete(opsId, OpetussuunnitelmanAikatauluDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AikatauluApi
     */
    public getAikataulu(opsId: number, options?: any) {
        return AikatauluApiFp(this.configuration).getAikataulu(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AikatauluApi
     */
    public save(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options?: any) {
        return AikatauluApiFp(this.configuration).save(opsId, OpetussuunnitelmanAikatauluDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {OpetussuunnitelmanAikatauluDto} OpetussuunnitelmanAikatauluDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AikatauluApi
     */
    public update(opsId: number, OpetussuunnitelmanAikatauluDto: OpetussuunnitelmanAikatauluDto, options?: any) {
        return AikatauluApiFp(this.configuration).update(opsId, OpetussuunnitelmanAikatauluDto, options)(this.axios, this.basePath);
    }

}


/**
 * DokumentitApi - axios parameter creator
 * @export
 */
export const DokumentitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} [kieli] 
         * @param {InlineObject} [InlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addImage(opsId: number, tyyppi: string, kieli?: string, InlineObject?: InlineObject, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addImage.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling addImage.');
            }
            const localVarPath = `/api/dokumentit/kuva`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (opsId !== undefined) {
                localVarQueryParameter['opsId'] = opsId;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof InlineObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(InlineObject !== undefined ? InlineObject : {}) : (InlineObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(opsId: number, kieli?: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling create.');
            }
            const localVarPath = `/api/dokumentit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (opsId !== undefined) {
                localVarQueryParameter['opsId'] = opsId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(opsId: number, tyyppi: string, kieli?: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling deleteImage.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling deleteImage.');
            }
            const localVarPath = `/api/dokumentit/kuva`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (opsId !== undefined) {
                localVarQueryParameter['opsId'] = opsId;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(fileName: string, options: any = {}): RequestArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling get.');
            }
            const localVarPath = `/api/dokumentit/{fileName}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiKuva(opsId: number, kieli?: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getDokumenttiKuva.');
            }
            const localVarPath = `/api/dokumentit/dokumenttikuva`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (opsId !== undefined) {
                localVarQueryParameter['opsId'] = opsId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(opsId: number, tyyppi: string, kieli?: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getImage.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling getImage.');
            }
            const localVarPath = `/api/dokumentit/kuva`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (opsId !== undefined) {
                localVarQueryParameter['opsId'] = opsId;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuDokumentti(opsId: number, kieli: string, revision?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getJulkaistuDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getJulkaistuDokumentti.');
            }
            const localVarPath = `/api/dokumentit/julkaistu`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (opsId !== undefined) {
                localVarQueryParameter['opsId'] = opsId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (revision !== undefined) {
                localVarQueryParameter['revision'] = revision;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumentti(opsId: number, kieli?: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLatestDokumentti.');
            }
            const localVarPath = `/api/dokumentit/latest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (opsId !== undefined) {
                localVarQueryParameter['opsId'] = opsId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumenttiId(opsId: number, kieli?: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLatestDokumenttiId.');
            }
            const localVarPath = `/api/dokumentit/ops`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (opsId !== undefined) {
                localVarQueryParameter['opsId'] = opsId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        query(dokumenttiId: number, options: any = {}): RequestArgs {
            // verify required parameter 'dokumenttiId' is not null or undefined
            if (dokumenttiId === null || dokumenttiId === undefined) {
                throw new RequiredError('dokumenttiId','Required parameter dokumenttiId was null or undefined when calling query.');
            }
            const localVarPath = `/api/dokumentit/{dokumenttiId}/dokumentti`
                .replace(`{${"dokumenttiId"}}`, encodeURIComponent(String(dokumenttiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} PdfData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePdfData(dokumenttiId: number, PdfData: PdfData, options: any = {}): RequestArgs {
            // verify required parameter 'dokumenttiId' is not null or undefined
            if (dokumenttiId === null || dokumenttiId === undefined) {
                throw new RequiredError('dokumenttiId','Required parameter dokumenttiId was null or undefined when calling savePdfData.');
            }
            // verify required parameter 'PdfData' is not null or undefined
            if (PdfData === null || PdfData === undefined) {
                throw new RequiredError('PdfData','Required parameter PdfData was null or undefined when calling savePdfData.');
            }
            const localVarPath = `/api/dokumentit/pdf/data/{dokumenttiId}`
                .replace(`{${"dokumenttiId"}}`, encodeURIComponent(String(dokumenttiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof PdfData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(PdfData !== undefined ? PdfData : {}) : (PdfData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} PdfData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumenttiTila(dokumenttiId: number, PdfData: PdfData, options: any = {}): RequestArgs {
            // verify required parameter 'dokumenttiId' is not null or undefined
            if (dokumenttiId === null || dokumenttiId === undefined) {
                throw new RequiredError('dokumenttiId','Required parameter dokumenttiId was null or undefined when calling updateDokumenttiTila.');
            }
            // verify required parameter 'PdfData' is not null or undefined
            if (PdfData === null || PdfData === undefined) {
                throw new RequiredError('PdfData','Required parameter PdfData was null or undefined when calling updateDokumenttiTila.');
            }
            const localVarPath = `/api/dokumentit/pdf/tila/{dokumenttiId}`
                .replace(`{${"dokumenttiId"}}`, encodeURIComponent(String(dokumenttiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof PdfData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(PdfData !== undefined ? PdfData : {}) : (PdfData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DokumentitApi - functional programming interface
 * @export
 */
export const DokumentitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} [kieli] 
         * @param {InlineObject} [InlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addImage(opsId: number, tyyppi: string, kieli?: string, InlineObject?: InlineObject, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiKuvaDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).addImage(opsId, tyyppi, kieli, InlineObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(opsId: number, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).create(opsId, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(opsId: number, tyyppi: string, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).deleteImage(opsId, tyyppi, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(fileName: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).get(fileName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiKuva(opsId: number, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiKuvaDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getDokumenttiKuva(opsId, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(opsId: number, tyyppi: string, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getImage(opsId, tyyppi, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuDokumentti(opsId: number, kieli: string, revision?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getJulkaistuDokumentti(opsId, kieli, revision, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumentti(opsId: number, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getLatestDokumentti(opsId, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumenttiId(opsId: number, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getLatestDokumenttiId(opsId, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        query(dokumenttiId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).query(dokumenttiId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} PdfData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePdfData(dokumenttiId: number, PdfData: PdfData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).savePdfData(dokumenttiId, PdfData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} PdfData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumenttiTila(dokumenttiId: number, PdfData: PdfData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).updateDokumenttiTila(dokumenttiId, PdfData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DokumentitApi - factory interface
 * @export
 */
export const DokumentitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} [kieli] 
         * @param {InlineObject} [InlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addImage(opsId: number, tyyppi: string, kieli?: string, InlineObject?: InlineObject, options?: any): AxiosPromise<DokumenttiKuvaDto> {
            return DokumentitApiFp(configuration).addImage(opsId, tyyppi, kieli, InlineObject, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(opsId: number, kieli?: string, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).create(opsId, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(opsId: number, tyyppi: string, kieli?: string, options?: any): AxiosPromise<object> {
            return DokumentitApiFp(configuration).deleteImage(opsId, tyyppi, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(fileName: string, options?: any): AxiosPromise<object> {
            return DokumentitApiFp(configuration).get(fileName, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiKuva(opsId: number, kieli?: string, options?: any): AxiosPromise<DokumenttiKuvaDto> {
            return DokumentitApiFp(configuration).getDokumenttiKuva(opsId, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tyyppi 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(opsId: number, tyyppi: string, kieli?: string, options?: any): AxiosPromise<string> {
            return DokumentitApiFp(configuration).getImage(opsId, tyyppi, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} kieli 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuDokumentti(opsId: number, kieli: string, revision?: number, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).getJulkaistuDokumentti(opsId, kieli, revision, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumentti(opsId: number, kieli?: string, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).getLatestDokumentti(opsId, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumenttiId(opsId: number, kieli?: string, options?: any): AxiosPromise<number> {
            return DokumentitApiFp(configuration).getLatestDokumenttiId(opsId, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        query(dokumenttiId: number, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).query(dokumenttiId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} PdfData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePdfData(dokumenttiId: number, PdfData: PdfData, options?: any): AxiosPromise<string> {
            return DokumentitApiFp(configuration).savePdfData(dokumenttiId, PdfData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} PdfData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumenttiTila(dokumenttiId: number, PdfData: PdfData, options?: any): AxiosPromise<string> {
            return DokumentitApiFp(configuration).updateDokumenttiTila(dokumenttiId, PdfData, options)(axios, basePath);
        },
    };
};

/**
 * DokumentitApi - object-oriented interface
 * @export
 * @class DokumentitApi
 * @extends {BaseAPI}
 */
export class DokumentitApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {string} tyyppi 
     * @param {string} [kieli] 
     * @param {InlineObject} [InlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public addImage(opsId: number, tyyppi: string, kieli?: string, InlineObject?: InlineObject, options?: any) {
        return DokumentitApiFp(this.configuration).addImage(opsId, tyyppi, kieli, InlineObject, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public create(opsId: number, kieli?: string, options?: any) {
        return DokumentitApiFp(this.configuration).create(opsId, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} tyyppi 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public deleteImage(opsId: number, tyyppi: string, kieli?: string, options?: any) {
        return DokumentitApiFp(this.configuration).deleteImage(opsId, tyyppi, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public get(fileName: string, options?: any) {
        return DokumentitApiFp(this.configuration).get(fileName, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getDokumenttiKuva(opsId: number, kieli?: string, options?: any) {
        return DokumentitApiFp(this.configuration).getDokumenttiKuva(opsId, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} tyyppi 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getImage(opsId: number, tyyppi: string, kieli?: string, options?: any) {
        return DokumentitApiFp(this.configuration).getImage(opsId, tyyppi, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} kieli 
     * @param {number} [revision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getJulkaistuDokumentti(opsId: number, kieli: string, revision?: number, options?: any) {
        return DokumentitApiFp(this.configuration).getJulkaistuDokumentti(opsId, kieli, revision, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getLatestDokumentti(opsId: number, kieli?: string, options?: any) {
        return DokumentitApiFp(this.configuration).getLatestDokumentti(opsId, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getLatestDokumenttiId(opsId: number, kieli?: string, options?: any) {
        return DokumentitApiFp(this.configuration).getLatestDokumenttiId(opsId, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} dokumenttiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public query(dokumenttiId: number, options?: any) {
        return DokumentitApiFp(this.configuration).query(dokumenttiId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} dokumenttiId 
     * @param {PdfData} PdfData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public savePdfData(dokumenttiId: number, PdfData: PdfData, options?: any) {
        return DokumentitApiFp(this.configuration).savePdfData(dokumenttiId, PdfData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} dokumenttiId 
     * @param {PdfData} PdfData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public updateDokumenttiTila(dokumenttiId: number, PdfData: PdfData, options?: any) {
        return DokumentitApiFp(this.configuration).updateDokumenttiTila(dokumenttiId, PdfData, options)(this.axios, this.basePath);
    }

}


/**
 * JulkaisutApi - axios parameter creator
 * @export
 */
export const JulkaisutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} revision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktivoiJulkaisu(opsId: number, revision: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling aktivoiJulkaisu.');
            }
            // verify required parameter 'revision' is not null or undefined
            if (revision === null || revision === undefined) {
                throw new RequiredError('revision','Required parameter revision was null or undefined when calling aktivoiJulkaisu.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/aktivoi/{revision}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"revision"}}`, encodeURIComponent(String(revision)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisut(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getJulkaisut.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/julkaisut`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisutKevyt(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getJulkaisutKevyt.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/julkaisut/kevyt`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {UusiJulkaisuDto} UusiJulkaisuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaise(opsId: number, UusiJulkaisuDto: UusiJulkaisuDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling julkaise.');
            }
            // verify required parameter 'UusiJulkaisuDto' is not null or undefined
            if (UusiJulkaisuDto === null || UusiJulkaisuDto === undefined) {
                throw new RequiredError('UusiJulkaisuDto','Required parameter UusiJulkaisuDto was null or undefined when calling julkaise.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/julkaise`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof UusiJulkaisuDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(UusiJulkaisuDto !== undefined ? UusiJulkaisuDto : {}) : (UusiJulkaisuDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaisemattomiaMuutoksia(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling julkaisemattomiaMuutoksia.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/julkaisu/muutoksia`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuTila(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling viimeisinJulkaisuTila.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/viimeisinjulkaisutila`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JulkaisutApi - functional programming interface
 * @export
 */
export const JulkaisutApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} revision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktivoiJulkaisu(opsId: number, revision: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmanJulkaisuDto> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).aktivoiJulkaisu(opsId, revision, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisut(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmanJulkaisuDto>> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).getJulkaisut(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisutKevyt(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmanJulkaisuDto>> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).getJulkaisutKevyt(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {UusiJulkaisuDto} UusiJulkaisuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaise(opsId: number, UusiJulkaisuDto: UusiJulkaisuDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).julkaise(opsId, UusiJulkaisuDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaisemattomiaMuutoksia(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).julkaisemattomiaMuutoksia(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuTila(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).viimeisinJulkaisuTila(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JulkaisutApi - factory interface
 * @export
 */
export const JulkaisutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} revision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktivoiJulkaisu(opsId: number, revision: number, options?: any): AxiosPromise<OpetussuunnitelmanJulkaisuDto> {
            return JulkaisutApiFp(configuration).aktivoiJulkaisu(opsId, revision, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisut(opsId: number, options?: any): AxiosPromise<Array<OpetussuunnitelmanJulkaisuDto>> {
            return JulkaisutApiFp(configuration).getJulkaisut(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisutKevyt(opsId: number, options?: any): AxiosPromise<Array<OpetussuunnitelmanJulkaisuDto>> {
            return JulkaisutApiFp(configuration).getJulkaisutKevyt(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {UusiJulkaisuDto} UusiJulkaisuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaise(opsId: number, UusiJulkaisuDto: UusiJulkaisuDto, options?: any): AxiosPromise<void> {
            return JulkaisutApiFp(configuration).julkaise(opsId, UusiJulkaisuDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaisemattomiaMuutoksia(opsId: number, options?: any): AxiosPromise<boolean> {
            return JulkaisutApiFp(configuration).julkaisemattomiaMuutoksia(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuTila(opsId: number, options?: any): AxiosPromise<string> {
            return JulkaisutApiFp(configuration).viimeisinJulkaisuTila(opsId, options)(axios, basePath);
        },
    };
};

/**
 * JulkaisutApi - object-oriented interface
 * @export
 * @class JulkaisutApi
 * @extends {BaseAPI}
 */
export class JulkaisutApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {number} revision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public aktivoiJulkaisu(opsId: number, revision: number, options?: any) {
        return JulkaisutApiFp(this.configuration).aktivoiJulkaisu(opsId, revision, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public getJulkaisut(opsId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).getJulkaisut(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public getJulkaisutKevyt(opsId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).getJulkaisutKevyt(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {UusiJulkaisuDto} UusiJulkaisuDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public julkaise(opsId: number, UusiJulkaisuDto: UusiJulkaisuDto, options?: any) {
        return JulkaisutApiFp(this.configuration).julkaise(opsId, UusiJulkaisuDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public julkaisemattomiaMuutoksia(opsId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).julkaisemattomiaMuutoksia(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public viimeisinJulkaisuTila(opsId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).viimeisinJulkaisuTila(opsId, options)(this.axios, this.basePath);
    }

}


/**
 * JulkinenApi - axios parameter creator
 * @export
 */
export const JulkinenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Opetussuunnitelman tietojen haku
         * @param {number} opetussuunnitelmaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalOpetussuunnitelma(opetussuunnitelmaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opetussuunnitelmaId' is not null or undefined
            if (opetussuunnitelmaId === null || opetussuunnitelmaId === undefined) {
                throw new RequiredError('opetussuunnitelmaId','Required parameter opetussuunnitelmaId was null or undefined when calling getExternalOpetussuunnitelma.');
            }
            const localVarPath = `/api/external/opetussuunnitelma/{opetussuunnitelmaId}`
                .replace(`{${"opetussuunnitelmaId"}}`, encodeURIComponent(String(opetussuunnitelmaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opetussuunnitelman perusteen haku. Palauttaa perusteen version, mikä opetussuunnitelmalla oli käytössä opetussuunnitelman julkaisun hetkellä.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalOpetussuunnitelmanPeruste(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getExternalOpetussuunnitelmanPeruste.');
            }
            const localVarPath = `/api/external/opetussuunnitelma/{id}/peruste`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opetussuunnitelmien haku
         * @param {OpetussuunnitelmaJulkaistuQuery} query 
         * @param {string} [nimi] nimi
         * @param {Array<string>} [kieli] 
         * @param {string} [perusteenDiaarinumero] perusteenDiaarinumero
         * @param {Array<string>} [koulutustyypit] koulutustyypit
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmat(query: OpetussuunnitelmaJulkaistuQuery, nimi?: string, kieli?: Array<string>, perusteenDiaarinumero?: string, koulutustyypit?: Array<string>, sivu?: number, sivukoko?: number, options: any = {}): RequestArgs {
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query','Required parameter query was null or undefined when calling getOpetussuunnitelmat.');
            }
            const localVarPath = `/api/external/opetussuunnitelmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kieli) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (perusteenDiaarinumero !== undefined) {
                localVarQueryParameter['perusteenDiaarinumero'] = perusteenDiaarinumero;
            }

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JulkinenApi - functional programming interface
 * @export
 */
export const JulkinenApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Opetussuunnitelman tietojen haku
         * @param {number} opetussuunnitelmaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalOpetussuunnitelma(opetussuunnitelmaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaExportDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getExternalOpetussuunnitelma(opetussuunnitelmaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Opetussuunnitelman perusteen haku. Palauttaa perusteen version, mikä opetussuunnitelmalla oli käytössä opetussuunnitelman julkaisun hetkellä.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalOpetussuunnitelmanPeruste(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getExternalOpetussuunnitelmanPeruste(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Opetussuunnitelmien haku
         * @param {OpetussuunnitelmaJulkaistuQuery} query 
         * @param {string} [nimi] nimi
         * @param {Array<string>} [kieli] 
         * @param {string} [perusteenDiaarinumero] perusteenDiaarinumero
         * @param {Array<string>} [koulutustyypit] koulutustyypit
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmat(query: OpetussuunnitelmaJulkaistuQuery, nimi?: string, kieli?: Array<string>, perusteenDiaarinumero?: string, koulutustyypit?: Array<string>, sivu?: number, sivukoko?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpetussuunnitelmaJulkinenDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getOpetussuunnitelmat(query, nimi, kieli, perusteenDiaarinumero, koulutustyypit, sivu, sivukoko, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JulkinenApi - factory interface
 * @export
 */
export const JulkinenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Opetussuunnitelman tietojen haku
         * @param {number} opetussuunnitelmaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalOpetussuunnitelma(opetussuunnitelmaId: number, options?: any): AxiosPromise<OpetussuunnitelmaExportDto> {
            return JulkinenApiFp(configuration).getExternalOpetussuunnitelma(opetussuunnitelmaId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Opetussuunnitelman perusteen haku. Palauttaa perusteen version, mikä opetussuunnitelmalla oli käytössä opetussuunnitelman julkaisun hetkellä.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalOpetussuunnitelmanPeruste(id: number, options?: any): AxiosPromise<object> {
            return JulkinenApiFp(configuration).getExternalOpetussuunnitelmanPeruste(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Opetussuunnitelmien haku
         * @param {OpetussuunnitelmaJulkaistuQuery} query 
         * @param {string} [nimi] nimi
         * @param {Array<string>} [kieli] 
         * @param {string} [perusteenDiaarinumero] perusteenDiaarinumero
         * @param {Array<string>} [koulutustyypit] koulutustyypit
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmat(query: OpetussuunnitelmaJulkaistuQuery, nimi?: string, kieli?: Array<string>, perusteenDiaarinumero?: string, koulutustyypit?: Array<string>, sivu?: number, sivukoko?: number, options?: any): AxiosPromise<PageOpetussuunnitelmaJulkinenDto> {
            return JulkinenApiFp(configuration).getOpetussuunnitelmat(query, nimi, kieli, perusteenDiaarinumero, koulutustyypit, sivu, sivukoko, options)(axios, basePath);
        },
    };
};

/**
 * JulkinenApi - object-oriented interface
 * @export
 * @class JulkinenApi
 * @extends {BaseAPI}
 */
export class JulkinenApi extends BaseAPI {
    /**
     * 
     * @summary Opetussuunnitelman tietojen haku
     * @param {number} opetussuunnitelmaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getExternalOpetussuunnitelma(opetussuunnitelmaId: number, options?: any) {
        return JulkinenApiFp(this.configuration).getExternalOpetussuunnitelma(opetussuunnitelmaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Opetussuunnitelman perusteen haku. Palauttaa perusteen version, mikä opetussuunnitelmalla oli käytössä opetussuunnitelman julkaisun hetkellä.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getExternalOpetussuunnitelmanPeruste(id: number, options?: any) {
        return JulkinenApiFp(this.configuration).getExternalOpetussuunnitelmanPeruste(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Opetussuunnitelmien haku
     * @param {OpetussuunnitelmaJulkaistuQuery} query 
     * @param {string} [nimi] nimi
     * @param {Array<string>} [kieli] 
     * @param {string} [perusteenDiaarinumero] perusteenDiaarinumero
     * @param {Array<string>} [koulutustyypit] koulutustyypit
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getOpetussuunnitelmat(query: OpetussuunnitelmaJulkaistuQuery, nimi?: string, kieli?: Array<string>, perusteenDiaarinumero?: string, koulutustyypit?: Array<string>, sivu?: number, sivukoko?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getOpetussuunnitelmat(query, nimi, kieli, perusteenDiaarinumero, koulutustyypit, sivu, sivukoko, options)(this.axios, this.basePath);
    }

}


/**
 * KayttajatApi - axios parameter creator
 * @export
 */
export const KayttajatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttaja(options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanEtusivu(options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/etusivu`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioOikeudet(options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttaja/organisaatiot`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KayttajatApi - functional programming interface
 * @export
 */
export const KayttajatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttaja(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajanTietoDto> {
            const localVarAxiosArgs = KayttajatApiAxiosParamCreator(configuration).getKayttaja(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanEtusivu(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtusivuDto> {
            const localVarAxiosArgs = KayttajatApiAxiosParamCreator(configuration).getKayttajanEtusivu(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioOikeudet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = KayttajatApiAxiosParamCreator(configuration).getOrganisaatioOikeudet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KayttajatApi - factory interface
 * @export
 */
export const KayttajatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttaja(options?: any): AxiosPromise<KayttajanTietoDto> {
            return KayttajatApiFp(configuration).getKayttaja(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanEtusivu(options?: any): AxiosPromise<EtusivuDto> {
            return KayttajatApiFp(configuration).getKayttajanEtusivu(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioOikeudet(options?: any): AxiosPromise<Array<string>> {
            return KayttajatApiFp(configuration).getOrganisaatioOikeudet(options)(axios, basePath);
        },
    };
};

/**
 * KayttajatApi - object-oriented interface
 * @export
 * @class KayttajatApi
 * @extends {BaseAPI}
 */
export class KayttajatApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajatApi
     */
    public getKayttaja(options?: any) {
        return KayttajatApiFp(this.configuration).getKayttaja(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajatApi
     */
    public getKayttajanEtusivu(options?: any) {
        return KayttajatApiFp(this.configuration).getKayttajanEtusivu(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajatApi
     */
    public getOrganisaatioOikeudet(options?: any) {
        return KayttajatApiFp(this.configuration).getOrganisaatioOikeudet(options)(this.axios, this.basePath);
    }

}


/**
 * KommentitApi - axios parameter creator
 * @export
 */
export const KommentitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KommenttiDto} KommenttiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKommentti(KommenttiDto: KommenttiDto, options: any = {}): RequestArgs {
            // verify required parameter 'KommenttiDto' is not null or undefined
            if (KommenttiDto === null || KommenttiDto === undefined) {
                throw new RequiredError('KommenttiDto','Required parameter KommenttiDto was null or undefined when calling addKommentti.');
            }
            const localVarPath = `/api/kommentit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof KommenttiDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(KommenttiDto !== undefined ? KommenttiDto : {}) : (KommenttiDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKommentti(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteKommentti.');
            }
            const localVarPath = `/api/kommentit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByOpetussuunnitelma(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllKommentitByOpetussuunnitelma.');
            }
            const localVarPath = `/api/kommentit/opetussuunnitelmat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByOppiaine(id: number, vlkId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllKommentitByOppiaine.');
            }
            // verify required parameter 'vlkId' is not null or undefined
            if (vlkId === null || vlkId === undefined) {
                throw new RequiredError('vlkId','Required parameter vlkId was null or undefined when calling getAllKommentitByOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAllKommentitByOppiaine.');
            }
            const localVarPath = `/api/kommentit/opetussuunnitelmat/{id}/vuosiluokat/{vlkId}/oppiaine/{oppiaineId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"vlkId"}}`, encodeURIComponent(String(vlkId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByParent(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllKommentitByParent.');
            }
            const localVarPath = `/api/kommentit/parent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByTekstiKappaleViite(id: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllKommentitByTekstiKappaleViite.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getAllKommentitByTekstiKappaleViite.');
            }
            const localVarPath = `/api/kommentit/opetussuunnitelmat/{id}/tekstikappaleviitteet/{viiteId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {number} vlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByVuosiluokka(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllKommentitByVuosiluokka.');
            }
            // verify required parameter 'vlkId' is not null or undefined
            if (vlkId === null || vlkId === undefined) {
                throw new RequiredError('vlkId','Required parameter vlkId was null or undefined when calling getAllKommentitByVuosiluokka.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAllKommentitByVuosiluokka.');
            }
            // verify required parameter 'vlId' is not null or undefined
            if (vlId === null || vlId === undefined) {
                throw new RequiredError('vlId','Required parameter vlId was null or undefined when calling getAllKommentitByVuosiluokka.');
            }
            const localVarPath = `/api/kommentit/opetussuunnitelmat/{opsId}/opetus/vuosiluokat/{vlkId}/oppiaine/{oppiaineId}/vuosiluokka/{vlId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"vlkId"}}`, encodeURIComponent(String(vlkId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"vlId"}}`, encodeURIComponent(String(vlId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {number} vlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByVuosiluokka1(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllKommentitByVuosiluokka1.');
            }
            // verify required parameter 'vlkId' is not null or undefined
            if (vlkId === null || vlkId === undefined) {
                throw new RequiredError('vlkId','Required parameter vlkId was null or undefined when calling getAllKommentitByVuosiluokka1.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAllKommentitByVuosiluokka1.');
            }
            // verify required parameter 'vlId' is not null or undefined
            if (vlId === null || vlId === undefined) {
                throw new RequiredError('vlId','Required parameter vlId was null or undefined when calling getAllKommentitByVuosiluokka1.');
            }
            const localVarPath = `/api/kommentit/opetussuunnitelmat/{opsId}/opetus/vuosiluokat/{vlkId}/oppiaine/{oppiaineId}/vuosiluokka/{vlId}/tavoitteet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"vlkId"}}`, encodeURIComponent(String(vlkId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"vlId"}}`, encodeURIComponent(String(vlId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {number} vlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByVuosiluokka2(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllKommentitByVuosiluokka2.');
            }
            // verify required parameter 'vlkId' is not null or undefined
            if (vlkId === null || vlkId === undefined) {
                throw new RequiredError('vlkId','Required parameter vlkId was null or undefined when calling getAllKommentitByVuosiluokka2.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAllKommentitByVuosiluokka2.');
            }
            // verify required parameter 'vlId' is not null or undefined
            if (vlId === null || vlId === undefined) {
                throw new RequiredError('vlId','Required parameter vlId was null or undefined when calling getAllKommentitByVuosiluokka2.');
            }
            const localVarPath = `/api/kommentit/opetussuunnitelmat/{opsId}/opetus/vuosiluokat/{vlkId}/oppiaine/{oppiaineId}/vuosiluokka/{vlId}/sisaltoalueet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"vlkId"}}`, encodeURIComponent(String(vlkId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"vlId"}}`, encodeURIComponent(String(vlId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByYlin(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllKommentitByYlin.');
            }
            const localVarPath = `/api/kommentit/ylin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommentti(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getKommentti.');
            }
            const localVarPath = `/api/kommentit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {KommenttiDto} KommenttiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKommentti(id: number, KommenttiDto: KommenttiDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateKommentti.');
            }
            // verify required parameter 'KommenttiDto' is not null or undefined
            if (KommenttiDto === null || KommenttiDto === undefined) {
                throw new RequiredError('KommenttiDto','Required parameter KommenttiDto was null or undefined when calling updateKommentti.');
            }
            const localVarPath = `/api/kommentit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof KommenttiDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(KommenttiDto !== undefined ? KommenttiDto : {}) : (KommenttiDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KommentitApi - functional programming interface
 * @export
 */
export const KommentitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KommenttiDto} KommenttiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKommentti(KommenttiDto: KommenttiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KommenttiDto> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).addKommentti(KommenttiDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKommentti(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).deleteKommentti(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByOpetussuunnitelma(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommenttiDto>> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).getAllKommentitByOpetussuunnitelma(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByOppiaine(id: number, vlkId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommenttiDto>> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).getAllKommentitByOppiaine(id, vlkId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByParent(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommenttiDto>> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).getAllKommentitByParent(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByTekstiKappaleViite(id: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommenttiDto>> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).getAllKommentitByTekstiKappaleViite(id, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {number} vlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByVuosiluokka(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommenttiDto>> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).getAllKommentitByVuosiluokka(opsId, vlkId, oppiaineId, vlId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {number} vlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByVuosiluokka1(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommenttiDto>> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).getAllKommentitByVuosiluokka1(opsId, vlkId, oppiaineId, vlId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {number} vlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByVuosiluokka2(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommenttiDto>> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).getAllKommentitByVuosiluokka2(opsId, vlkId, oppiaineId, vlId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByYlin(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommenttiDto>> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).getAllKommentitByYlin(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommentti(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KommenttiDto> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).getKommentti(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {KommenttiDto} KommenttiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKommentti(id: number, KommenttiDto: KommenttiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KommenttiDto> {
            const localVarAxiosArgs = KommentitApiAxiosParamCreator(configuration).updateKommentti(id, KommenttiDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KommentitApi - factory interface
 * @export
 */
export const KommentitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {KommenttiDto} KommenttiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKommentti(KommenttiDto: KommenttiDto, options?: any): AxiosPromise<KommenttiDto> {
            return KommentitApiFp(configuration).addKommentti(KommenttiDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKommentti(id: number, options?: any): AxiosPromise<void> {
            return KommentitApiFp(configuration).deleteKommentti(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByOpetussuunnitelma(id: number, options?: any): AxiosPromise<Array<KommenttiDto>> {
            return KommentitApiFp(configuration).getAllKommentitByOpetussuunnitelma(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByOppiaine(id: number, vlkId: number, oppiaineId: number, options?: any): AxiosPromise<Array<KommenttiDto>> {
            return KommentitApiFp(configuration).getAllKommentitByOppiaine(id, vlkId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByParent(id: number, options?: any): AxiosPromise<Array<KommenttiDto>> {
            return KommentitApiFp(configuration).getAllKommentitByParent(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByTekstiKappaleViite(id: number, viiteId: number, options?: any): AxiosPromise<Array<KommenttiDto>> {
            return KommentitApiFp(configuration).getAllKommentitByTekstiKappaleViite(id, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {number} vlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByVuosiluokka(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options?: any): AxiosPromise<Array<KommenttiDto>> {
            return KommentitApiFp(configuration).getAllKommentitByVuosiluokka(opsId, vlkId, oppiaineId, vlId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {number} vlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByVuosiluokka1(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options?: any): AxiosPromise<Array<KommenttiDto>> {
            return KommentitApiFp(configuration).getAllKommentitByVuosiluokka1(opsId, vlkId, oppiaineId, vlId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} oppiaineId 
         * @param {number} vlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByVuosiluokka2(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options?: any): AxiosPromise<Array<KommenttiDto>> {
            return KommentitApiFp(configuration).getAllKommentitByVuosiluokka2(opsId, vlkId, oppiaineId, vlId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKommentitByYlin(id: number, options?: any): AxiosPromise<Array<KommenttiDto>> {
            return KommentitApiFp(configuration).getAllKommentitByYlin(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommentti(id: number, options?: any): AxiosPromise<KommenttiDto> {
            return KommentitApiFp(configuration).getKommentti(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {KommenttiDto} KommenttiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKommentti(id: number, KommenttiDto: KommenttiDto, options?: any): AxiosPromise<KommenttiDto> {
            return KommentitApiFp(configuration).updateKommentti(id, KommenttiDto, options)(axios, basePath);
        },
    };
};

/**
 * KommentitApi - object-oriented interface
 * @export
 * @class KommentitApi
 * @extends {BaseAPI}
 */
export class KommentitApi extends BaseAPI {
    /**
     * 
     * @param {KommenttiDto} KommenttiDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public addKommentti(KommenttiDto: KommenttiDto, options?: any) {
        return KommentitApiFp(this.configuration).addKommentti(KommenttiDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public deleteKommentti(id: number, options?: any) {
        return KommentitApiFp(this.configuration).deleteKommentti(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public getAllKommentitByOpetussuunnitelma(id: number, options?: any) {
        return KommentitApiFp(this.configuration).getAllKommentitByOpetussuunnitelma(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} vlkId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public getAllKommentitByOppiaine(id: number, vlkId: number, oppiaineId: number, options?: any) {
        return KommentitApiFp(this.configuration).getAllKommentitByOppiaine(id, vlkId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public getAllKommentitByParent(id: number, options?: any) {
        return KommentitApiFp(this.configuration).getAllKommentitByParent(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public getAllKommentitByTekstiKappaleViite(id: number, viiteId: number, options?: any) {
        return KommentitApiFp(this.configuration).getAllKommentitByTekstiKappaleViite(id, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} vlkId 
     * @param {number} oppiaineId 
     * @param {number} vlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public getAllKommentitByVuosiluokka(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options?: any) {
        return KommentitApiFp(this.configuration).getAllKommentitByVuosiluokka(opsId, vlkId, oppiaineId, vlId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} vlkId 
     * @param {number} oppiaineId 
     * @param {number} vlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public getAllKommentitByVuosiluokka1(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options?: any) {
        return KommentitApiFp(this.configuration).getAllKommentitByVuosiluokka1(opsId, vlkId, oppiaineId, vlId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} vlkId 
     * @param {number} oppiaineId 
     * @param {number} vlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public getAllKommentitByVuosiluokka2(opsId: number, vlkId: number, oppiaineId: number, vlId: number, options?: any) {
        return KommentitApiFp(this.configuration).getAllKommentitByVuosiluokka2(opsId, vlkId, oppiaineId, vlId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public getAllKommentitByYlin(id: number, options?: any) {
        return KommentitApiFp(this.configuration).getAllKommentitByYlin(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public getKommentti(id: number, options?: any) {
        return KommentitApiFp(this.configuration).getKommentti(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {KommenttiDto} KommenttiDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentitApi
     */
    public updateKommentti(id: number, KommenttiDto: KommenttiDto, options?: any) {
        return KommentitApiFp(this.configuration).updateKommentti(id, KommenttiDto, options)(this.axios, this.basePath);
    }

}


/**
 * KommentointiApi - axios parameter creator
 * @export
 */
export const KommentointiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KommenttiKahvaDto} KommenttiKahvaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKetju(KommenttiKahvaDto: KommenttiKahvaDto, options: any = {}): RequestArgs {
            // verify required parameter 'KommenttiKahvaDto' is not null or undefined
            if (KommenttiKahvaDto === null || KommenttiKahvaDto === undefined) {
                throw new RequiredError('KommenttiKahvaDto','Required parameter KommenttiKahvaDto was null or undefined when calling addKetju.');
            }
            const localVarPath = `/api/kommentointi`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof KommenttiKahvaDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(KommenttiKahvaDto !== undefined ? KommenttiKahvaDto : {}) : (KommenttiKahvaDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {Kommentti2019Dto} Kommentti2019Dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKommentti2019(ketjuUuid: string, Kommentti2019Dto: Kommentti2019Dto, options: any = {}): RequestArgs {
            // verify required parameter 'ketjuUuid' is not null or undefined
            if (ketjuUuid === null || ketjuUuid === undefined) {
                throw new RequiredError('ketjuUuid','Required parameter ketjuUuid was null or undefined when calling addKommentti2019.');
            }
            // verify required parameter 'Kommentti2019Dto' is not null or undefined
            if (Kommentti2019Dto === null || Kommentti2019Dto === undefined) {
                throw new RequiredError('Kommentti2019Dto','Required parameter Kommentti2019Dto was null or undefined when calling addKommentti2019.');
            }
            const localVarPath = `/api/kommentointi/{ketjuUuid}`
                .replace(`{${"ketjuUuid"}}`, encodeURIComponent(String(ketjuUuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof Kommentti2019Dto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(Kommentti2019Dto !== undefined ? Kommentti2019Dto : {}) : (Kommentti2019Dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommenttiByKetjuUuid(ketjuUuid: string, options: any = {}): RequestArgs {
            // verify required parameter 'ketjuUuid' is not null or undefined
            if (ketjuUuid === null || ketjuUuid === undefined) {
                throw new RequiredError('ketjuUuid','Required parameter ketjuUuid was null or undefined when calling getKommenttiByKetjuUuid.');
            }
            const localVarPath = `/api/kommentointi/{ketjuUuid}`
                .replace(`{${"ketjuUuid"}}`, encodeURIComponent(String(ketjuUuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poistaKommenttiKetju2019(ketjuUuid: string, options: any = {}): RequestArgs {
            // verify required parameter 'ketjuUuid' is not null or undefined
            if (ketjuUuid === null || ketjuUuid === undefined) {
                throw new RequiredError('ketjuUuid','Required parameter ketjuUuid was null or undefined when calling poistaKommenttiKetju2019.');
            }
            const localVarPath = `/api/kommentointi/{ketjuUuid}`
                .replace(`{${"ketjuUuid"}}`, encodeURIComponent(String(ketjuUuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {Kommentti2019Dto} Kommentti2019Dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKommentti2019(ketjuUuid: string, Kommentti2019Dto: Kommentti2019Dto, options: any = {}): RequestArgs {
            // verify required parameter 'ketjuUuid' is not null or undefined
            if (ketjuUuid === null || ketjuUuid === undefined) {
                throw new RequiredError('ketjuUuid','Required parameter ketjuUuid was null or undefined when calling updateKommentti2019.');
            }
            // verify required parameter 'Kommentti2019Dto' is not null or undefined
            if (Kommentti2019Dto === null || Kommentti2019Dto === undefined) {
                throw new RequiredError('Kommentti2019Dto','Required parameter Kommentti2019Dto was null or undefined when calling updateKommentti2019.');
            }
            const localVarPath = `/api/kommentointi/{ketjuUuid}`
                .replace(`{${"ketjuUuid"}}`, encodeURIComponent(String(ketjuUuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof Kommentti2019Dto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(Kommentti2019Dto !== undefined ? Kommentti2019Dto : {}) : (Kommentti2019Dto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KommentointiApi - functional programming interface
 * @export
 */
export const KommentointiApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KommenttiKahvaDto} KommenttiKahvaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKetju(KommenttiKahvaDto: KommenttiKahvaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KommenttiKahvaDto> {
            const localVarAxiosArgs = KommentointiApiAxiosParamCreator(configuration).addKetju(KommenttiKahvaDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {Kommentti2019Dto} Kommentti2019Dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKommentti2019(ketjuUuid: string, Kommentti2019Dto: Kommentti2019Dto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Kommentti2019Dto> {
            const localVarAxiosArgs = KommentointiApiAxiosParamCreator(configuration).addKommentti2019(ketjuUuid, Kommentti2019Dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommenttiByKetjuUuid(ketjuUuid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Kommentti2019Dto>> {
            const localVarAxiosArgs = KommentointiApiAxiosParamCreator(configuration).getKommenttiByKetjuUuid(ketjuUuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poistaKommenttiKetju2019(ketjuUuid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = KommentointiApiAxiosParamCreator(configuration).poistaKommenttiKetju2019(ketjuUuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {Kommentti2019Dto} Kommentti2019Dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKommentti2019(ketjuUuid: string, Kommentti2019Dto: Kommentti2019Dto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Kommentti2019Dto> {
            const localVarAxiosArgs = KommentointiApiAxiosParamCreator(configuration).updateKommentti2019(ketjuUuid, Kommentti2019Dto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KommentointiApi - factory interface
 * @export
 */
export const KommentointiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {KommenttiKahvaDto} KommenttiKahvaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKetju(KommenttiKahvaDto: KommenttiKahvaDto, options?: any): AxiosPromise<KommenttiKahvaDto> {
            return KommentointiApiFp(configuration).addKetju(KommenttiKahvaDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {Kommentti2019Dto} Kommentti2019Dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKommentti2019(ketjuUuid: string, Kommentti2019Dto: Kommentti2019Dto, options?: any): AxiosPromise<Kommentti2019Dto> {
            return KommentointiApiFp(configuration).addKommentti2019(ketjuUuid, Kommentti2019Dto, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommenttiByKetjuUuid(ketjuUuid: string, options?: any): AxiosPromise<Array<Kommentti2019Dto>> {
            return KommentointiApiFp(configuration).getKommenttiByKetjuUuid(ketjuUuid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        poistaKommenttiKetju2019(ketjuUuid: string, options?: any): AxiosPromise<void> {
            return KommentointiApiFp(configuration).poistaKommenttiKetju2019(ketjuUuid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} ketjuUuid 
         * @param {Kommentti2019Dto} Kommentti2019Dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKommentti2019(ketjuUuid: string, Kommentti2019Dto: Kommentti2019Dto, options?: any): AxiosPromise<Kommentti2019Dto> {
            return KommentointiApiFp(configuration).updateKommentti2019(ketjuUuid, Kommentti2019Dto, options)(axios, basePath);
        },
    };
};

/**
 * KommentointiApi - object-oriented interface
 * @export
 * @class KommentointiApi
 * @extends {BaseAPI}
 */
export class KommentointiApi extends BaseAPI {
    /**
     * 
     * @param {KommenttiKahvaDto} KommenttiKahvaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentointiApi
     */
    public addKetju(KommenttiKahvaDto: KommenttiKahvaDto, options?: any) {
        return KommentointiApiFp(this.configuration).addKetju(KommenttiKahvaDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ketjuUuid 
     * @param {Kommentti2019Dto} Kommentti2019Dto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentointiApi
     */
    public addKommentti2019(ketjuUuid: string, Kommentti2019Dto: Kommentti2019Dto, options?: any) {
        return KommentointiApiFp(this.configuration).addKommentti2019(ketjuUuid, Kommentti2019Dto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ketjuUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentointiApi
     */
    public getKommenttiByKetjuUuid(ketjuUuid: string, options?: any) {
        return KommentointiApiFp(this.configuration).getKommenttiByKetjuUuid(ketjuUuid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ketjuUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentointiApi
     */
    public poistaKommenttiKetju2019(ketjuUuid: string, options?: any) {
        return KommentointiApiFp(this.configuration).poistaKommenttiKetju2019(ketjuUuid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} ketjuUuid 
     * @param {Kommentti2019Dto} Kommentti2019Dto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentointiApi
     */
    public updateKommentti2019(ketjuUuid: string, Kommentti2019Dto: Kommentti2019Dto, options?: any) {
        return KommentointiApiFp(this.configuration).updateKommentti2019(ketjuUuid, Kommentti2019Dto, options)(this.axios, this.basePath);
    }

}


/**
 * KysymyksetApi - axios parameter creator
 * @export
 */
export const KysymyksetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KysymysDto} KysymysDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKysymys(KysymysDto: KysymysDto, options: any = {}): RequestArgs {
            // verify required parameter 'KysymysDto' is not null or undefined
            if (KysymysDto === null || KysymysDto === undefined) {
                throw new RequiredError('KysymysDto','Required parameter KysymysDto was null or undefined when calling createKysymys.');
            }
            const localVarPath = `/api/kysymykset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof KysymysDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(KysymysDto !== undefined ? KysymysDto : {}) : (KysymysDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKysymys(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteKysymys.');
            }
            const localVarPath = `/api/kysymykset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKysymykset(options: any = {}): RequestArgs {
            const localVarPath = `/api/kysymykset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {KysymysDto} KysymysDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKysymys(id: number, KysymysDto: KysymysDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateKysymys.');
            }
            // verify required parameter 'KysymysDto' is not null or undefined
            if (KysymysDto === null || KysymysDto === undefined) {
                throw new RequiredError('KysymysDto','Required parameter KysymysDto was null or undefined when calling updateKysymys.');
            }
            const localVarPath = `/api/kysymykset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof KysymysDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(KysymysDto !== undefined ? KysymysDto : {}) : (KysymysDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KysymyksetApi - functional programming interface
 * @export
 */
export const KysymyksetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KysymysDto} KysymysDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKysymys(KysymysDto: KysymysDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KysymysDto> {
            const localVarAxiosArgs = KysymyksetApiAxiosParamCreator(configuration).createKysymys(KysymysDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKysymys(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = KysymyksetApiAxiosParamCreator(configuration).deleteKysymys(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKysymykset(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KysymysDto>> {
            const localVarAxiosArgs = KysymyksetApiAxiosParamCreator(configuration).getKysymykset(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {KysymysDto} KysymysDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKysymys(id: number, KysymysDto: KysymysDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KysymysDto> {
            const localVarAxiosArgs = KysymyksetApiAxiosParamCreator(configuration).updateKysymys(id, KysymysDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KysymyksetApi - factory interface
 * @export
 */
export const KysymyksetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {KysymysDto} KysymysDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKysymys(KysymysDto: KysymysDto, options?: any): AxiosPromise<KysymysDto> {
            return KysymyksetApiFp(configuration).createKysymys(KysymysDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKysymys(id: number, options?: any): AxiosPromise<string> {
            return KysymyksetApiFp(configuration).deleteKysymys(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKysymykset(options?: any): AxiosPromise<Array<KysymysDto>> {
            return KysymyksetApiFp(configuration).getKysymykset(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {KysymysDto} KysymysDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKysymys(id: number, KysymysDto: KysymysDto, options?: any): AxiosPromise<KysymysDto> {
            return KysymyksetApiFp(configuration).updateKysymys(id, KysymysDto, options)(axios, basePath);
        },
    };
};

/**
 * KysymyksetApi - object-oriented interface
 * @export
 * @class KysymyksetApi
 * @extends {BaseAPI}
 */
export class KysymyksetApi extends BaseAPI {
    /**
     * 
     * @param {KysymysDto} KysymysDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KysymyksetApi
     */
    public createKysymys(KysymysDto: KysymysDto, options?: any) {
        return KysymyksetApiFp(this.configuration).createKysymys(KysymysDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KysymyksetApi
     */
    public deleteKysymys(id: number, options?: any) {
        return KysymyksetApiFp(this.configuration).deleteKysymys(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KysymyksetApi
     */
    public getKysymykset(options?: any) {
        return KysymyksetApiFp(this.configuration).getKysymykset(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {KysymysDto} KysymysDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KysymyksetApi
     */
    public updateKysymys(id: number, KysymysDto: KysymysDto, options?: any) {
        return KysymyksetApiFp(this.configuration).updateKysymys(id, KysymysDto, options)(this.axios, this.basePath);
    }

}


/**
 * LiitetiedostotApi - axios parameter creator
 * @export
 */
export const LiitetiedostotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiitetiedosto(opsId: number, id: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling deleteLiitetiedosto.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLiitetiedosto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/kuvat/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLiitteet(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLiitteet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/kuvat`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiitetiedosto(opsId: number, fileName: string, If_None_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLiitetiedosto.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getLiitetiedosto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/kuvat/{fileName}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(If_None_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} id 
         * @param {number} width 
         * @param {number} height 
         * @param {Part} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reScaleImg(opsId: number, id: string, width: number, height: number, file: Part, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling reScaleImg.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reScaleImg.');
            }
            // verify required parameter 'width' is not null or undefined
            if (width === null || width === undefined) {
                throw new RequiredError('width','Required parameter width was null or undefined when calling reScaleImg.');
            }
            // verify required parameter 'height' is not null or undefined
            if (height === null || height === undefined) {
                throw new RequiredError('height','Required parameter height was null or undefined when calling reScaleImg.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling reScaleImg.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/kuvat/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} nimi 
         * @param {Part} file 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(opsId: number, nimi: string, file: Part, width: number, height: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling upload.');
            }
            // verify required parameter 'nimi' is not null or undefined
            if (nimi === null || nimi === undefined) {
                throw new RequiredError('nimi','Required parameter nimi was null or undefined when calling upload.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling upload.');
            }
            // verify required parameter 'width' is not null or undefined
            if (width === null || width === undefined) {
                throw new RequiredError('width','Required parameter width was null or undefined when calling upload.');
            }
            // verify required parameter 'height' is not null or undefined
            if (height === null || height === undefined) {
                throw new RequiredError('height','Required parameter height was null or undefined when calling upload.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/kuvat`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiitetiedostotApi - functional programming interface
 * @export
 */
export const LiitetiedostotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiitetiedosto(opsId: number, id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).deleteLiitetiedosto(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLiitteet(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LiiteDto>> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getAllLiitteet(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiitetiedosto(opsId: number, fileName: string, If_None_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getLiitetiedosto(opsId, fileName, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} id 
         * @param {number} width 
         * @param {number} height 
         * @param {Part} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reScaleImg(opsId: number, id: string, width: number, height: number, file: Part, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).reScaleImg(opsId, id, width, height, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} nimi 
         * @param {Part} file 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(opsId: number, nimi: string, file: Part, width: number, height: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).upload(opsId, nimi, file, width, height, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LiitetiedostotApi - factory interface
 * @export
 */
export const LiitetiedostotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiitetiedosto(opsId: number, id: string, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).deleteLiitetiedosto(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLiitteet(opsId: number, options?: any): AxiosPromise<Array<LiiteDto>> {
            return LiitetiedostotApiFp(configuration).getAllLiitteet(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiitetiedosto(opsId: number, fileName: string, If_None_Match?: string, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).getLiitetiedosto(opsId, fileName, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} id 
         * @param {number} width 
         * @param {number} height 
         * @param {Part} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reScaleImg(opsId: number, id: string, width: number, height: number, file: Part, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).reScaleImg(opsId, id, width, height, file, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} nimi 
         * @param {Part} file 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(opsId: number, nimi: string, file: Part, width: number, height: number, options?: any): AxiosPromise<string> {
            return LiitetiedostotApiFp(configuration).upload(opsId, nimi, file, width, height, options)(axios, basePath);
        },
    };
};

/**
 * LiitetiedostotApi - object-oriented interface
 * @export
 * @class LiitetiedostotApi
 * @extends {BaseAPI}
 */
export class LiitetiedostotApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public deleteLiitetiedosto(opsId: number, id: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration).deleteLiitetiedosto(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getAllLiitteet(opsId: number, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getAllLiitteet(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} fileName 
     * @param {string} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getLiitetiedosto(opsId: number, fileName: string, If_None_Match?: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getLiitetiedosto(opsId, fileName, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} id 
     * @param {number} width 
     * @param {number} height 
     * @param {Part} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public reScaleImg(opsId: number, id: string, width: number, height: number, file: Part, options?: any) {
        return LiitetiedostotApiFp(this.configuration).reScaleImg(opsId, id, width, height, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} nimi 
     * @param {Part} file 
     * @param {number} width 
     * @param {number} height 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public upload(opsId: number, nimi: string, file: Part, width: number, height: number, options?: any) {
        return LiitetiedostotApiFp(this.configuration).upload(opsId, nimi, file, width, height, options)(this.axios, this.basePath);
    }

}


/**
 * Lops2019Api - axios parameter creator
 * @export
 */
export const Lops2019ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoved(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getRemoved.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/poistetut`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} poistettuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palauta(opsId: number, poistettuId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling palauta.');
            }
            // verify required parameter 'poistettuId' is not null or undefined
            if (poistettuId === null || poistettuId === undefined) {
                throw new RequiredError('poistettuId','Required parameter poistettuId was null or undefined when calling palauta.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/palauta/{poistettuId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"poistettuId"}}`, encodeURIComponent(String(poistettuId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Lops2019Api - functional programming interface
 * @export
 */
export const Lops2019ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoved(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019PoistettuDto>> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).getRemoved(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} poistettuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palauta(opsId: number, poistettuId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).palauta(opsId, poistettuId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * Lops2019Api - factory interface
 * @export
 */
export const Lops2019ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoved(opsId: number, options?: any): AxiosPromise<Array<Lops2019PoistettuDto>> {
            return Lops2019ApiFp(configuration).getRemoved(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} poistettuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palauta(opsId: number, poistettuId: number, options?: any): AxiosPromise<void> {
            return Lops2019ApiFp(configuration).palauta(opsId, poistettuId, options)(axios, basePath);
        },
    };
};

/**
 * Lops2019Api - object-oriented interface
 * @export
 * @class Lops2019Api
 * @extends {BaseAPI}
 */
export class Lops2019Api extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public getRemoved(opsId: number, options?: any) {
        return Lops2019ApiFp(this.configuration).getRemoved(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} poistettuId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public palauta(opsId: number, poistettuId: number, options?: any) {
        return Lops2019ApiFp(this.configuration).palauta(opsId, poistettuId, options)(this.axios, this.basePath);
    }

}


/**
 * Lops2019OpintojaksotApi - axios parameter creator
 * @export
 */
export const Lops2019OpintojaksotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {Lops2019OpintojaksoDto} Lops2019OpintojaksoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpintojakso(opsId: number, Lops2019OpintojaksoDto: Lops2019OpintojaksoDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addOpintojakso.');
            }
            // verify required parameter 'Lops2019OpintojaksoDto' is not null or undefined
            if (Lops2019OpintojaksoDto === null || Lops2019OpintojaksoDto === undefined) {
                throw new RequiredError('Lops2019OpintojaksoDto','Required parameter Lops2019OpintojaksoDto was null or undefined when calling addOpintojakso.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof Lops2019OpintojaksoDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(Lops2019OpintojaksoDto !== undefined ? Lops2019OpintojaksoDto : {}) : (Lops2019OpintojaksoDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [moduuliUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpintojaksot(opsId: number, moduuliUri?: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllOpintojaksot.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (moduuliUri !== undefined) {
                localVarQueryParameter['moduuliUri'] = moduuliUri;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpintojakso(opsId: number, opintojaksoId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpintojakso.');
            }
            // verify required parameter 'opintojaksoId' is not null or undefined
            if (opintojaksoId === null || opintojaksoId === undefined) {
                throw new RequiredError('opintojaksoId','Required parameter opintojaksoId was null or undefined when calling getOpintojakso.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/{opintojaksoId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"opintojaksoId"}}`, encodeURIComponent(String(opintojaksoId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpintojaksonOpetussuunnitelma(opsId: number, opintojaksoId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpintojaksonOpetussuunnitelma.');
            }
            // verify required parameter 'opintojaksoId' is not null or undefined
            if (opintojaksoId === null || opintojaksoId === undefined) {
                throw new RequiredError('opintojaksoId','Required parameter opintojaksoId was null or undefined when calling getOpintojaksonOpetussuunnitelma.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/{opintojaksoId}/opetussuunnitelma`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"opintojaksoId"}}`, encodeURIComponent(String(opintojaksoId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpintojaksonPeruste(opsId: number, opintojaksoId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpintojaksonPeruste.');
            }
            // verify required parameter 'opintojaksoId' is not null or undefined
            if (opintojaksoId === null || opintojaksoId === undefined) {
                throw new RequiredError('opintojaksoId','Required parameter opintojaksoId was null or undefined when calling getOpintojaksonPeruste.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/{opintojaksoId}/peruste`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"opintojaksoId"}}`, encodeURIComponent(String(opintojaksoId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTuodutOpintojaksot(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTuodutOpintojaksot.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/tuodut`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTuotuOpintojakso(opsId: number, opintojaksoId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTuotuOpintojakso.');
            }
            // verify required parameter 'opintojaksoId' is not null or undefined
            if (opintojaksoId === null || opintojaksoId === undefined) {
                throw new RequiredError('opintojaksoId','Required parameter opintojaksoId was null or undefined when calling getTuotuOpintojakso.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/tuodut/{opintojaksoId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"opintojaksoId"}}`, encodeURIComponent(String(opintojaksoId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(opsId: number, opintojaksoId: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getVersion.');
            }
            // verify required parameter 'opintojaksoId' is not null or undefined
            if (opintojaksoId === null || opintojaksoId === undefined) {
                throw new RequiredError('opintojaksoId','Required parameter opintojaksoId was null or undefined when calling getVersion.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling getVersion.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/{opintojaksoId}/versiot/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"opintojaksoId"}}`, encodeURIComponent(String(opintojaksoId)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionHistory(opsId: number, opintojaksoId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getVersionHistory.');
            }
            // verify required parameter 'opintojaksoId' is not null or undefined
            if (opintojaksoId === null || opintojaksoId === undefined) {
                throw new RequiredError('opintojaksoId','Required parameter opintojaksoId was null or undefined when calling getVersionHistory.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/{opintojaksoId}/versiot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"opintojaksoId"}}`, encodeURIComponent(String(opintojaksoId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOpintojakso(opsId: number, opintojaksoId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling removeOpintojakso.');
            }
            // verify required parameter 'opintojaksoId' is not null or undefined
            if (opintojaksoId === null || opintojaksoId === undefined) {
                throw new RequiredError('opintojaksoId','Required parameter opintojaksoId was null or undefined when calling removeOpintojakso.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/{opintojaksoId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"opintojaksoId"}}`, encodeURIComponent(String(opintojaksoId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertToVersion(opsId: number, opintojaksoId: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling revertToVersion.');
            }
            // verify required parameter 'opintojaksoId' is not null or undefined
            if (opintojaksoId === null || opintojaksoId === undefined) {
                throw new RequiredError('opintojaksoId','Required parameter opintojaksoId was null or undefined when calling revertToVersion.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling revertToVersion.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/{opintojaksoId}/versiot/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"opintojaksoId"}}`, encodeURIComponent(String(opintojaksoId)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {UpdateWrapperDtoLops2019OpintojaksoDto} UpdateWrapperDtoLops2019OpintojaksoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpintojakso(opsId: number, opintojaksoId: number, UpdateWrapperDtoLops2019OpintojaksoDto: UpdateWrapperDtoLops2019OpintojaksoDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOpintojakso.');
            }
            // verify required parameter 'opintojaksoId' is not null or undefined
            if (opintojaksoId === null || opintojaksoId === undefined) {
                throw new RequiredError('opintojaksoId','Required parameter opintojaksoId was null or undefined when calling updateOpintojakso.');
            }
            // verify required parameter 'UpdateWrapperDtoLops2019OpintojaksoDto' is not null or undefined
            if (UpdateWrapperDtoLops2019OpintojaksoDto === null || UpdateWrapperDtoLops2019OpintojaksoDto === undefined) {
                throw new RequiredError('UpdateWrapperDtoLops2019OpintojaksoDto','Required parameter UpdateWrapperDtoLops2019OpintojaksoDto was null or undefined when calling updateOpintojakso.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/opintojaksot/{opintojaksoId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"opintojaksoId"}}`, encodeURIComponent(String(opintojaksoId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof UpdateWrapperDtoLops2019OpintojaksoDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(UpdateWrapperDtoLops2019OpintojaksoDto !== undefined ? UpdateWrapperDtoLops2019OpintojaksoDto : {}) : (UpdateWrapperDtoLops2019OpintojaksoDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Lops2019OpintojaksotApi - functional programming interface
 * @export
 */
export const Lops2019OpintojaksotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {Lops2019OpintojaksoDto} Lops2019OpintojaksoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpintojakso(opsId: number, Lops2019OpintojaksoDto: Lops2019OpintojaksoDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OpintojaksoDto> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).addOpintojakso(opsId, Lops2019OpintojaksoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [moduuliUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpintojaksot(opsId: number, moduuliUri?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019OpintojaksoDto>> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).getAllOpintojaksot(opsId, moduuliUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpintojakso(opsId: number, opintojaksoId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OpintojaksoDto> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).getOpintojakso(opsId, opintojaksoId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpintojaksonOpetussuunnitelma(opsId: number, opintojaksoId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaDto> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).getOpintojaksonOpetussuunnitelma(opsId, opintojaksoId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpintojaksonPeruste(opsId: number, opintojaksoId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OpintojaksoPerusteDto> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).getOpintojaksonPeruste(opsId, opintojaksoId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTuodutOpintojaksot(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019OpintojaksoDto>> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).getTuodutOpintojaksot(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTuotuOpintojakso(opsId: number, opintojaksoId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OpintojaksoDto> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).getTuotuOpintojakso(opsId, opintojaksoId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(opsId: number, opintojaksoId: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OpintojaksoDto> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).getVersion(opsId, opintojaksoId, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionHistory(opsId: number, opintojaksoId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RevisionDto>> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).getVersionHistory(opsId, opintojaksoId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOpintojakso(opsId: number, opintojaksoId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).removeOpintojakso(opsId, opintojaksoId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertToVersion(opsId: number, opintojaksoId: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OpintojaksoDto> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).revertToVersion(opsId, opintojaksoId, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {UpdateWrapperDtoLops2019OpintojaksoDto} UpdateWrapperDtoLops2019OpintojaksoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpintojakso(opsId: number, opintojaksoId: number, UpdateWrapperDtoLops2019OpintojaksoDto: UpdateWrapperDtoLops2019OpintojaksoDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OpintojaksoDto> {
            const localVarAxiosArgs = Lops2019OpintojaksotApiAxiosParamCreator(configuration).updateOpintojakso(opsId, opintojaksoId, UpdateWrapperDtoLops2019OpintojaksoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * Lops2019OpintojaksotApi - factory interface
 * @export
 */
export const Lops2019OpintojaksotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {Lops2019OpintojaksoDto} Lops2019OpintojaksoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpintojakso(opsId: number, Lops2019OpintojaksoDto: Lops2019OpintojaksoDto, options?: any): AxiosPromise<Lops2019OpintojaksoDto> {
            return Lops2019OpintojaksotApiFp(configuration).addOpintojakso(opsId, Lops2019OpintojaksoDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} [moduuliUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpintojaksot(opsId: number, moduuliUri?: string, options?: any): AxiosPromise<Array<Lops2019OpintojaksoDto>> {
            return Lops2019OpintojaksotApiFp(configuration).getAllOpintojaksot(opsId, moduuliUri, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpintojakso(opsId: number, opintojaksoId: number, options?: any): AxiosPromise<Lops2019OpintojaksoDto> {
            return Lops2019OpintojaksotApiFp(configuration).getOpintojakso(opsId, opintojaksoId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpintojaksonOpetussuunnitelma(opsId: number, opintojaksoId: number, options?: any): AxiosPromise<OpetussuunnitelmaDto> {
            return Lops2019OpintojaksotApiFp(configuration).getOpintojaksonOpetussuunnitelma(opsId, opintojaksoId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpintojaksonPeruste(opsId: number, opintojaksoId: number, options?: any): AxiosPromise<Lops2019OpintojaksoPerusteDto> {
            return Lops2019OpintojaksotApiFp(configuration).getOpintojaksonPeruste(opsId, opintojaksoId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTuodutOpintojaksot(opsId: number, options?: any): AxiosPromise<Array<Lops2019OpintojaksoDto>> {
            return Lops2019OpintojaksotApiFp(configuration).getTuodutOpintojaksot(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTuotuOpintojakso(opsId: number, opintojaksoId: number, options?: any): AxiosPromise<Lops2019OpintojaksoDto> {
            return Lops2019OpintojaksotApiFp(configuration).getTuotuOpintojakso(opsId, opintojaksoId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(opsId: number, opintojaksoId: number, versio: number, options?: any): AxiosPromise<Lops2019OpintojaksoDto> {
            return Lops2019OpintojaksotApiFp(configuration).getVersion(opsId, opintojaksoId, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionHistory(opsId: number, opintojaksoId: number, options?: any): AxiosPromise<Array<RevisionDto>> {
            return Lops2019OpintojaksotApiFp(configuration).getVersionHistory(opsId, opintojaksoId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOpintojakso(opsId: number, opintojaksoId: number, options?: any): AxiosPromise<void> {
            return Lops2019OpintojaksotApiFp(configuration).removeOpintojakso(opsId, opintojaksoId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertToVersion(opsId: number, opintojaksoId: number, versio: number, options?: any): AxiosPromise<Lops2019OpintojaksoDto> {
            return Lops2019OpintojaksotApiFp(configuration).revertToVersion(opsId, opintojaksoId, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} opintojaksoId 
         * @param {UpdateWrapperDtoLops2019OpintojaksoDto} UpdateWrapperDtoLops2019OpintojaksoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpintojakso(opsId: number, opintojaksoId: number, UpdateWrapperDtoLops2019OpintojaksoDto: UpdateWrapperDtoLops2019OpintojaksoDto, options?: any): AxiosPromise<Lops2019OpintojaksoDto> {
            return Lops2019OpintojaksotApiFp(configuration).updateOpintojakso(opsId, opintojaksoId, UpdateWrapperDtoLops2019OpintojaksoDto, options)(axios, basePath);
        },
    };
};

/**
 * Lops2019OpintojaksotApi - object-oriented interface
 * @export
 * @class Lops2019OpintojaksotApi
 * @extends {BaseAPI}
 */
export class Lops2019OpintojaksotApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {Lops2019OpintojaksoDto} Lops2019OpintojaksoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public addOpintojakso(opsId: number, Lops2019OpintojaksoDto: Lops2019OpintojaksoDto, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).addOpintojakso(opsId, Lops2019OpintojaksoDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} [moduuliUri] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public getAllOpintojaksot(opsId: number, moduuliUri?: string, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).getAllOpintojaksot(opsId, moduuliUri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} opintojaksoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public getOpintojakso(opsId: number, opintojaksoId: number, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).getOpintojakso(opsId, opintojaksoId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} opintojaksoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public getOpintojaksonOpetussuunnitelma(opsId: number, opintojaksoId: number, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).getOpintojaksonOpetussuunnitelma(opsId, opintojaksoId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} opintojaksoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public getOpintojaksonPeruste(opsId: number, opintojaksoId: number, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).getOpintojaksonPeruste(opsId, opintojaksoId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public getTuodutOpintojaksot(opsId: number, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).getTuodutOpintojaksot(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} opintojaksoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public getTuotuOpintojakso(opsId: number, opintojaksoId: number, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).getTuotuOpintojakso(opsId, opintojaksoId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} opintojaksoId 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public getVersion(opsId: number, opintojaksoId: number, versio: number, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).getVersion(opsId, opintojaksoId, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} opintojaksoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public getVersionHistory(opsId: number, opintojaksoId: number, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).getVersionHistory(opsId, opintojaksoId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} opintojaksoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public removeOpintojakso(opsId: number, opintojaksoId: number, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).removeOpintojakso(opsId, opintojaksoId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} opintojaksoId 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public revertToVersion(opsId: number, opintojaksoId: number, versio: number, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).revertToVersion(opsId, opintojaksoId, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} opintojaksoId 
     * @param {UpdateWrapperDtoLops2019OpintojaksoDto} UpdateWrapperDtoLops2019OpintojaksoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OpintojaksotApi
     */
    public updateOpintojakso(opsId: number, opintojaksoId: number, UpdateWrapperDtoLops2019OpintojaksoDto: UpdateWrapperDtoLops2019OpintojaksoDto, options?: any) {
        return Lops2019OpintojaksotApiFp(this.configuration).updateOpintojakso(opsId, opintojaksoId, UpdateWrapperDtoLops2019OpintojaksoDto, options)(this.axios, this.basePath);
    }

}


/**
 * Lops2019OppiaineetApi - axios parameter creator
 * @export
 */
export const Lops2019OppiaineetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {Lops2019PaikallinenOppiaineDto} Lops2019PaikallinenOppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLops2019PaikallinenOppiaine(opsId: number, Lops2019PaikallinenOppiaineDto: Lops2019PaikallinenOppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addLops2019PaikallinenOppiaine.');
            }
            // verify required parameter 'Lops2019PaikallinenOppiaineDto' is not null or undefined
            if (Lops2019PaikallinenOppiaineDto === null || Lops2019PaikallinenOppiaineDto === undefined) {
                throw new RequiredError('Lops2019PaikallinenOppiaineDto','Required parameter Lops2019PaikallinenOppiaineDto was null or undefined when calling addLops2019PaikallinenOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof Lops2019PaikallinenOppiaineDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(Lops2019PaikallinenOppiaineDto !== undefined ? Lops2019PaikallinenOppiaineDto : {}) : (Lops2019PaikallinenOppiaineDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PaikallisetOppiainet(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PaikallisetOppiainet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019OppiaineJarjestys(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLops2019OppiaineJarjestys.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet/jarjestys`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLops2019PaikallinenOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getLops2019PaikallinenOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet/{oppiaineId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenOppiaineTuotu(opsId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLops2019PaikallinenOppiaineTuotu.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getLops2019PaikallinenOppiaineTuotu.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet/{oppiaineId}/tuotu`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenRemoved(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLops2019PaikallinenRemoved.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet/poistetut`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenVersion(opsId: number, oppiaineId: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLops2019PaikallinenVersion.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getLops2019PaikallinenVersion.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling getLops2019PaikallinenVersion.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet/{oppiaineId}/versiot/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenVersionHistory(opsId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLops2019PaikallinenVersionHistory.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getLops2019PaikallinenVersionHistory.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet/{oppiaineId}/versiot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling removeLops2019PaikallinenOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling removeLops2019PaikallinenOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet/{oppiaineId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertLops2019PaikallinenToVersion(opsId: number, oppiaineId: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling revertLops2019PaikallinenToVersion.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling revertLops2019PaikallinenToVersion.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling revertLops2019PaikallinenToVersion.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet/{oppiaineId}/versiot/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {UpdateWrapperDtoLops2019PaikallinenOppiaineDto} UpdateWrapperDtoLops2019PaikallinenOppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, UpdateWrapperDtoLops2019PaikallinenOppiaineDto: UpdateWrapperDtoLops2019PaikallinenOppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateLops2019PaikallinenOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateLops2019PaikallinenOppiaine.');
            }
            // verify required parameter 'UpdateWrapperDtoLops2019PaikallinenOppiaineDto' is not null or undefined
            if (UpdateWrapperDtoLops2019PaikallinenOppiaineDto === null || UpdateWrapperDtoLops2019PaikallinenOppiaineDto === undefined) {
                throw new RequiredError('UpdateWrapperDtoLops2019PaikallinenOppiaineDto','Required parameter UpdateWrapperDtoLops2019PaikallinenOppiaineDto was null or undefined when calling updateLops2019PaikallinenOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/lops2019/oppiaineet/{oppiaineId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof UpdateWrapperDtoLops2019PaikallinenOppiaineDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(UpdateWrapperDtoLops2019PaikallinenOppiaineDto !== undefined ? UpdateWrapperDtoLops2019PaikallinenOppiaineDto : {}) : (UpdateWrapperDtoLops2019PaikallinenOppiaineDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Lops2019OppiaineetApi - functional programming interface
 * @export
 */
export const Lops2019OppiaineetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {Lops2019PaikallinenOppiaineDto} Lops2019PaikallinenOppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLops2019PaikallinenOppiaine(opsId: number, Lops2019PaikallinenOppiaineDto: Lops2019PaikallinenOppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).addLops2019PaikallinenOppiaine(opsId, Lops2019PaikallinenOppiaineDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PaikallisetOppiainet(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019PaikallinenOppiaineDto>> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).getAllLops2019PaikallisetOppiainet(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019OppiaineJarjestys(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019OppiaineJarjestysDto>> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).getLops2019OppiaineJarjestys(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).getLops2019PaikallinenOppiaine(opsId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenOppiaineTuotu(opsId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).getLops2019PaikallinenOppiaineTuotu(opsId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenRemoved(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PoistettuDto>> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).getLops2019PaikallinenRemoved(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenVersion(opsId: number, oppiaineId: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).getLops2019PaikallinenVersion(opsId, oppiaineId, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenVersionHistory(opsId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RevisionDto>> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).getLops2019PaikallinenVersionHistory(opsId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).removeLops2019PaikallinenOppiaine(opsId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertLops2019PaikallinenToVersion(opsId: number, oppiaineId: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).revertLops2019PaikallinenToVersion(opsId, oppiaineId, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {UpdateWrapperDtoLops2019PaikallinenOppiaineDto} UpdateWrapperDtoLops2019PaikallinenOppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, UpdateWrapperDtoLops2019PaikallinenOppiaineDto: UpdateWrapperDtoLops2019PaikallinenOppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            const localVarAxiosArgs = Lops2019OppiaineetApiAxiosParamCreator(configuration).updateLops2019PaikallinenOppiaine(opsId, oppiaineId, UpdateWrapperDtoLops2019PaikallinenOppiaineDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * Lops2019OppiaineetApi - factory interface
 * @export
 */
export const Lops2019OppiaineetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {Lops2019PaikallinenOppiaineDto} Lops2019PaikallinenOppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLops2019PaikallinenOppiaine(opsId: number, Lops2019PaikallinenOppiaineDto: Lops2019PaikallinenOppiaineDto, options?: any): AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            return Lops2019OppiaineetApiFp(configuration).addLops2019PaikallinenOppiaine(opsId, Lops2019PaikallinenOppiaineDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PaikallisetOppiainet(opsId: number, options?: any): AxiosPromise<Array<Lops2019PaikallinenOppiaineDto>> {
            return Lops2019OppiaineetApiFp(configuration).getAllLops2019PaikallisetOppiainet(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019OppiaineJarjestys(opsId: number, options?: any): AxiosPromise<Array<Lops2019OppiaineJarjestysDto>> {
            return Lops2019OppiaineetApiFp(configuration).getLops2019OppiaineJarjestys(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, options?: any): AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            return Lops2019OppiaineetApiFp(configuration).getLops2019PaikallinenOppiaine(opsId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenOppiaineTuotu(opsId: number, oppiaineId: number, options?: any): AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            return Lops2019OppiaineetApiFp(configuration).getLops2019PaikallinenOppiaineTuotu(opsId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenRemoved(opsId: number, options?: any): AxiosPromise<Array<PoistettuDto>> {
            return Lops2019OppiaineetApiFp(configuration).getLops2019PaikallinenRemoved(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenVersion(opsId: number, oppiaineId: number, versio: number, options?: any): AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            return Lops2019OppiaineetApiFp(configuration).getLops2019PaikallinenVersion(opsId, oppiaineId, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLops2019PaikallinenVersionHistory(opsId: number, oppiaineId: number, options?: any): AxiosPromise<Array<RevisionDto>> {
            return Lops2019OppiaineetApiFp(configuration).getLops2019PaikallinenVersionHistory(opsId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, options?: any): AxiosPromise<void> {
            return Lops2019OppiaineetApiFp(configuration).removeLops2019PaikallinenOppiaine(opsId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertLops2019PaikallinenToVersion(opsId: number, oppiaineId: number, versio: number, options?: any): AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            return Lops2019OppiaineetApiFp(configuration).revertLops2019PaikallinenToVersion(opsId, oppiaineId, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {UpdateWrapperDtoLops2019PaikallinenOppiaineDto} UpdateWrapperDtoLops2019PaikallinenOppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, UpdateWrapperDtoLops2019PaikallinenOppiaineDto: UpdateWrapperDtoLops2019PaikallinenOppiaineDto, options?: any): AxiosPromise<Lops2019PaikallinenOppiaineDto> {
            return Lops2019OppiaineetApiFp(configuration).updateLops2019PaikallinenOppiaine(opsId, oppiaineId, UpdateWrapperDtoLops2019PaikallinenOppiaineDto, options)(axios, basePath);
        },
    };
};

/**
 * Lops2019OppiaineetApi - object-oriented interface
 * @export
 * @class Lops2019OppiaineetApi
 * @extends {BaseAPI}
 */
export class Lops2019OppiaineetApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {Lops2019PaikallinenOppiaineDto} Lops2019PaikallinenOppiaineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public addLops2019PaikallinenOppiaine(opsId: number, Lops2019PaikallinenOppiaineDto: Lops2019PaikallinenOppiaineDto, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).addLops2019PaikallinenOppiaine(opsId, Lops2019PaikallinenOppiaineDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public getAllLops2019PaikallisetOppiainet(opsId: number, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).getAllLops2019PaikallisetOppiainet(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public getLops2019OppiaineJarjestys(opsId: number, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).getLops2019OppiaineJarjestys(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public getLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).getLops2019PaikallinenOppiaine(opsId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public getLops2019PaikallinenOppiaineTuotu(opsId: number, oppiaineId: number, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).getLops2019PaikallinenOppiaineTuotu(opsId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public getLops2019PaikallinenRemoved(opsId: number, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).getLops2019PaikallinenRemoved(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public getLops2019PaikallinenVersion(opsId: number, oppiaineId: number, versio: number, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).getLops2019PaikallinenVersion(opsId, oppiaineId, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public getLops2019PaikallinenVersionHistory(opsId: number, oppiaineId: number, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).getLops2019PaikallinenVersionHistory(opsId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public removeLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).removeLops2019PaikallinenOppiaine(opsId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public revertLops2019PaikallinenToVersion(opsId: number, oppiaineId: number, versio: number, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).revertLops2019PaikallinenToVersion(opsId, oppiaineId, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {UpdateWrapperDtoLops2019PaikallinenOppiaineDto} UpdateWrapperDtoLops2019PaikallinenOppiaineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019OppiaineetApi
     */
    public updateLops2019PaikallinenOppiaine(opsId: number, oppiaineId: number, UpdateWrapperDtoLops2019PaikallinenOppiaineDto: UpdateWrapperDtoLops2019PaikallinenOppiaineDto, options?: any) {
        return Lops2019OppiaineetApiFp(this.configuration).updateLops2019PaikallinenOppiaine(opsId, oppiaineId, UpdateWrapperDtoLops2019PaikallinenOppiaineDto, options)(this.axios, this.basePath);
    }

}


/**
 * Lops2019PerusteControllerApi - axios parameter creator
 * @export
 */
export const Lops2019PerusteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteModuuli(opsId: number, oppiaineId: number, moduuliId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PerusteModuuli.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAllLops2019PerusteModuuli.');
            }
            // verify required parameter 'moduuliId' is not null or undefined
            if (moduuliId === null || moduuliId === undefined) {
                throw new RequiredError('moduuliId','Required parameter moduuliId was null or undefined when calling getAllLops2019PerusteModuuli.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/peruste/oppiaineet/{oppiaineId}/moduulit/{moduuliId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"moduuliId"}}`, encodeURIComponent(String(moduuliId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {Array<string>} moduuliKoodiUris 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteModuulit(opsId: number, moduuliKoodiUris: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PerusteModuulit.');
            }
            // verify required parameter 'moduuliKoodiUris' is not null or undefined
            if (moduuliKoodiUris === null || moduuliKoodiUris === undefined) {
                throw new RequiredError('moduuliKoodiUris','Required parameter moduuliKoodiUris was null or undefined when calling getAllLops2019PerusteModuulit.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/peruste/moduulit`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (moduuliKoodiUris) {
                localVarQueryParameter['moduuliKoodiUris'] = moduuliKoodiUris;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} oppiaineUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaine(opsId: number, oppiaineUri: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PerusteOppiaine.');
            }
            // verify required parameter 'oppiaineUri' is not null or undefined
            if (oppiaineUri === null || oppiaineUri === undefined) {
                throw new RequiredError('oppiaineUri','Required parameter oppiaineUri was null or undefined when calling getAllLops2019PerusteOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/peruste/oppiaineet/uri/{oppiaineUri}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineUri"}}`, encodeURIComponent(String(oppiaineUri)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaineById(opsId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PerusteOppiaineById.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAllLops2019PerusteOppiaineById.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/peruste/oppiaineet/{oppiaineId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} oppiaineUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaineenModuulit(opsId: number, oppiaineUri: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PerusteOppiaineenModuulit.');
            }
            // verify required parameter 'oppiaineUri' is not null or undefined
            if (oppiaineUri === null || oppiaineUri === undefined) {
                throw new RequiredError('oppiaineUri','Required parameter oppiaineUri was null or undefined when calling getAllLops2019PerusteOppiaineenModuulit.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/peruste/oppiaineet/{oppiaineUri}/moduulit`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineUri"}}`, encodeURIComponent(String(oppiaineUri)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaineet(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PerusteOppiaineet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/peruste/oppiaineet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteSisalto(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PerusteSisalto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/peruste/sisalto`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} tekstikappaleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteTekstikappale(opsId: number, tekstikappaleId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PerusteTekstikappale.');
            }
            // verify required parameter 'tekstikappaleId' is not null or undefined
            if (tekstikappaleId === null || tekstikappaleId === undefined) {
                throw new RequiredError('tekstikappaleId','Required parameter tekstikappaleId was null or undefined when calling getAllLops2019PerusteTekstikappale.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/peruste/tekstikappaleet/{tekstikappaleId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"tekstikappaleId"}}`, encodeURIComponent(String(tekstikappaleId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteTekstikappaleet(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllLops2019PerusteTekstikappaleet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/peruste/tekstikappaleet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Lops2019PerusteControllerApi - functional programming interface
 * @export
 */
export const Lops2019PerusteControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteModuuli(opsId: number, oppiaineId: number, moduuliId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019ModuuliDto> {
            const localVarAxiosArgs = Lops2019PerusteControllerApiAxiosParamCreator(configuration).getAllLops2019PerusteModuuli(opsId, oppiaineId, moduuliId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {Array<string>} moduuliKoodiUris 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteModuulit(opsId: number, moduuliKoodiUris: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019ModuuliDto>> {
            const localVarAxiosArgs = Lops2019PerusteControllerApiAxiosParamCreator(configuration).getAllLops2019PerusteModuulit(opsId, moduuliKoodiUris, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} oppiaineUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaine(opsId: number, oppiaineUri: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OppiaineKaikkiDto> {
            const localVarAxiosArgs = Lops2019PerusteControllerApiAxiosParamCreator(configuration).getAllLops2019PerusteOppiaine(opsId, oppiaineUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaineById(opsId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OppiaineKaikkiDto> {
            const localVarAxiosArgs = Lops2019PerusteControllerApiAxiosParamCreator(configuration).getAllLops2019PerusteOppiaineById(opsId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} oppiaineUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaineenModuulit(opsId: number, oppiaineUri: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019ModuuliDto>> {
            const localVarAxiosArgs = Lops2019PerusteControllerApiAxiosParamCreator(configuration).getAllLops2019PerusteOppiaineenModuulit(opsId, oppiaineUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaineet(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019OppiaineKaikkiDto>> {
            const localVarAxiosArgs = Lops2019PerusteControllerApiAxiosParamCreator(configuration).getAllLops2019PerusteOppiaineet(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteSisalto(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019SisaltoDto> {
            const localVarAxiosArgs = Lops2019PerusteControllerApiAxiosParamCreator(configuration).getAllLops2019PerusteSisalto(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} tekstikappaleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteTekstikappale(opsId: number, tekstikappaleId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TekstiKappaleViiteDto> {
            const localVarAxiosArgs = Lops2019PerusteControllerApiAxiosParamCreator(configuration).getAllLops2019PerusteTekstikappale(opsId, tekstikappaleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteTekstikappaleet(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TekstiKappaleViiteDto> {
            const localVarAxiosArgs = Lops2019PerusteControllerApiAxiosParamCreator(configuration).getAllLops2019PerusteTekstikappaleet(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * Lops2019PerusteControllerApi - factory interface
 * @export
 */
export const Lops2019PerusteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteModuuli(opsId: number, oppiaineId: number, moduuliId: number, options?: any): AxiosPromise<Lops2019ModuuliDto> {
            return Lops2019PerusteControllerApiFp(configuration).getAllLops2019PerusteModuuli(opsId, oppiaineId, moduuliId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {Array<string>} moduuliKoodiUris 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteModuulit(opsId: number, moduuliKoodiUris: Array<string>, options?: any): AxiosPromise<Array<Lops2019ModuuliDto>> {
            return Lops2019PerusteControllerApiFp(configuration).getAllLops2019PerusteModuulit(opsId, moduuliKoodiUris, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} oppiaineUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaine(opsId: number, oppiaineUri: string, options?: any): AxiosPromise<Lops2019OppiaineKaikkiDto> {
            return Lops2019PerusteControllerApiFp(configuration).getAllLops2019PerusteOppiaine(opsId, oppiaineUri, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaineById(opsId: number, oppiaineId: number, options?: any): AxiosPromise<Lops2019OppiaineKaikkiDto> {
            return Lops2019PerusteControllerApiFp(configuration).getAllLops2019PerusteOppiaineById(opsId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} oppiaineUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaineenModuulit(opsId: number, oppiaineUri: string, options?: any): AxiosPromise<Array<Lops2019ModuuliDto>> {
            return Lops2019PerusteControllerApiFp(configuration).getAllLops2019PerusteOppiaineenModuulit(opsId, oppiaineUri, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteOppiaineet(opsId: number, options?: any): AxiosPromise<Array<Lops2019OppiaineKaikkiDto>> {
            return Lops2019PerusteControllerApiFp(configuration).getAllLops2019PerusteOppiaineet(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteSisalto(opsId: number, options?: any): AxiosPromise<Lops2019SisaltoDto> {
            return Lops2019PerusteControllerApiFp(configuration).getAllLops2019PerusteSisalto(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} tekstikappaleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteTekstikappale(opsId: number, tekstikappaleId: number, options?: any): AxiosPromise<TekstiKappaleViiteDto> {
            return Lops2019PerusteControllerApiFp(configuration).getAllLops2019PerusteTekstikappale(opsId, tekstikappaleId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLops2019PerusteTekstikappaleet(opsId: number, options?: any): AxiosPromise<TekstiKappaleViiteDto> {
            return Lops2019PerusteControllerApiFp(configuration).getAllLops2019PerusteTekstikappaleet(opsId, options)(axios, basePath);
        },
    };
};

/**
 * Lops2019PerusteControllerApi - object-oriented interface
 * @export
 * @class Lops2019PerusteControllerApi
 * @extends {BaseAPI}
 */
export class Lops2019PerusteControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} moduuliId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019PerusteControllerApi
     */
    public getAllLops2019PerusteModuuli(opsId: number, oppiaineId: number, moduuliId: number, options?: any) {
        return Lops2019PerusteControllerApiFp(this.configuration).getAllLops2019PerusteModuuli(opsId, oppiaineId, moduuliId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {Array<string>} moduuliKoodiUris 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019PerusteControllerApi
     */
    public getAllLops2019PerusteModuulit(opsId: number, moduuliKoodiUris: Array<string>, options?: any) {
        return Lops2019PerusteControllerApiFp(this.configuration).getAllLops2019PerusteModuulit(opsId, moduuliKoodiUris, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} oppiaineUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019PerusteControllerApi
     */
    public getAllLops2019PerusteOppiaine(opsId: number, oppiaineUri: string, options?: any) {
        return Lops2019PerusteControllerApiFp(this.configuration).getAllLops2019PerusteOppiaine(opsId, oppiaineUri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019PerusteControllerApi
     */
    public getAllLops2019PerusteOppiaineById(opsId: number, oppiaineId: number, options?: any) {
        return Lops2019PerusteControllerApiFp(this.configuration).getAllLops2019PerusteOppiaineById(opsId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} oppiaineUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019PerusteControllerApi
     */
    public getAllLops2019PerusteOppiaineenModuulit(opsId: number, oppiaineUri: string, options?: any) {
        return Lops2019PerusteControllerApiFp(this.configuration).getAllLops2019PerusteOppiaineenModuulit(opsId, oppiaineUri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019PerusteControllerApi
     */
    public getAllLops2019PerusteOppiaineet(opsId: number, options?: any) {
        return Lops2019PerusteControllerApiFp(this.configuration).getAllLops2019PerusteOppiaineet(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019PerusteControllerApi
     */
    public getAllLops2019PerusteSisalto(opsId: number, options?: any) {
        return Lops2019PerusteControllerApiFp(this.configuration).getAllLops2019PerusteSisalto(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} tekstikappaleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019PerusteControllerApi
     */
    public getAllLops2019PerusteTekstikappale(opsId: number, tekstikappaleId: number, options?: any) {
        return Lops2019PerusteControllerApiFp(this.configuration).getAllLops2019PerusteTekstikappale(opsId, tekstikappaleId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019PerusteControllerApi
     */
    public getAllLops2019PerusteTekstikappaleet(opsId: number, options?: any) {
        return Lops2019PerusteControllerApiFp(this.configuration).getAllLops2019PerusteTekstikappaleet(opsId, options)(this.axios, this.basePath);
    }

}


/**
 * LukioOpetussuunnitelmatApi - axios parameter creator
 * @export
 */
export const LukioOpetussuunnitelmatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {LukioAbstraktiOppiaineTuontiDto} LukioAbstraktiOppiaineTuontiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAbstraktiOppiaine(opsId: number, LukioAbstraktiOppiaineTuontiDto: LukioAbstraktiOppiaineTuontiDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addAbstraktiOppiaine.');
            }
            // verify required parameter 'LukioAbstraktiOppiaineTuontiDto' is not null or undefined
            if (LukioAbstraktiOppiaineTuontiDto === null || LukioAbstraktiOppiaineTuontiDto === undefined) {
                throw new RequiredError('LukioAbstraktiOppiaineTuontiDto','Required parameter LukioAbstraktiOppiaineTuontiDto was null or undefined when calling addAbstraktiOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/oppiaine/abstrakti`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof LukioAbstraktiOppiaineTuontiDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(LukioAbstraktiOppiaineTuontiDto !== undefined ? LukioAbstraktiOppiaineTuontiDto : {}) : (LukioAbstraktiOppiaineTuontiDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {LukioKopioiOppimaaraDto} LukioKopioiOppimaaraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLukioOppimaara(opsId: number, oppiaineId: number, LukioKopioiOppimaaraDto: LukioKopioiOppimaaraDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addLukioOppimaara.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling addLukioOppimaara.');
            }
            // verify required parameter 'LukioKopioiOppimaaraDto' is not null or undefined
            if (LukioKopioiOppimaaraDto === null || LukioKopioiOppimaaraDto === undefined) {
                throw new RequiredError('LukioKopioiOppimaaraDto','Required parameter LukioKopioiOppimaaraDto was null or undefined when calling addLukioOppimaara.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/oppiaine/{oppiaineId}/kielitarjonta`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof LukioKopioiOppimaaraDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(LukioKopioiOppimaaraDto !== undefined ? LukioKopioiOppimaaraDto : {}) : (LukioKopioiOppimaaraDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} aihekokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAihekokonaisuus(opsId: number, aihekokonaisuusId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling deleteAihekokonaisuus.');
            }
            // verify required parameter 'aihekokonaisuusId' is not null or undefined
            if (aihekokonaisuusId === null || aihekokonaisuusId === undefined) {
                throw new RequiredError('aihekokonaisuusId','Required parameter aihekokonaisuusId was null or undefined when calling deleteAihekokonaisuus.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/aihekokonaisuudet/kokonaisuus/{aihekokonaisuusId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"aihekokonaisuusId"}}`, encodeURIComponent(String(aihekokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectKurssi(opsId: number, oppiaineId: number, kurssiId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling disconnectKurssi.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling disconnectKurssi.');
            }
            // verify required parameter 'kurssiId' is not null or undefined
            if (kurssiId === null || kurssiId === undefined) {
                throw new RequiredError('kurssiId','Required parameter kurssiId was null or undefined when calling disconnectKurssi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/oppiaine/{oppiaineId}/kurssi/{kurssiId}/disconnect`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kurssiId"}}`, encodeURIComponent(String(kurssiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAihekokonaisuudet(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAihekokonaisuudet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/aihekokonaisuudet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukioOppiaine(opsId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getLukioOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getLukioOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/oppiaine/{oppiaineId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetuksenYleisetTavoitteet(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetuksenYleisetTavoitteet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/opetuksenYleisetTavoitteet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenne(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getRakenne.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/rakenne`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {AihekokonaisuudetJarjestaDto} AihekokonaisuudetJarjestaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reArrangeAihekokonaisuudet(opsId: number, AihekokonaisuudetJarjestaDto: AihekokonaisuudetJarjestaDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling reArrangeAihekokonaisuudet.');
            }
            // verify required parameter 'AihekokonaisuudetJarjestaDto' is not null or undefined
            if (AihekokonaisuudetJarjestaDto === null || AihekokonaisuudetJarjestaDto === undefined) {
                throw new RequiredError('AihekokonaisuudetJarjestaDto','Required parameter AihekokonaisuudetJarjestaDto was null or undefined when calling reArrangeAihekokonaisuudet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/aihekokonaisuudet/jarjesta`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof AihekokonaisuudetJarjestaDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(AihekokonaisuudetJarjestaDto !== undefined ? AihekokonaisuudetJarjestaDto : {}) : (AihekokonaisuudetJarjestaDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconnectKurssi(opsId: number, oppiaineId: number, kurssiId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling reconnectKurssi.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling reconnectKurssi.');
            }
            // verify required parameter 'kurssiId' is not null or undefined
            if (kurssiId === null || kurssiId === undefined) {
                throw new RequiredError('kurssiId','Required parameter kurssiId was null or undefined when calling reconnectKurssi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/oppiaine/{oppiaineId}/kurssi/{kurssiId}/reconnect`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kurssiId"}}`, encodeURIComponent(String(kurssiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeKurssi(opsId: number, kurssiId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling removeKurssi.');
            }
            // verify required parameter 'kurssiId' is not null or undefined
            if (kurssiId === null || kurssiId === undefined) {
                throw new RequiredError('kurssiId','Required parameter kurssiId was null or undefined when calling removeKurssi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/kurssi/{kurssiId}/remove`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"kurssiId"}}`, encodeURIComponent(String(kurssiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAihekokonaisuus(opsId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling saveAihekokonaisuus.');
            }
            // verify required parameter 'AihekokonaisuusSaveDto' is not null or undefined
            if (AihekokonaisuusSaveDto === null || AihekokonaisuusSaveDto === undefined) {
                throw new RequiredError('AihekokonaisuusSaveDto','Required parameter AihekokonaisuusSaveDto was null or undefined when calling saveAihekokonaisuus.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/aihekokonaisuudet/kokonaisuus`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof AihekokonaisuusSaveDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(AihekokonaisuusSaveDto !== undefined ? AihekokonaisuusSaveDto : {}) : (AihekokonaisuusSaveDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {LukiokurssiSaveDto} LukiokurssiSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveKurssi(opsId: number, LukiokurssiSaveDto: LukiokurssiSaveDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling saveKurssi.');
            }
            // verify required parameter 'LukiokurssiSaveDto' is not null or undefined
            if (LukiokurssiSaveDto === null || LukiokurssiSaveDto === undefined) {
                throw new RequiredError('LukiokurssiSaveDto','Required parameter LukiokurssiSaveDto was null or undefined when calling saveKurssi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/kurssi`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof LukiokurssiSaveDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(LukiokurssiSaveDto !== undefined ? LukiokurssiSaveDto : {}) : (LukiokurssiSaveDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {LukioOppiaineSaveDto} LukioOppiaineSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLukioOppiaine(opsId: number, LukioOppiaineSaveDto: LukioOppiaineSaveDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling saveLukioOppiaine.');
            }
            // verify required parameter 'LukioOppiaineSaveDto' is not null or undefined
            if (LukioOppiaineSaveDto === null || LukioOppiaineSaveDto === undefined) {
                throw new RequiredError('LukioOppiaineSaveDto','Required parameter LukioOppiaineSaveDto was null or undefined when calling saveLukioOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/oppiaine`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof LukioOppiaineSaveDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(LukioOppiaineSaveDto !== undefined ? LukioOppiaineSaveDto : {}) : (LukioOppiaineSaveDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAihekokonaisuudetYleiskuvaus(opsId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateAihekokonaisuudetYleiskuvaus.');
            }
            // verify required parameter 'AihekokonaisuusSaveDto' is not null or undefined
            if (AihekokonaisuusSaveDto === null || AihekokonaisuusSaveDto === undefined) {
                throw new RequiredError('AihekokonaisuusSaveDto','Required parameter AihekokonaisuusSaveDto was null or undefined when calling updateAihekokonaisuudetYleiskuvaus.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/aihekokonaisuudet/yleiskuvaus`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof AihekokonaisuusSaveDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(AihekokonaisuusSaveDto !== undefined ? AihekokonaisuusSaveDto : {}) : (AihekokonaisuusSaveDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} aihekokonaisuusId 
         * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAihekokonaisuus(opsId: number, aihekokonaisuusId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateAihekokonaisuus.');
            }
            // verify required parameter 'aihekokonaisuusId' is not null or undefined
            if (aihekokonaisuusId === null || aihekokonaisuusId === undefined) {
                throw new RequiredError('aihekokonaisuusId','Required parameter aihekokonaisuusId was null or undefined when calling updateAihekokonaisuus.');
            }
            // verify required parameter 'AihekokonaisuusSaveDto' is not null or undefined
            if (AihekokonaisuusSaveDto === null || AihekokonaisuusSaveDto === undefined) {
                throw new RequiredError('AihekokonaisuusSaveDto','Required parameter AihekokonaisuusSaveDto was null or undefined when calling updateAihekokonaisuus.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/aihekokonaisuudet/kokonaisuus/{aihekokonaisuusId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"aihekokonaisuusId"}}`, encodeURIComponent(String(aihekokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof AihekokonaisuusSaveDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(AihekokonaisuusSaveDto !== undefined ? AihekokonaisuusSaveDto : {}) : (AihekokonaisuusSaveDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} kurssiId 
         * @param {LukiokurssiUpdateDto} LukiokurssiUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKurssi(opsId: number, kurssiId: number, LukiokurssiUpdateDto: LukiokurssiUpdateDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateKurssi.');
            }
            // verify required parameter 'kurssiId' is not null or undefined
            if (kurssiId === null || kurssiId === undefined) {
                throw new RequiredError('kurssiId','Required parameter kurssiId was null or undefined when calling updateKurssi.');
            }
            // verify required parameter 'LukiokurssiUpdateDto' is not null or undefined
            if (LukiokurssiUpdateDto === null || LukiokurssiUpdateDto === undefined) {
                throw new RequiredError('LukiokurssiUpdateDto','Required parameter LukiokurssiUpdateDto was null or undefined when calling updateKurssi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/kurssi/{kurssiId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"kurssiId"}}`, encodeURIComponent(String(kurssiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof LukiokurssiUpdateDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(LukiokurssiUpdateDto !== undefined ? LukiokurssiUpdateDto : {}) : (LukiokurssiUpdateDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {LukioOppiaineSaveDto} LukioOppiaineSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLukioOppiaine(opsId: number, LukioOppiaineSaveDto: LukioOppiaineSaveDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateLukioOppiaine.');
            }
            // verify required parameter 'LukioOppiaineSaveDto' is not null or undefined
            if (LukioOppiaineSaveDto === null || LukioOppiaineSaveDto === undefined) {
                throw new RequiredError('LukioOppiaineSaveDto','Required parameter LukioOppiaineSaveDto was null or undefined when calling updateLukioOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/oppiaine`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof LukioOppiaineSaveDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(LukioOppiaineSaveDto !== undefined ? LukioOppiaineSaveDto : {}) : (LukioOppiaineSaveDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OpetuksenYleisetTavoitteetUpdateDto} OpetuksenYleisetTavoitteetUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetuksenYleisetTavoitteet(opsId: number, OpetuksenYleisetTavoitteetUpdateDto: OpetuksenYleisetTavoitteetUpdateDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOpetuksenYleisetTavoitteet.');
            }
            // verify required parameter 'OpetuksenYleisetTavoitteetUpdateDto' is not null or undefined
            if (OpetuksenYleisetTavoitteetUpdateDto === null || OpetuksenYleisetTavoitteetUpdateDto === undefined) {
                throw new RequiredError('OpetuksenYleisetTavoitteetUpdateDto','Required parameter OpetuksenYleisetTavoitteetUpdateDto was null or undefined when calling updateOpetuksenYleisetTavoitteet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/opetuksenYleisetTavoitteet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OpetuksenYleisetTavoitteetUpdateDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OpetuksenYleisetTavoitteetUpdateDto !== undefined ? OpetuksenYleisetTavoitteetUpdateDto : {}) : (OpetuksenYleisetTavoitteetUpdateDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OppaineKurssiTreeStructureDto} OppaineKurssiTreeStructureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStructure(opsId: number, OppaineKurssiTreeStructureDto: OppaineKurssiTreeStructureDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateStructure.');
            }
            // verify required parameter 'OppaineKurssiTreeStructureDto' is not null or undefined
            if (OppaineKurssiTreeStructureDto === null || OppaineKurssiTreeStructureDto === undefined) {
                throw new RequiredError('OppaineKurssiTreeStructureDto','Required parameter OppaineKurssiTreeStructureDto was null or undefined when calling updateStructure.');
            }
            const localVarPath = `/api/opetussuunnitelmat/lukio/{opsId}/rakenne`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppaineKurssiTreeStructureDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppaineKurssiTreeStructureDto !== undefined ? OppaineKurssiTreeStructureDto : {}) : (OppaineKurssiTreeStructureDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LukioOpetussuunnitelmatApi - functional programming interface
 * @export
 */
export const LukioOpetussuunnitelmatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {LukioAbstraktiOppiaineTuontiDto} LukioAbstraktiOppiaineTuontiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAbstraktiOppiaine(opsId: number, LukioAbstraktiOppiaineTuontiDto: LukioAbstraktiOppiaineTuontiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongIdResultDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).addAbstraktiOppiaine(opsId, LukioAbstraktiOppiaineTuontiDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {LukioKopioiOppimaaraDto} LukioKopioiOppimaaraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLukioOppimaara(opsId: number, oppiaineId: number, LukioKopioiOppimaaraDto: LukioKopioiOppimaaraDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongIdResultDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).addLukioOppimaara(opsId, oppiaineId, LukioKopioiOppimaaraDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} aihekokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAihekokonaisuus(opsId: number, aihekokonaisuusId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).deleteAihekokonaisuus(opsId, aihekokonaisuusId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectKurssi(opsId: number, oppiaineId: number, kurssiId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongIdResultDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).disconnectKurssi(opsId, oppiaineId, kurssiId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAihekokonaisuudet(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AihekokonaisuudetPerusteOpsDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).getAihekokonaisuudet(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukioOppiaine(opsId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukioOppiaineTiedotDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).getLukioOppiaine(opsId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetuksenYleisetTavoitteet(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetuksenYleisetTavoitteetPerusteOpsDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetuksenYleisetTavoitteet(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenne(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukioOpetussuunnitelmaRakenneOpsDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).getRakenne(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {AihekokonaisuudetJarjestaDto} AihekokonaisuudetJarjestaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reArrangeAihekokonaisuudet(opsId: number, AihekokonaisuudetJarjestaDto: AihekokonaisuudetJarjestaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).reArrangeAihekokonaisuudet(opsId, AihekokonaisuudetJarjestaDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconnectKurssi(opsId: number, oppiaineId: number, kurssiId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongIdResultDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).reconnectKurssi(opsId, oppiaineId, kurssiId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeKurssi(opsId: number, kurssiId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).removeKurssi(opsId, kurssiId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAihekokonaisuus(opsId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongIdResultDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).saveAihekokonaisuus(opsId, AihekokonaisuusSaveDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {LukiokurssiSaveDto} LukiokurssiSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveKurssi(opsId: number, LukiokurssiSaveDto: LukiokurssiSaveDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongIdResultDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).saveKurssi(opsId, LukiokurssiSaveDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {LukioOppiaineSaveDto} LukioOppiaineSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLukioOppiaine(opsId: number, LukioOppiaineSaveDto: LukioOppiaineSaveDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LongIdResultDto> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).saveLukioOppiaine(opsId, LukioOppiaineSaveDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAihekokonaisuudetYleiskuvaus(opsId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).updateAihekokonaisuudetYleiskuvaus(opsId, AihekokonaisuusSaveDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} aihekokonaisuusId 
         * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAihekokonaisuus(opsId: number, aihekokonaisuusId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).updateAihekokonaisuus(opsId, aihekokonaisuusId, AihekokonaisuusSaveDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} kurssiId 
         * @param {LukiokurssiUpdateDto} LukiokurssiUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKurssi(opsId: number, kurssiId: number, LukiokurssiUpdateDto: LukiokurssiUpdateDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).updateKurssi(opsId, kurssiId, LukiokurssiUpdateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {LukioOppiaineSaveDto} LukioOppiaineSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLukioOppiaine(opsId: number, LukioOppiaineSaveDto: LukioOppiaineSaveDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).updateLukioOppiaine(opsId, LukioOppiaineSaveDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OpetuksenYleisetTavoitteetUpdateDto} OpetuksenYleisetTavoitteetUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetuksenYleisetTavoitteet(opsId: number, OpetuksenYleisetTavoitteetUpdateDto: OpetuksenYleisetTavoitteetUpdateDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).updateOpetuksenYleisetTavoitteet(opsId, OpetuksenYleisetTavoitteetUpdateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OppaineKurssiTreeStructureDto} OppaineKurssiTreeStructureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStructure(opsId: number, OppaineKurssiTreeStructureDto: OppaineKurssiTreeStructureDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LukioOpetussuunnitelmatApiAxiosParamCreator(configuration).updateStructure(opsId, OppaineKurssiTreeStructureDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LukioOpetussuunnitelmatApi - factory interface
 * @export
 */
export const LukioOpetussuunnitelmatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {LukioAbstraktiOppiaineTuontiDto} LukioAbstraktiOppiaineTuontiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAbstraktiOppiaine(opsId: number, LukioAbstraktiOppiaineTuontiDto: LukioAbstraktiOppiaineTuontiDto, options?: any): AxiosPromise<LongIdResultDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).addAbstraktiOppiaine(opsId, LukioAbstraktiOppiaineTuontiDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {LukioKopioiOppimaaraDto} LukioKopioiOppimaaraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLukioOppimaara(opsId: number, oppiaineId: number, LukioKopioiOppimaaraDto: LukioKopioiOppimaaraDto, options?: any): AxiosPromise<LongIdResultDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).addLukioOppimaara(opsId, oppiaineId, LukioKopioiOppimaaraDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} aihekokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAihekokonaisuus(opsId: number, aihekokonaisuusId: number, options?: any): AxiosPromise<void> {
            return LukioOpetussuunnitelmatApiFp(configuration).deleteAihekokonaisuus(opsId, aihekokonaisuusId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectKurssi(opsId: number, oppiaineId: number, kurssiId: number, options?: any): AxiosPromise<LongIdResultDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).disconnectKurssi(opsId, oppiaineId, kurssiId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAihekokonaisuudet(opsId: number, options?: any): AxiosPromise<AihekokonaisuudetPerusteOpsDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).getAihekokonaisuudet(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukioOppiaine(opsId: number, oppiaineId: number, options?: any): AxiosPromise<LukioOppiaineTiedotDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).getLukioOppiaine(opsId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetuksenYleisetTavoitteet(opsId: number, options?: any): AxiosPromise<OpetuksenYleisetTavoitteetPerusteOpsDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).getOpetuksenYleisetTavoitteet(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenne(opsId: number, options?: any): AxiosPromise<LukioOpetussuunnitelmaRakenneOpsDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).getRakenne(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {AihekokonaisuudetJarjestaDto} AihekokonaisuudetJarjestaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reArrangeAihekokonaisuudet(opsId: number, AihekokonaisuudetJarjestaDto: AihekokonaisuudetJarjestaDto, options?: any): AxiosPromise<void> {
            return LukioOpetussuunnitelmatApiFp(configuration).reArrangeAihekokonaisuudet(opsId, AihekokonaisuudetJarjestaDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconnectKurssi(opsId: number, oppiaineId: number, kurssiId: number, options?: any): AxiosPromise<LongIdResultDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).reconnectKurssi(opsId, oppiaineId, kurssiId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeKurssi(opsId: number, kurssiId: number, options?: any): AxiosPromise<void> {
            return LukioOpetussuunnitelmatApiFp(configuration).removeKurssi(opsId, kurssiId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAihekokonaisuus(opsId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options?: any): AxiosPromise<LongIdResultDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).saveAihekokonaisuus(opsId, AihekokonaisuusSaveDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {LukiokurssiSaveDto} LukiokurssiSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveKurssi(opsId: number, LukiokurssiSaveDto: LukiokurssiSaveDto, options?: any): AxiosPromise<LongIdResultDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).saveKurssi(opsId, LukiokurssiSaveDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {LukioOppiaineSaveDto} LukioOppiaineSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLukioOppiaine(opsId: number, LukioOppiaineSaveDto: LukioOppiaineSaveDto, options?: any): AxiosPromise<LongIdResultDto> {
            return LukioOpetussuunnitelmatApiFp(configuration).saveLukioOppiaine(opsId, LukioOppiaineSaveDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAihekokonaisuudetYleiskuvaus(opsId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options?: any): AxiosPromise<void> {
            return LukioOpetussuunnitelmatApiFp(configuration).updateAihekokonaisuudetYleiskuvaus(opsId, AihekokonaisuusSaveDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} aihekokonaisuusId 
         * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAihekokonaisuus(opsId: number, aihekokonaisuusId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options?: any): AxiosPromise<void> {
            return LukioOpetussuunnitelmatApiFp(configuration).updateAihekokonaisuus(opsId, aihekokonaisuusId, AihekokonaisuusSaveDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} kurssiId 
         * @param {LukiokurssiUpdateDto} LukiokurssiUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKurssi(opsId: number, kurssiId: number, LukiokurssiUpdateDto: LukiokurssiUpdateDto, options?: any): AxiosPromise<void> {
            return LukioOpetussuunnitelmatApiFp(configuration).updateKurssi(opsId, kurssiId, LukiokurssiUpdateDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {LukioOppiaineSaveDto} LukioOppiaineSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLukioOppiaine(opsId: number, LukioOppiaineSaveDto: LukioOppiaineSaveDto, options?: any): AxiosPromise<void> {
            return LukioOpetussuunnitelmatApiFp(configuration).updateLukioOppiaine(opsId, LukioOppiaineSaveDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OpetuksenYleisetTavoitteetUpdateDto} OpetuksenYleisetTavoitteetUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetuksenYleisetTavoitteet(opsId: number, OpetuksenYleisetTavoitteetUpdateDto: OpetuksenYleisetTavoitteetUpdateDto, options?: any): AxiosPromise<void> {
            return LukioOpetussuunnitelmatApiFp(configuration).updateOpetuksenYleisetTavoitteet(opsId, OpetuksenYleisetTavoitteetUpdateDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OppaineKurssiTreeStructureDto} OppaineKurssiTreeStructureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStructure(opsId: number, OppaineKurssiTreeStructureDto: OppaineKurssiTreeStructureDto, options?: any): AxiosPromise<void> {
            return LukioOpetussuunnitelmatApiFp(configuration).updateStructure(opsId, OppaineKurssiTreeStructureDto, options)(axios, basePath);
        },
    };
};

/**
 * LukioOpetussuunnitelmatApi - object-oriented interface
 * @export
 * @class LukioOpetussuunnitelmatApi
 * @extends {BaseAPI}
 */
export class LukioOpetussuunnitelmatApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {LukioAbstraktiOppiaineTuontiDto} LukioAbstraktiOppiaineTuontiDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public addAbstraktiOppiaine(opsId: number, LukioAbstraktiOppiaineTuontiDto: LukioAbstraktiOppiaineTuontiDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).addAbstraktiOppiaine(opsId, LukioAbstraktiOppiaineTuontiDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {LukioKopioiOppimaaraDto} LukioKopioiOppimaaraDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public addLukioOppimaara(opsId: number, oppiaineId: number, LukioKopioiOppimaaraDto: LukioKopioiOppimaaraDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).addLukioOppimaara(opsId, oppiaineId, LukioKopioiOppimaaraDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} aihekokonaisuusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public deleteAihekokonaisuus(opsId: number, aihekokonaisuusId: number, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).deleteAihekokonaisuus(opsId, aihekokonaisuusId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} kurssiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public disconnectKurssi(opsId: number, oppiaineId: number, kurssiId: number, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).disconnectKurssi(opsId, oppiaineId, kurssiId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public getAihekokonaisuudet(opsId: number, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).getAihekokonaisuudet(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public getLukioOppiaine(opsId: number, oppiaineId: number, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).getLukioOppiaine(opsId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public getOpetuksenYleisetTavoitteet(opsId: number, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).getOpetuksenYleisetTavoitteet(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public getRakenne(opsId: number, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).getRakenne(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {AihekokonaisuudetJarjestaDto} AihekokonaisuudetJarjestaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public reArrangeAihekokonaisuudet(opsId: number, AihekokonaisuudetJarjestaDto: AihekokonaisuudetJarjestaDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).reArrangeAihekokonaisuudet(opsId, AihekokonaisuudetJarjestaDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} kurssiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public reconnectKurssi(opsId: number, oppiaineId: number, kurssiId: number, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).reconnectKurssi(opsId, oppiaineId, kurssiId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} kurssiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public removeKurssi(opsId: number, kurssiId: number, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).removeKurssi(opsId, kurssiId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public saveAihekokonaisuus(opsId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).saveAihekokonaisuus(opsId, AihekokonaisuusSaveDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {LukiokurssiSaveDto} LukiokurssiSaveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public saveKurssi(opsId: number, LukiokurssiSaveDto: LukiokurssiSaveDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).saveKurssi(opsId, LukiokurssiSaveDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {LukioOppiaineSaveDto} LukioOppiaineSaveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public saveLukioOppiaine(opsId: number, LukioOppiaineSaveDto: LukioOppiaineSaveDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).saveLukioOppiaine(opsId, LukioOppiaineSaveDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public updateAihekokonaisuudetYleiskuvaus(opsId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).updateAihekokonaisuudetYleiskuvaus(opsId, AihekokonaisuusSaveDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} aihekokonaisuusId 
     * @param {AihekokonaisuusSaveDto} AihekokonaisuusSaveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public updateAihekokonaisuus(opsId: number, aihekokonaisuusId: number, AihekokonaisuusSaveDto: AihekokonaisuusSaveDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).updateAihekokonaisuus(opsId, aihekokonaisuusId, AihekokonaisuusSaveDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} kurssiId 
     * @param {LukiokurssiUpdateDto} LukiokurssiUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public updateKurssi(opsId: number, kurssiId: number, LukiokurssiUpdateDto: LukiokurssiUpdateDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).updateKurssi(opsId, kurssiId, LukiokurssiUpdateDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {LukioOppiaineSaveDto} LukioOppiaineSaveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public updateLukioOppiaine(opsId: number, LukioOppiaineSaveDto: LukioOppiaineSaveDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).updateLukioOppiaine(opsId, LukioOppiaineSaveDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {OpetuksenYleisetTavoitteetUpdateDto} OpetuksenYleisetTavoitteetUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public updateOpetuksenYleisetTavoitteet(opsId: number, OpetuksenYleisetTavoitteetUpdateDto: OpetuksenYleisetTavoitteetUpdateDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).updateOpetuksenYleisetTavoitteet(opsId, OpetuksenYleisetTavoitteetUpdateDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {OppaineKurssiTreeStructureDto} OppaineKurssiTreeStructureDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioOpetussuunnitelmatApi
     */
    public updateStructure(opsId: number, OppaineKurssiTreeStructureDto: OppaineKurssiTreeStructureDto, options?: any) {
        return LukioOpetussuunnitelmatApiFp(this.configuration).updateStructure(opsId, OppaineKurssiTreeStructureDto, options)(this.axios, this.basePath);
    }

}


/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cacheOpetussuunnitelmaJulkaisut(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/cache/julkaisut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cache 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(cache: string, options: any = {}): RequestArgs {
            // verify required parameter 'cache' is not null or undefined
            if (cache === null || cache === undefined) {
                throw new RequiredError('cache','Required parameter cache was null or undefined when calling clearCache.');
            }
            const localVarPath = `/api/maintenance/cacheclear/{cache}`
                .replace(`{${"cache"}}`, encodeURIComponent(String(cache)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [julkaisekaikki] 
         * @param {Array<string>} [koulutustyypit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisut(julkaisekaikki?: boolean, koulutustyypit?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/julkaisut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (julkaisekaikki !== undefined) {
                localVarQueryParameter['julkaisekaikki'] = julkaisekaikki;
            }

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cacheOpetussuunnitelmaJulkaisut(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).cacheOpetussuunnitelmaJulkaisut(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cache 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(cache: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).clearCache(cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [julkaisekaikki] 
         * @param {Array<string>} [koulutustyypit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisut(julkaisekaikki?: boolean, koulutustyypit?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).teeJulkaisut(julkaisekaikki, koulutustyypit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cacheOpetussuunnitelmaJulkaisut(options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).cacheOpetussuunnitelmaJulkaisut(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cache 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(cache: string, options?: any): AxiosPromise<string> {
            return MaintenanceApiFp(configuration).clearCache(cache, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} [julkaisekaikki] 
         * @param {Array<string>} [koulutustyypit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisut(julkaisekaikki?: boolean, koulutustyypit?: Array<string>, options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).teeJulkaisut(julkaisekaikki, koulutustyypit, options)(axios, basePath);
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public cacheOpetussuunnitelmaJulkaisut(options?: any) {
        return MaintenanceApiFp(this.configuration).cacheOpetussuunnitelmaJulkaisut(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cache 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public clearCache(cache: string, options?: any) {
        return MaintenanceApiFp(this.configuration).clearCache(cache, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} [julkaisekaikki] 
     * @param {Array<string>} [koulutustyypit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public teeJulkaisut(julkaisekaikki?: boolean, koulutustyypit?: Array<string>, options?: any) {
        return MaintenanceApiFp(this.configuration).teeJulkaisut(julkaisekaikki, koulutustyypit, options)(this.axios, this.basePath);
    }

}


/**
 * MuokkaustietoApi - axios parameter creator
 * @export
 */
export const MuokkaustietoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPohjanViimeisinPohjaTekstiSync(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpetussuunnitelmanPohjanViimeisinPohjaTekstiSync.');
            }
            const localVarPath = `/api/muokkaustieto/{opsId}/viimeisinpohjatekstisync/pohjaopetussuunnitelma`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} [viimeisinLuomisaika] 
         * @param {number} [lukumaara] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpsMuokkausTiedotWithLuomisaika(opsId: number, viimeisinLuomisaika?: number, lukumaara?: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOpsMuokkausTiedotWithLuomisaika.');
            }
            const localVarPath = `/api/muokkaustieto/{opsId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (viimeisinLuomisaika !== undefined) {
                localVarQueryParameter['viimeisinLuomisaika'] = viimeisinLuomisaika;
            }

            if (lukumaara !== undefined) {
                localVarQueryParameter['lukumaara'] = lukumaara;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViimeisinPohjatekstiSync(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getViimeisinPohjatekstiSync.');
            }
            const localVarPath = `/api/muokkaustieto/{opsId}/viimeisinpohjatekstisync`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MuokkaustietoApi - functional programming interface
 * @export
 */
export const MuokkaustietoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPohjanViimeisinPohjaTekstiSync(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmanMuokkaustietoDto> {
            const localVarAxiosArgs = MuokkaustietoApiAxiosParamCreator(configuration).getOpetussuunnitelmanPohjanViimeisinPohjaTekstiSync(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} [viimeisinLuomisaika] 
         * @param {number} [lukumaara] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpsMuokkausTiedotWithLuomisaika(opsId: number, viimeisinLuomisaika?: number, lukumaara?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MuokkaustietoKayttajallaDto>> {
            const localVarAxiosArgs = MuokkaustietoApiAxiosParamCreator(configuration).getOpsMuokkausTiedotWithLuomisaika(opsId, viimeisinLuomisaika, lukumaara, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViimeisinPohjatekstiSync(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmanMuokkaustietoDto> {
            const localVarAxiosArgs = MuokkaustietoApiAxiosParamCreator(configuration).getViimeisinPohjatekstiSync(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MuokkaustietoApi - factory interface
 * @export
 */
export const MuokkaustietoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPohjanViimeisinPohjaTekstiSync(opsId: number, options?: any): AxiosPromise<OpetussuunnitelmanMuokkaustietoDto> {
            return MuokkaustietoApiFp(configuration).getOpetussuunnitelmanPohjanViimeisinPohjaTekstiSync(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} [viimeisinLuomisaika] 
         * @param {number} [lukumaara] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpsMuokkausTiedotWithLuomisaika(opsId: number, viimeisinLuomisaika?: number, lukumaara?: number, options?: any): AxiosPromise<Array<MuokkaustietoKayttajallaDto>> {
            return MuokkaustietoApiFp(configuration).getOpsMuokkausTiedotWithLuomisaika(opsId, viimeisinLuomisaika, lukumaara, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViimeisinPohjatekstiSync(opsId: number, options?: any): AxiosPromise<OpetussuunnitelmanMuokkaustietoDto> {
            return MuokkaustietoApiFp(configuration).getViimeisinPohjatekstiSync(opsId, options)(axios, basePath);
        },
    };
};

/**
 * MuokkaustietoApi - object-oriented interface
 * @export
 * @class MuokkaustietoApi
 * @extends {BaseAPI}
 */
export class MuokkaustietoApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuokkaustietoApi
     */
    public getOpetussuunnitelmanPohjanViimeisinPohjaTekstiSync(opsId: number, options?: any) {
        return MuokkaustietoApiFp(this.configuration).getOpetussuunnitelmanPohjanViimeisinPohjaTekstiSync(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} [viimeisinLuomisaika] 
     * @param {number} [lukumaara] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuokkaustietoApi
     */
    public getOpsMuokkausTiedotWithLuomisaika(opsId: number, viimeisinLuomisaika?: number, lukumaara?: number, options?: any) {
        return MuokkaustietoApiFp(this.configuration).getOpsMuokkausTiedotWithLuomisaika(opsId, viimeisinLuomisaika, lukumaara, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuokkaustietoApi
     */
    public getViimeisinPohjatekstiSync(opsId: number, options?: any) {
        return MuokkaustietoApiFp(this.configuration).getViimeisinPohjatekstiSync(opsId, options)(this.axios, this.basePath);
    }

}


/**
 * OpetussuunnitelmanSisaltoApi - axios parameter creator
 * @export
 */
export const OpetussuunnitelmanSisaltoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {Matala} [Matala] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappale(opsId: number, Matala?: Matala, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addTekstiKappale.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof Matala !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(Matala !== undefined ? Matala : {}) : (Matala || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {Matala} [Matala] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappaleLapsi(opsId: number, viiteId: number, Matala?: Matala, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addTekstiKappaleLapsi.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling addTekstiKappaleLapsi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}/lapsi`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof Matala !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(Matala !== undefined ? Matala : {}) : (Matala || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} parentId 
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappaleLapsiTiettyynTekstikappaleeseen(opsId: number, parentId: number, childId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addTekstiKappaleLapsiTiettyynTekstikappaleeseen.');
            }
            // verify required parameter 'parentId' is not null or undefined
            if (parentId === null || parentId === undefined) {
                throw new RequiredError('parentId','Required parameter parentId was null or undefined when calling addTekstiKappaleLapsiTiettyynTekstikappaleeseen.');
            }
            // verify required parameter 'childId' is not null or undefined
            if (childId === null || childId === undefined) {
                throw new RequiredError('childId','Required parameter childId was null or undefined when calling addTekstiKappaleLapsiTiettyynTekstikappaleeseen.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{parentId}/lapsi/{childId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTekstikappale(opsId: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPerusteTekstikappale.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getPerusteTekstikappale.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}/peruste`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemovedTekstikappaleet(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getRemovedTekstikappaleet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/removed`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViite(opsId: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTekstiKappaleViite.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getTekstiKappaleViite.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViiteOriginal(opsId: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTekstiKappaleViiteOriginal.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getTekstiKappaleViiteOriginal.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}/alkuperainen`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViiteOriginals(opsId: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTekstiKappaleViiteOriginals.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getTekstiKappaleViiteOriginals.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}/alkuperaiset`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViiteSyva(opsId: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTekstiKappaleViiteSyva.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getTekstiKappaleViiteSyva.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}/kaikki`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiOtsikot(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTekstiOtsikot.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/otsikot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tunniste 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstikappaleAlaOpetussuunnitelmaLukumaara(opsId: number, tunniste: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTekstikappaleAlaOpetussuunnitelmaLukumaara.');
            }
            // verify required parameter 'tunniste' is not null or undefined
            if (tunniste === null || tunniste === undefined) {
                throw new RequiredError('tunniste','Required parameter tunniste was null or undefined when calling getTekstikappaleAlaOpetussuunnitelmaLukumaara.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{tunniste}/alaopetussuunnitelmalukumaara`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"tunniste"}}`, encodeURIComponent(String(tunniste)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstit(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTekstit.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionForTekstiKappaleViite(opsId: number, viiteId: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getVersionForTekstiKappaleViite.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getVersionForTekstiKappaleViite.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling getVersionForTekstiKappaleViite.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}/versio/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionsForTekstiKappaleViite(opsId: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getVersionsForTekstiKappaleViite.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getVersionsForTekstiKappaleViite.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}/versiot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaTekstiKappale(opsId: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling kloonaaTekstiKappale.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling kloonaaTekstiKappale.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}/muokattavakopio`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTekstiKappaleViite(opsId: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling removeTekstiKappaleViite.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling removeTekstiKappaleViite.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnRemoved(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling returnRemoved.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling returnRemoved.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{id}/returnRemoved`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertTekstikappaleToVersion(opsId: number, viiteId: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling revertTekstikappaleToVersion.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling revertTekstikappaleToVersion.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling revertTekstikappaleToVersion.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}/revert/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {Puu} Puu 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTekstiKappaleViite(opsId: number, viiteId: number, Puu: Puu, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateTekstiKappaleViite.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling updateTekstiKappaleViite.');
            }
            // verify required parameter 'Puu' is not null or undefined
            if (Puu === null || Puu === undefined) {
                throw new RequiredError('Puu','Required parameter Puu was null or undefined when calling updateTekstiKappaleViite.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/tekstit/{viiteId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof Puu !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(Puu !== undefined ? Puu : {}) : (Puu || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpetussuunnitelmanSisaltoApi - functional programming interface
 * @export
 */
export const OpetussuunnitelmanSisaltoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {Matala} [Matala] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappale(opsId: number, Matala?: Matala, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).addTekstiKappale(opsId, Matala, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {Matala} [Matala] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappaleLapsi(opsId: number, viiteId: number, Matala?: Matala, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).addTekstiKappaleLapsi(opsId, viiteId, Matala, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} parentId 
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappaleLapsiTiettyynTekstikappaleeseen(opsId: number, parentId: number, childId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).addTekstiKappaleLapsiTiettyynTekstikappaleeseen(opsId, parentId, childId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTekstikappale(opsId: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TekstiKappaleViiteDto> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getPerusteTekstikappale(opsId, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemovedTekstikappaleet(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PoistettuTekstiKappaleDto>> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getRemovedTekstikappaleet(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViite(opsId: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getTekstiKappaleViite(opsId, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViiteOriginal(opsId: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getTekstiKappaleViiteOriginal(opsId, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViiteOriginals(opsId: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Matala>> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getTekstiKappaleViiteOriginals(opsId, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViiteSyva(opsId: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Puu> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getTekstiKappaleViiteSyva(opsId, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiOtsikot(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TekstiKappaleViitePerusteTekstillaDto> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getTekstiOtsikot(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tunniste 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstikappaleAlaOpetussuunnitelmaLukumaara(opsId: number, tunniste: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getTekstikappaleAlaOpetussuunnitelmaLukumaara(opsId, tunniste, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstit(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Puu> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getTekstit(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionForTekstiKappaleViite(opsId: number, viiteId: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TekstiKappaleDto> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getVersionForTekstiKappaleViite(opsId, viiteId, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionsForTekstiKappaleViite(opsId: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RevisionKayttajaDto>> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).getVersionsForTekstiKappaleViite(opsId, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaTekstiKappale(opsId: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Puu> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).kloonaaTekstiKappale(opsId, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTekstiKappaleViite(opsId: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).removeTekstiKappaleViite(opsId, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnRemoved(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).returnRemoved(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertTekstikappaleToVersion(opsId: number, viiteId: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).revertTekstikappaleToVersion(opsId, viiteId, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {Puu} Puu 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTekstiKappaleViite(opsId: number, viiteId: number, Puu: Puu, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OpetussuunnitelmanSisaltoApiAxiosParamCreator(configuration).updateTekstiKappaleViite(opsId, viiteId, Puu, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpetussuunnitelmanSisaltoApi - factory interface
 * @export
 */
export const OpetussuunnitelmanSisaltoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {Matala} [Matala] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappale(opsId: number, Matala?: Matala, options?: any): AxiosPromise<Matala> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).addTekstiKappale(opsId, Matala, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {Matala} [Matala] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappaleLapsi(opsId: number, viiteId: number, Matala?: Matala, options?: any): AxiosPromise<Matala> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).addTekstiKappaleLapsi(opsId, viiteId, Matala, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} parentId 
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTekstiKappaleLapsiTiettyynTekstikappaleeseen(opsId: number, parentId: number, childId: number, options?: any): AxiosPromise<Matala> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).addTekstiKappaleLapsiTiettyynTekstikappaleeseen(opsId, parentId, childId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteTekstikappale(opsId: number, viiteId: number, options?: any): AxiosPromise<TekstiKappaleViiteDto> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getPerusteTekstikappale(opsId, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemovedTekstikappaleet(opsId: number, options?: any): AxiosPromise<Array<PoistettuTekstiKappaleDto>> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getRemovedTekstikappaleet(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViite(opsId: number, viiteId: number, options?: any): AxiosPromise<Matala> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getTekstiKappaleViite(opsId, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViiteOriginal(opsId: number, viiteId: number, options?: any): AxiosPromise<Matala> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getTekstiKappaleViiteOriginal(opsId, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViiteOriginals(opsId: number, viiteId: number, options?: any): AxiosPromise<Array<Matala>> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getTekstiKappaleViiteOriginals(opsId, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiKappaleViiteSyva(opsId: number, viiteId: number, options?: any): AxiosPromise<Puu> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getTekstiKappaleViiteSyva(opsId, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstiOtsikot(opsId: number, options?: any): AxiosPromise<TekstiKappaleViitePerusteTekstillaDto> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getTekstiOtsikot(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tunniste 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstikappaleAlaOpetussuunnitelmaLukumaara(opsId: number, tunniste: string, options?: any): AxiosPromise<number> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getTekstikappaleAlaOpetussuunnitelmaLukumaara(opsId, tunniste, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTekstit(opsId: number, options?: any): AxiosPromise<Puu> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getTekstit(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionForTekstiKappaleViite(opsId: number, viiteId: number, versio: number, options?: any): AxiosPromise<TekstiKappaleDto> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getVersionForTekstiKappaleViite(opsId, viiteId, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionsForTekstiKappaleViite(opsId: number, viiteId: number, options?: any): AxiosPromise<Array<RevisionKayttajaDto>> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).getVersionsForTekstiKappaleViite(opsId, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaTekstiKappale(opsId: number, viiteId: number, options?: any): AxiosPromise<Puu> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).kloonaaTekstiKappale(opsId, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTekstiKappaleViite(opsId: number, viiteId: number, options?: any): AxiosPromise<void> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).removeTekstiKappaleViite(opsId, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnRemoved(opsId: number, id: number, options?: any): AxiosPromise<void> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).returnRemoved(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertTekstikappaleToVersion(opsId: number, viiteId: number, versio: number, options?: any): AxiosPromise<void> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).revertTekstikappaleToVersion(opsId, viiteId, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} viiteId 
         * @param {Puu} Puu 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTekstiKappaleViite(opsId: number, viiteId: number, Puu: Puu, options?: any): AxiosPromise<void> {
            return OpetussuunnitelmanSisaltoApiFp(configuration).updateTekstiKappaleViite(opsId, viiteId, Puu, options)(axios, basePath);
        },
    };
};

/**
 * OpetussuunnitelmanSisaltoApi - object-oriented interface
 * @export
 * @class OpetussuunnitelmanSisaltoApi
 * @extends {BaseAPI}
 */
export class OpetussuunnitelmanSisaltoApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {Matala} [Matala] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public addTekstiKappale(opsId: number, Matala?: Matala, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).addTekstiKappale(opsId, Matala, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {Matala} [Matala] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public addTekstiKappaleLapsi(opsId: number, viiteId: number, Matala?: Matala, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).addTekstiKappaleLapsi(opsId, viiteId, Matala, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} parentId 
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public addTekstiKappaleLapsiTiettyynTekstikappaleeseen(opsId: number, parentId: number, childId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).addTekstiKappaleLapsiTiettyynTekstikappaleeseen(opsId, parentId, childId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getPerusteTekstikappale(opsId: number, viiteId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getPerusteTekstikappale(opsId, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getRemovedTekstikappaleet(opsId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getRemovedTekstikappaleet(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getTekstiKappaleViite(opsId: number, viiteId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getTekstiKappaleViite(opsId, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getTekstiKappaleViiteOriginal(opsId: number, viiteId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getTekstiKappaleViiteOriginal(opsId, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getTekstiKappaleViiteOriginals(opsId: number, viiteId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getTekstiKappaleViiteOriginals(opsId, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getTekstiKappaleViiteSyva(opsId: number, viiteId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getTekstiKappaleViiteSyva(opsId, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getTekstiOtsikot(opsId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getTekstiOtsikot(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} tunniste 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getTekstikappaleAlaOpetussuunnitelmaLukumaara(opsId: number, tunniste: string, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getTekstikappaleAlaOpetussuunnitelmaLukumaara(opsId, tunniste, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getTekstit(opsId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getTekstit(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getVersionForTekstiKappaleViite(opsId: number, viiteId: number, versio: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getVersionForTekstiKappaleViite(opsId, viiteId, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public getVersionsForTekstiKappaleViite(opsId: number, viiteId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).getVersionsForTekstiKappaleViite(opsId, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public kloonaaTekstiKappale(opsId: number, viiteId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).kloonaaTekstiKappale(opsId, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public removeTekstiKappaleViite(opsId: number, viiteId: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).removeTekstiKappaleViite(opsId, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public returnRemoved(opsId: number, id: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).returnRemoved(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public revertTekstikappaleToVersion(opsId: number, viiteId: number, versio: number, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).revertTekstikappaleToVersion(opsId, viiteId, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} viiteId 
     * @param {Puu} Puu 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmanSisaltoApi
     */
    public updateTekstiKappaleViite(opsId: number, viiteId: number, Puu: Puu, options?: any) {
        return OpetussuunnitelmanSisaltoApiFp(this.configuration).updateTekstiKappaleViite(opsId, viiteId, Puu, options)(this.axios, this.basePath);
    }

}


/**
 * OpetussuunnitelmatApi - axios parameter creator
 * @export
 */
export const OpetussuunnitelmatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OpetussuunnitelmaLuontiDto} OpetussuunnitelmaLuontiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpetussuunnitelma(OpetussuunnitelmaLuontiDto: OpetussuunnitelmaLuontiDto, options: any = {}): RequestArgs {
            // verify required parameter 'OpetussuunnitelmaLuontiDto' is not null or undefined
            if (OpetussuunnitelmaLuontiDto === null || OpetussuunnitelmaLuontiDto === undefined) {
                throw new RequiredError('OpetussuunnitelmaLuontiDto','Required parameter OpetussuunnitelmaLuontiDto was null or undefined when calling addOpetussuunnitelma.');
            }
            const localVarPath = `/api/opetussuunnitelmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OpetussuunnitelmaLuontiDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OpetussuunnitelmaLuontiDto !== undefined ? OpetussuunnitelmaLuontiDto : {}) : (OpetussuunnitelmaLuontiDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'OPS' | 'POHJA'} [tyyppi] 
         * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} [tila] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(tyyppi?: 'OPS' | 'POHJA', tila?: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', options: any = {}): RequestArgs {
            const localVarPath = `/api/opetussuunnitelmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (tila !== undefined) {
                localVarQueryParameter['tila'] = tila;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikki(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getKaikki.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/kaikki`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} koodisto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistonKoodit(opsId: number, koodisto: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getKoodistonKoodit.');
            }
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling getKoodistonKoodit.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/koodisto/{koodisto}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLaajalaisetosamiset(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLaajalaisetosamiset.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/laajaalaisetosaamiset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLapsiOpetussuunnitelmat(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLapsiOpetussuunnitelmat.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/opetussuunnitelmat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigation(id: number, kieli?: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNavigation.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/navigaatio`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [kieli] 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationPublic(id: number, kieli?: string, revision?: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNavigationPublic.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/navigaatio/public`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (revision !== undefined) {
                localVarQueryParameter['revision'] = revision;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOikeudet(options: any = {}): RequestArgs {
            const localVarPath = `/api/opetussuunnitelmat/oikeudet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOikeudetById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOikeudetById.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/oikeudet`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelma(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpetussuunnitelma.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNimi(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpetussuunnitelmaNimi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/nimi`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaOrganisaatiotarkistuksella(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpetussuunnitelmaOrganisaatiotarkistuksella.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/organisaatiotarkistus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaSisalto(id: number, query: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpetussuunnitelmaSisalto.');
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query','Required parameter query was null or undefined when calling getOpetussuunnitelmaSisalto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/sisalto`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaTilastot(options: any = {}): RequestArgs {
            const localVarPath = `/api/opetussuunnitelmat/tilastot`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPeruste(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpetussuunnitelmanPeruste.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/peruste`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPohjanVuosiluokkakokonaisuudet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpetussuunnitelmanPohjanVuosiluokkakokonaisuudet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/pohja/vuosiluokkakokonaisuudet`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmienOpsPohjat(options: any = {}): RequestArgs {
            const localVarPath = `/api/opetussuunnitelmat/pohjat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tyyppi 
         * @param {string} tila 
         * @param {string} [koulutustyyppi] 
         * @param {string} [nimi] 
         * @param {string} [jarjestys] 
         * @param {string} [jarjestyssuunta] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSivutettu(tyyppi: string, tila: string, koulutustyyppi?: string, nimi?: string, jarjestys?: string, jarjestyssuunta?: string, sivu?: number, sivukoko?: number, kieli?: string, options: any = {}): RequestArgs {
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling getSivutettu.');
            }
            // verify required parameter 'tila' is not null or undefined
            if (tila === null || tila === undefined) {
                throw new RequiredError('tila','Required parameter tila was null or undefined when calling getSivutettu.');
            }
            const localVarPath = `/api/opetussuunnitelmat/sivutettu`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (tila !== undefined) {
                localVarQueryParameter['tila'] = tila;
            }

            if (koulutustyyppi !== undefined) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (jarjestys !== undefined) {
                localVarQueryParameter['jarjestys'] = jarjestys;
            }

            if (jarjestyssuunta !== undefined) {
                localVarQueryParameter['jarjestyssuunta'] = jarjestyssuunta;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistiikka(options: any = {}): RequestArgs {
            const localVarPath = `/api/opetussuunnitelmat/statistiikka`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haePohjavaihtoehdot(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling haePohjavaihtoehdot.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/pohjavaihtoehdot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPerusteTekstit(id: number, skip?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling importPerusteTekstit.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/importperustetekstit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opetussuunnitelmanPohjallaUusiaTeksteja(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling opetussuunnitelmanPohjallaUusiaTeksteja.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/opetussuunnitelmanPohjallaUusiaTeksteja`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaTekstirakenne(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling palautaTekstirakenne.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/palauteTekstirakenne`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pohjanperustepaivittynyt(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pohjanperustepaivittynyt.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/pohjanperustepaivittynyt`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling sync.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTekstitPohjasta(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling syncTekstitPohjasta.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/syncTekstitPohjasta`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLapsiOpetussuunnitelmat(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateLapsiOpetussuunnitelmat.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/opetussuunnitelmat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OpetussuunnitelmaDto} OpetussuunnitelmaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelma(id: number, OpetussuunnitelmaDto: OpetussuunnitelmaDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOpetussuunnitelma.');
            }
            // verify required parameter 'OpetussuunnitelmaDto' is not null or undefined
            if (OpetussuunnitelmaDto === null || OpetussuunnitelmaDto === undefined) {
                throw new RequiredError('OpetussuunnitelmaDto','Required parameter OpetussuunnitelmaDto was null or undefined when calling updateOpetussuunnitelma.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OpetussuunnitelmaDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OpetussuunnitelmaDto !== undefined ? OpetussuunnitelmaDto : {}) : (OpetussuunnitelmaDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OppiaineOpintojaksoDto>} OppiaineOpintojaksoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineJaOpintojaksojarjestys(id: number, OppiaineOpintojaksoDto: Array<OppiaineOpintojaksoDto>, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOppiaineJaOpintojaksojarjestys.');
            }
            // verify required parameter 'OppiaineOpintojaksoDto' is not null or undefined
            if (OppiaineOpintojaksoDto === null || OppiaineOpintojaksoDto === undefined) {
                throw new RequiredError('OppiaineOpintojaksoDto','Required parameter OppiaineOpintojaksoDto was null or undefined when calling updateOppiaineJaOpintojaksojarjestys.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/oppiaineopintojaksojarjestys`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppiaineOpintojaksoDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppiaineOpintojaksoDto !== undefined ? OppiaineOpintojaksoDto : {}) : (OppiaineOpintojaksoDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<JarjestysDto>} JarjestysDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiainejarjestys(id: number, JarjestysDto: Array<JarjestysDto>, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOppiainejarjestys.');
            }
            // verify required parameter 'JarjestysDto' is not null or undefined
            if (JarjestysDto === null || JarjestysDto === undefined) {
                throw new RequiredError('JarjestysDto','Required parameter JarjestysDto was null or undefined when calling updateOppiainejarjestys.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/oppiainejarjestys`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof JarjestysDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(JarjestysDto !== undefined ? JarjestysDto : {}) : (JarjestysDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} tila 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTila(id: number, tila: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTila.');
            }
            // verify required parameter 'tila' is not null or undefined
            if (tila === null || tila === undefined) {
                throw new RequiredError('tila','Required parameter tila was null or undefined when calling updateTila.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/tila/{tila}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tila"}}`, encodeURIComponent(String(tila)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} pohjaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vaihdaPohja(id: number, pohjaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling vaihdaPohja.');
            }
            // verify required parameter 'pohjaId' is not null or undefined
            if (pohjaId === null || pohjaId === undefined) {
                throw new RequiredError('pohjaId','Required parameter pohjaId was null or undefined when calling vaihdaPohja.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/vaihda/{pohjaId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pohjaId"}}`, encodeURIComponent(String(pohjaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validoiOpetussuunnitelma(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling validoiOpetussuunnitelma.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{id}/validoi`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpetussuunnitelmatApi - functional programming interface
 * @export
 */
export const OpetussuunnitelmatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OpetussuunnitelmaLuontiDto} OpetussuunnitelmaLuontiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpetussuunnitelma(OpetussuunnitelmaLuontiDto: OpetussuunnitelmaLuontiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaBaseDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).addOpetussuunnitelma(OpetussuunnitelmaLuontiDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {'OPS' | 'POHJA'} [tyyppi] 
         * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} [tila] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(tyyppi?: 'OPS' | 'POHJA', tila?: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaInfoDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getAll(tyyppi, tila, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikki(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaExportDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getKaikki(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} koodisto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistonKoodit(opsId: number, koodisto: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getKoodistonKoodit(opsId, koodisto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLaajalaisetosamiset(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteLaajaalainenosaaminenDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getLaajalaisetosamiset(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLapsiOpetussuunnitelmat(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaInfoDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getLapsiOpetussuunnitelmat(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigation(id: number, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NavigationNodeDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getNavigation(id, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [kieli] 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationPublic(id: number, kieli?: string, revision?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NavigationNodeDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getNavigationPublic(id, kieli, revision, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOikeudet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOikeudet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOikeudetById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOikeudetById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelma(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaKevytDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelma(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNimi(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaNimiDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaNimi(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaOrganisaatiotarkistuksella(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaKevytDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaOrganisaatiotarkistuksella(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaSisalto(id: number, query: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaSisalto(id, query, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaTilastot(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaTilastoDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmaTilastot(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPeruste(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteInfoDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmanPeruste(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPohjanVuosiluokkakokonaisuudet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpsVuosiluokkakokonaisuusKevytDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmanPohjanVuosiluokkakokonaisuudet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmienOpsPohjat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaInfoDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getOpetussuunnitelmienOpsPohjat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tyyppi 
         * @param {string} tila 
         * @param {string} [koulutustyyppi] 
         * @param {string} [nimi] 
         * @param {string} [jarjestys] 
         * @param {string} [jarjestyssuunta] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSivutettu(tyyppi: string, tila: string, koulutustyyppi?: string, nimi?: string, jarjestys?: string, jarjestyssuunta?: string, sivu?: number, sivukoko?: number, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpetussuunnitelmaInfoDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getSivutettu(tyyppi, tila, koulutustyyppi, nimi, jarjestys, jarjestyssuunta, sivu, sivukoko, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistiikka(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaStatistiikkaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).getStatistiikka(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haePohjavaihtoehdot(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaInfoDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).haePohjavaihtoehdot(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPerusteTekstit(id: number, skip?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).importPerusteTekstit(id, skip, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opetussuunnitelmanPohjallaUusiaTeksteja(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).opetussuunnitelmanPohjallaUusiaTeksteja(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaTekstirakenne(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).palautaTekstirakenne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pohjanperustepaivittynyt(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).pohjanperustepaivittynyt(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).sync(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTekstitPohjasta(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).syncTekstitPohjasta(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLapsiOpetussuunnitelmat(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateLapsiOpetussuunnitelmat(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OpetussuunnitelmaDto} OpetussuunnitelmaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelma(id: number, OpetussuunnitelmaDto: OpetussuunnitelmaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateOpetussuunnitelma(id, OpetussuunnitelmaDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OppiaineOpintojaksoDto>} OppiaineOpintojaksoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineJaOpintojaksojarjestys(id: number, OppiaineOpintojaksoDto: Array<OppiaineOpintojaksoDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateOppiaineJaOpintojaksojarjestys(id, OppiaineOpintojaksoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<JarjestysDto>} JarjestysDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiainejarjestys(id: number, JarjestysDto: Array<JarjestysDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateOppiainejarjestys(id, JarjestysDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} tila 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTila(id: number, tila: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaDto> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).updateTila(id, tila, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} pohjaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vaihdaPohja(id: number, pohjaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).vaihdaPohja(id, pohjaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validoiOpetussuunnitelma(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Validointi>> {
            const localVarAxiosArgs = OpetussuunnitelmatApiAxiosParamCreator(configuration).validoiOpetussuunnitelma(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpetussuunnitelmatApi - factory interface
 * @export
 */
export const OpetussuunnitelmatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {OpetussuunnitelmaLuontiDto} OpetussuunnitelmaLuontiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpetussuunnitelma(OpetussuunnitelmaLuontiDto: OpetussuunnitelmaLuontiDto, options?: any): AxiosPromise<OpetussuunnitelmaBaseDto> {
            return OpetussuunnitelmatApiFp(configuration).addOpetussuunnitelma(OpetussuunnitelmaLuontiDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {'OPS' | 'POHJA'} [tyyppi] 
         * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} [tila] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(tyyppi?: 'OPS' | 'POHJA', tila?: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', options?: any): AxiosPromise<Array<OpetussuunnitelmaInfoDto>> {
            return OpetussuunnitelmatApiFp(configuration).getAll(tyyppi, tila, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikki(id: number, options?: any): AxiosPromise<OpetussuunnitelmaExportDto> {
            return OpetussuunnitelmatApiFp(configuration).getKaikki(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} koodisto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodistonKoodit(opsId: number, koodisto: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return OpetussuunnitelmatApiFp(configuration).getKoodistonKoodit(opsId, koodisto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLaajalaisetosamiset(id: number, options?: any): AxiosPromise<Array<PerusteLaajaalainenosaaminenDto>> {
            return OpetussuunnitelmatApiFp(configuration).getLaajalaisetosamiset(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLapsiOpetussuunnitelmat(id: number, options?: any): AxiosPromise<Array<OpetussuunnitelmaInfoDto>> {
            return OpetussuunnitelmatApiFp(configuration).getLapsiOpetussuunnitelmat(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigation(id: number, kieli?: string, options?: any): AxiosPromise<NavigationNodeDto> {
            return OpetussuunnitelmatApiFp(configuration).getNavigation(id, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [kieli] 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationPublic(id: number, kieli?: string, revision?: number, options?: any): AxiosPromise<NavigationNodeDto> {
            return OpetussuunnitelmatApiFp(configuration).getNavigationPublic(id, kieli, revision, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOikeudet(options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return OpetussuunnitelmatApiFp(configuration).getOikeudet(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOikeudetById(id: number, options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return OpetussuunnitelmatApiFp(configuration).getOikeudetById(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelma(id: number, options?: any): AxiosPromise<OpetussuunnitelmaKevytDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelma(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaNimi(id: number, options?: any): AxiosPromise<OpetussuunnitelmaNimiDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaNimi(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaOrganisaatiotarkistuksella(id: number, options?: any): AxiosPromise<OpetussuunnitelmaKevytDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaOrganisaatiotarkistuksella(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaSisalto(id: number, query: string, options?: any): AxiosPromise<object> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaSisalto(id, query, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaTilastot(options?: any): AxiosPromise<Array<OpetussuunnitelmaTilastoDto>> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmaTilastot(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPeruste(id: number, options?: any): AxiosPromise<PerusteInfoDto> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmanPeruste(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanPohjanVuosiluokkakokonaisuudet(id: number, options?: any): AxiosPromise<Array<OpsVuosiluokkakokonaisuusKevytDto>> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmanPohjanVuosiluokkakokonaisuudet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmienOpsPohjat(options?: any): AxiosPromise<Array<OpetussuunnitelmaInfoDto>> {
            return OpetussuunnitelmatApiFp(configuration).getOpetussuunnitelmienOpsPohjat(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} tyyppi 
         * @param {string} tila 
         * @param {string} [koulutustyyppi] 
         * @param {string} [nimi] 
         * @param {string} [jarjestys] 
         * @param {string} [jarjestyssuunta] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSivutettu(tyyppi: string, tila: string, koulutustyyppi?: string, nimi?: string, jarjestys?: string, jarjestyssuunta?: string, sivu?: number, sivukoko?: number, kieli?: string, options?: any): AxiosPromise<PageOpetussuunnitelmaInfoDto> {
            return OpetussuunnitelmatApiFp(configuration).getSivutettu(tyyppi, tila, koulutustyyppi, nimi, jarjestys, jarjestyssuunta, sivu, sivukoko, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistiikka(options?: any): AxiosPromise<OpetussuunnitelmaStatistiikkaDto> {
            return OpetussuunnitelmatApiFp(configuration).getStatistiikka(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        haePohjavaihtoehdot(id: number, options?: any): AxiosPromise<Array<OpetussuunnitelmaInfoDto>> {
            return OpetussuunnitelmatApiFp(configuration).haePohjavaihtoehdot(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPerusteTekstit(id: number, skip?: boolean, options?: any): AxiosPromise<OpetussuunnitelmaDto> {
            return OpetussuunnitelmatApiFp(configuration).importPerusteTekstit(id, skip, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opetussuunnitelmanPohjallaUusiaTeksteja(id: number, options?: any): AxiosPromise<boolean> {
            return OpetussuunnitelmatApiFp(configuration).opetussuunnitelmanPohjallaUusiaTeksteja(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaTekstirakenne(id: number, options?: any): AxiosPromise<void> {
            return OpetussuunnitelmatApiFp(configuration).palautaTekstirakenne(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pohjanperustepaivittynyt(id: number, options?: any): AxiosPromise<boolean> {
            return OpetussuunnitelmatApiFp(configuration).pohjanperustepaivittynyt(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync(id: number, options?: any): AxiosPromise<string> {
            return OpetussuunnitelmatApiFp(configuration).sync(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTekstitPohjasta(id: number, options?: any): AxiosPromise<string> {
            return OpetussuunnitelmatApiFp(configuration).syncTekstitPohjasta(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLapsiOpetussuunnitelmat(id: number, options?: any): AxiosPromise<string> {
            return OpetussuunnitelmatApiFp(configuration).updateLapsiOpetussuunnitelmat(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {OpetussuunnitelmaDto} OpetussuunnitelmaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpetussuunnitelma(id: number, OpetussuunnitelmaDto: OpetussuunnitelmaDto, options?: any): AxiosPromise<OpetussuunnitelmaDto> {
            return OpetussuunnitelmatApiFp(configuration).updateOpetussuunnitelma(id, OpetussuunnitelmaDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<OppiaineOpintojaksoDto>} OppiaineOpintojaksoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineJaOpintojaksojarjestys(id: number, OppiaineOpintojaksoDto: Array<OppiaineOpintojaksoDto>, options?: any): AxiosPromise<string> {
            return OpetussuunnitelmatApiFp(configuration).updateOppiaineJaOpintojaksojarjestys(id, OppiaineOpintojaksoDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<JarjestysDto>} JarjestysDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiainejarjestys(id: number, JarjestysDto: Array<JarjestysDto>, options?: any): AxiosPromise<string> {
            return OpetussuunnitelmatApiFp(configuration).updateOppiainejarjestys(id, JarjestysDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} tila 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTila(id: number, tila: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', options?: any): AxiosPromise<OpetussuunnitelmaDto> {
            return OpetussuunnitelmatApiFp(configuration).updateTila(id, tila, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} pohjaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vaihdaPohja(id: number, pohjaId: number, options?: any): AxiosPromise<string> {
            return OpetussuunnitelmatApiFp(configuration).vaihdaPohja(id, pohjaId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validoiOpetussuunnitelma(id: number, options?: any): AxiosPromise<Array<Validointi>> {
            return OpetussuunnitelmatApiFp(configuration).validoiOpetussuunnitelma(id, options)(axios, basePath);
        },
    };
};

/**
 * OpetussuunnitelmatApi - object-oriented interface
 * @export
 * @class OpetussuunnitelmatApi
 * @extends {BaseAPI}
 */
export class OpetussuunnitelmatApi extends BaseAPI {
    /**
     * 
     * @param {OpetussuunnitelmaLuontiDto} OpetussuunnitelmaLuontiDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public addOpetussuunnitelma(OpetussuunnitelmaLuontiDto: OpetussuunnitelmaLuontiDto, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).addOpetussuunnitelma(OpetussuunnitelmaLuontiDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {'OPS' | 'POHJA'} [tyyppi] 
     * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} [tila] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getAll(tyyppi?: 'OPS' | 'POHJA', tila?: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getAll(tyyppi, tila, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getKaikki(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getKaikki(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} koodisto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getKoodistonKoodit(opsId: number, koodisto: string, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getKoodistonKoodit(opsId, koodisto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getLaajalaisetosamiset(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getLaajalaisetosamiset(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getLapsiOpetussuunnitelmat(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getLapsiOpetussuunnitelmat(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getNavigation(id: number, kieli?: string, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getNavigation(id, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [kieli] 
     * @param {number} [revision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getNavigationPublic(id: number, kieli?: string, revision?: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getNavigationPublic(id, kieli, revision, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOikeudet(options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOikeudet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOikeudetById(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOikeudetById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelma(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelma(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaNimi(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaNimi(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaOrganisaatiotarkistuksella(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaOrganisaatiotarkistuksella(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaSisalto(id: number, query: string, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaSisalto(id, query, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmaTilastot(options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmaTilastot(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmanPeruste(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmanPeruste(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmanPohjanVuosiluokkakokonaisuudet(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmanPohjanVuosiluokkakokonaisuudet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getOpetussuunnitelmienOpsPohjat(options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getOpetussuunnitelmienOpsPohjat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} tyyppi 
     * @param {string} tila 
     * @param {string} [koulutustyyppi] 
     * @param {string} [nimi] 
     * @param {string} [jarjestys] 
     * @param {string} [jarjestyssuunta] 
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getSivutettu(tyyppi: string, tila: string, koulutustyyppi?: string, nimi?: string, jarjestys?: string, jarjestyssuunta?: string, sivu?: number, sivukoko?: number, kieli?: string, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getSivutettu(tyyppi, tila, koulutustyyppi, nimi, jarjestys, jarjestyssuunta, sivu, sivukoko, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public getStatistiikka(options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).getStatistiikka(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public haePohjavaihtoehdot(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).haePohjavaihtoehdot(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public importPerusteTekstit(id: number, skip?: boolean, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).importPerusteTekstit(id, skip, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public opetussuunnitelmanPohjallaUusiaTeksteja(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).opetussuunnitelmanPohjallaUusiaTeksteja(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public palautaTekstirakenne(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).palautaTekstirakenne(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public pohjanperustepaivittynyt(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).pohjanperustepaivittynyt(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public sync(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).sync(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public syncTekstitPohjasta(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).syncTekstitPohjasta(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateLapsiOpetussuunnitelmat(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateLapsiOpetussuunnitelmat(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {OpetussuunnitelmaDto} OpetussuunnitelmaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateOpetussuunnitelma(id: number, OpetussuunnitelmaDto: OpetussuunnitelmaDto, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateOpetussuunnitelma(id, OpetussuunnitelmaDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<OppiaineOpintojaksoDto>} OppiaineOpintojaksoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateOppiaineJaOpintojaksojarjestys(id: number, OppiaineOpintojaksoDto: Array<OppiaineOpintojaksoDto>, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateOppiaineJaOpintojaksojarjestys(id, OppiaineOpintojaksoDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<JarjestysDto>} JarjestysDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateOppiainejarjestys(id: number, JarjestysDto: Array<JarjestysDto>, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateOppiainejarjestys(id, JarjestysDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU'} tila 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public updateTila(id: number, tila: 'LUONNOS' | 'VALMIS' | 'POISTETTU' | 'JULKAISTU', options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).updateTila(id, tila, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} pohjaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public vaihdaPohja(id: number, pohjaId: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).vaihdaPohja(id, pohjaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatApi
     */
    public validoiOpetussuunnitelma(id: number, options?: any) {
        return OpetussuunnitelmatApiFp(this.configuration).validoiOpetussuunnitelma(id, options)(this.axios, this.basePath);
    }

}


/**
 * OpetussuunnitelmatJulkisetApi - axios parameter creator
 * @export
 */
export const OpetussuunnitelmatJulkisetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [koulutustyypit] koulutustyypit
         * @param {string} [nimi] nimi
         * @param {string} [kieli] kieli
         * @param {string} [perusteenDiaarinumero] perusteenDiaarinumero
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJulkaistutOpetussuunnitelmat(koulutustyypit?: Array<string>, nimi?: string, kieli?: string, perusteenDiaarinumero?: string, sivu?: number, sivukoko?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/opetussuunnitelmat/julkiset/julkaisut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyypit) {
                localVarQueryParameter['koulutustyypit'] = koulutustyypit;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (perusteenDiaarinumero !== undefined) {
                localVarQueryParameter['perusteenDiaarinumero'] = perusteenDiaarinumero;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {Array<string>} [organisaatio] organisaatio oid (organisaatiopalvelusta)
         * @param {Array<string>} [tyyppi] ops | pohja
         * @param {Array<string>} [perusteenId] perusterajaus
         * @param {Array<string>} [perusteenDiaarinumero] perusterajaus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJulkiset(koulutustyyppi?: Array<string>, organisaatio?: Array<string>, tyyppi?: Array<string>, perusteenId?: Array<string>, perusteenDiaarinumero?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/opetussuunnitelmat/julkiset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (organisaatio) {
                localVarQueryParameter['organisaatio'] = organisaatio;
            }

            if (tyyppi) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (perusteenId) {
                localVarQueryParameter['perusteenId'] = perusteenId;
            }

            if (perusteenDiaarinumero) {
                localVarQueryParameter['perusteenDiaarinumero'] = perusteenDiaarinumero;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiJulkaistutOpetussuunnitelmat(options: any = {}): RequestArgs {
            const localVarPath = `/api/opetussuunnitelmat/julkiset/kaikki`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaJulkaistu(id: number, revision?: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpetussuunnitelmaJulkaistu.');
            }
            const localVarPath = `/api/opetussuunnitelmat/julkiset/{id}/julkaisu`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (revision !== undefined) {
                localVarQueryParameter['revision'] = revision;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanJulkisetTiedot(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpetussuunnitelmanJulkisetTiedot.');
            }
            const localVarPath = `/api/opetussuunnitelmat/julkiset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpetussuunnitelmatJulkisetApi - functional programming interface
 * @export
 */
export const OpetussuunnitelmatJulkisetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [koulutustyypit] koulutustyypit
         * @param {string} [nimi] nimi
         * @param {string} [kieli] kieli
         * @param {string} [perusteenDiaarinumero] perusteenDiaarinumero
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJulkaistutOpetussuunnitelmat(koulutustyypit?: Array<string>, nimi?: string, kieli?: string, perusteenDiaarinumero?: string, sivu?: number, sivukoko?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOpetussuunnitelmaJulkinenDto> {
            const localVarAxiosArgs = OpetussuunnitelmatJulkisetApiAxiosParamCreator(configuration).getAllJulkaistutOpetussuunnitelmat(koulutustyypit, nimi, kieli, perusteenDiaarinumero, sivu, sivukoko, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {Array<string>} [organisaatio] organisaatio oid (organisaatiopalvelusta)
         * @param {Array<string>} [tyyppi] ops | pohja
         * @param {Array<string>} [perusteenId] perusterajaus
         * @param {Array<string>} [perusteenDiaarinumero] perusterajaus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJulkiset(koulutustyyppi?: Array<string>, organisaatio?: Array<string>, tyyppi?: Array<string>, perusteenId?: Array<string>, perusteenDiaarinumero?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaJulkinenDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatJulkisetApiAxiosParamCreator(configuration).getAllJulkiset(koulutustyyppi, organisaatio, tyyppi, perusteenId, perusteenDiaarinumero, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiJulkaistutOpetussuunnitelmat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetussuunnitelmaJulkinenDto>> {
            const localVarAxiosArgs = OpetussuunnitelmatJulkisetApiAxiosParamCreator(configuration).getKaikkiJulkaistutOpetussuunnitelmat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaJulkaistu(id: number, revision?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaExportDto> {
            const localVarAxiosArgs = OpetussuunnitelmatJulkisetApiAxiosParamCreator(configuration).getOpetussuunnitelmaJulkaistu(id, revision, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanJulkisetTiedot(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetussuunnitelmaJulkinenDto> {
            const localVarAxiosArgs = OpetussuunnitelmatJulkisetApiAxiosParamCreator(configuration).getOpetussuunnitelmanJulkisetTiedot(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OpetussuunnitelmatJulkisetApi - factory interface
 * @export
 */
export const OpetussuunnitelmatJulkisetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Array<string>} [koulutustyypit] koulutustyypit
         * @param {string} [nimi] nimi
         * @param {string} [kieli] kieli
         * @param {string} [perusteenDiaarinumero] perusteenDiaarinumero
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJulkaistutOpetussuunnitelmat(koulutustyypit?: Array<string>, nimi?: string, kieli?: string, perusteenDiaarinumero?: string, sivu?: number, sivukoko?: number, options?: any): AxiosPromise<PageOpetussuunnitelmaJulkinenDto> {
            return OpetussuunnitelmatJulkisetApiFp(configuration).getAllJulkaistutOpetussuunnitelmat(koulutustyypit, nimi, kieli, perusteenDiaarinumero, sivu, sivukoko, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {Array<string>} [organisaatio] organisaatio oid (organisaatiopalvelusta)
         * @param {Array<string>} [tyyppi] ops | pohja
         * @param {Array<string>} [perusteenId] perusterajaus
         * @param {Array<string>} [perusteenDiaarinumero] perusterajaus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJulkiset(koulutustyyppi?: Array<string>, organisaatio?: Array<string>, tyyppi?: Array<string>, perusteenId?: Array<string>, perusteenDiaarinumero?: Array<string>, options?: any): AxiosPromise<Array<OpetussuunnitelmaJulkinenDto>> {
            return OpetussuunnitelmatJulkisetApiFp(configuration).getAllJulkiset(koulutustyyppi, organisaatio, tyyppi, perusteenId, perusteenDiaarinumero, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiJulkaistutOpetussuunnitelmat(options?: any): AxiosPromise<Array<OpetussuunnitelmaJulkinenDto>> {
            return OpetussuunnitelmatJulkisetApiFp(configuration).getKaikkiJulkaistutOpetussuunnitelmat(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmaJulkaistu(id: number, revision?: number, options?: any): AxiosPromise<OpetussuunnitelmaExportDto> {
            return OpetussuunnitelmatJulkisetApiFp(configuration).getOpetussuunnitelmaJulkaistu(id, revision, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanJulkisetTiedot(id: number, options?: any): AxiosPromise<OpetussuunnitelmaJulkinenDto> {
            return OpetussuunnitelmatJulkisetApiFp(configuration).getOpetussuunnitelmanJulkisetTiedot(id, options)(axios, basePath);
        },
    };
};

/**
 * OpetussuunnitelmatJulkisetApi - object-oriented interface
 * @export
 * @class OpetussuunnitelmatJulkisetApi
 * @extends {BaseAPI}
 */
export class OpetussuunnitelmatJulkisetApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} [koulutustyypit] koulutustyypit
     * @param {string} [nimi] nimi
     * @param {string} [kieli] kieli
     * @param {string} [perusteenDiaarinumero] perusteenDiaarinumero
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatJulkisetApi
     */
    public getAllJulkaistutOpetussuunnitelmat(koulutustyypit?: Array<string>, nimi?: string, kieli?: string, perusteenDiaarinumero?: string, sivu?: number, sivukoko?: number, options?: any) {
        return OpetussuunnitelmatJulkisetApiFp(this.configuration).getAllJulkaistutOpetussuunnitelmat(koulutustyypit, nimi, kieli, perusteenDiaarinumero, sivu, sivukoko, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
     * @param {Array<string>} [organisaatio] organisaatio oid (organisaatiopalvelusta)
     * @param {Array<string>} [tyyppi] ops | pohja
     * @param {Array<string>} [perusteenId] perusterajaus
     * @param {Array<string>} [perusteenDiaarinumero] perusterajaus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatJulkisetApi
     */
    public getAllJulkiset(koulutustyyppi?: Array<string>, organisaatio?: Array<string>, tyyppi?: Array<string>, perusteenId?: Array<string>, perusteenDiaarinumero?: Array<string>, options?: any) {
        return OpetussuunnitelmatJulkisetApiFp(this.configuration).getAllJulkiset(koulutustyyppi, organisaatio, tyyppi, perusteenId, perusteenDiaarinumero, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatJulkisetApi
     */
    public getKaikkiJulkaistutOpetussuunnitelmat(options?: any) {
        return OpetussuunnitelmatJulkisetApiFp(this.configuration).getKaikkiJulkaistutOpetussuunnitelmat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [revision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatJulkisetApi
     */
    public getOpetussuunnitelmaJulkaistu(id: number, revision?: number, options?: any) {
        return OpetussuunnitelmatJulkisetApiFp(this.configuration).getOpetussuunnitelmaJulkaistu(id, revision, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpetussuunnitelmatJulkisetApi
     */
    public getOpetussuunnitelmanJulkisetTiedot(id: number, options?: any) {
        return OpetussuunnitelmatJulkisetApiFp(this.configuration).getOpetussuunnitelmanJulkisetTiedot(id, options)(this.axios, this.basePath);
    }

}


/**
 * OppiaineenVuosiluokatApi - axios parameter creator
 * @export
 */
export const OppiaineenVuosiluokatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenvuosiluokka(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOppiaineenvuosiluokka.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getOppiaineenvuosiluokka.');
            }
            // verify required parameter 'kokonaisuusId' is not null or undefined
            if (kokonaisuusId === null || kokonaisuusId === undefined) {
                throw new RequiredError('kokonaisuusId','Required parameter kokonaisuusId was null or undefined when calling getOppiaineenvuosiluokka.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineenvuosiluokka.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{kokonaisuusId}/vuosiluokat/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kokonaisuusId"}}`, encodeURIComponent(String(kokonaisuusId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {number} id 
         * @param {Array<OpetuksenTavoiteDto>} OpetuksenTavoiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValinnaisenVuosiluokanSisalto(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, OpetuksenTavoiteDto: Array<OpetuksenTavoiteDto>, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateValinnaisenVuosiluokanSisalto.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateValinnaisenVuosiluokanSisalto.');
            }
            // verify required parameter 'kokonaisuusId' is not null or undefined
            if (kokonaisuusId === null || kokonaisuusId === undefined) {
                throw new RequiredError('kokonaisuusId','Required parameter kokonaisuusId was null or undefined when calling updateValinnaisenVuosiluokanSisalto.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateValinnaisenVuosiluokanSisalto.');
            }
            // verify required parameter 'OpetuksenTavoiteDto' is not null or undefined
            if (OpetuksenTavoiteDto === null || OpetuksenTavoiteDto === undefined) {
                throw new RequiredError('OpetuksenTavoiteDto','Required parameter OpetuksenTavoiteDto was null or undefined when calling updateValinnaisenVuosiluokanSisalto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{kokonaisuusId}/vuosiluokat/{id}/valinnainen`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kokonaisuusId"}}`, encodeURIComponent(String(kokonaisuusId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OpetuksenTavoiteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OpetuksenTavoiteDto !== undefined ? OpetuksenTavoiteDto : {}) : (OpetuksenTavoiteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {number} id 
         * @param {OppiaineenVuosiluokkaDto} OppiaineenVuosiluokkaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokanSisalto(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, OppiaineenVuosiluokkaDto: OppiaineenVuosiluokkaDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateVuosiluokanSisalto.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateVuosiluokanSisalto.');
            }
            // verify required parameter 'kokonaisuusId' is not null or undefined
            if (kokonaisuusId === null || kokonaisuusId === undefined) {
                throw new RequiredError('kokonaisuusId','Required parameter kokonaisuusId was null or undefined when calling updateVuosiluokanSisalto.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateVuosiluokanSisalto.');
            }
            // verify required parameter 'OppiaineenVuosiluokkaDto' is not null or undefined
            if (OppiaineenVuosiluokkaDto === null || OppiaineenVuosiluokkaDto === undefined) {
                throw new RequiredError('OppiaineenVuosiluokkaDto','Required parameter OppiaineenVuosiluokkaDto was null or undefined when calling updateVuosiluokanSisalto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{kokonaisuusId}/vuosiluokat/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kokonaisuusId"}}`, encodeURIComponent(String(kokonaisuusId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppiaineenVuosiluokkaDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppiaineenVuosiluokkaDto !== undefined ? OppiaineenVuosiluokkaDto : {}) : (OppiaineenVuosiluokkaDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OppiaineenVuosiluokatApi - functional programming interface
 * @export
 */
export const OppiaineenVuosiluokatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenvuosiluokka(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineenVuosiluokkaDto> {
            const localVarAxiosArgs = OppiaineenVuosiluokatApiAxiosParamCreator(configuration).getOppiaineenvuosiluokka(opsId, oppiaineId, kokonaisuusId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {number} id 
         * @param {Array<OpetuksenTavoiteDto>} OpetuksenTavoiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValinnaisenVuosiluokanSisalto(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, OpetuksenTavoiteDto: Array<OpetuksenTavoiteDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineenVuosiluokkaDto> {
            const localVarAxiosArgs = OppiaineenVuosiluokatApiAxiosParamCreator(configuration).updateValinnaisenVuosiluokanSisalto(opsId, oppiaineId, kokonaisuusId, id, OpetuksenTavoiteDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {number} id 
         * @param {OppiaineenVuosiluokkaDto} OppiaineenVuosiluokkaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokanSisalto(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, OppiaineenVuosiluokkaDto: OppiaineenVuosiluokkaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineenVuosiluokkaDto> {
            const localVarAxiosArgs = OppiaineenVuosiluokatApiAxiosParamCreator(configuration).updateVuosiluokanSisalto(opsId, oppiaineId, kokonaisuusId, id, OppiaineenVuosiluokkaDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OppiaineenVuosiluokatApi - factory interface
 * @export
 */
export const OppiaineenVuosiluokatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenvuosiluokka(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, options?: any): AxiosPromise<OppiaineenVuosiluokkaDto> {
            return OppiaineenVuosiluokatApiFp(configuration).getOppiaineenvuosiluokka(opsId, oppiaineId, kokonaisuusId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {number} id 
         * @param {Array<OpetuksenTavoiteDto>} OpetuksenTavoiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValinnaisenVuosiluokanSisalto(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, OpetuksenTavoiteDto: Array<OpetuksenTavoiteDto>, options?: any): AxiosPromise<OppiaineenVuosiluokkaDto> {
            return OppiaineenVuosiluokatApiFp(configuration).updateValinnaisenVuosiluokanSisalto(opsId, oppiaineId, kokonaisuusId, id, OpetuksenTavoiteDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {number} id 
         * @param {OppiaineenVuosiluokkaDto} OppiaineenVuosiluokkaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokanSisalto(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, OppiaineenVuosiluokkaDto: OppiaineenVuosiluokkaDto, options?: any): AxiosPromise<OppiaineenVuosiluokkaDto> {
            return OppiaineenVuosiluokatApiFp(configuration).updateVuosiluokanSisalto(opsId, oppiaineId, kokonaisuusId, id, OppiaineenVuosiluokkaDto, options)(axios, basePath);
        },
    };
};

/**
 * OppiaineenVuosiluokatApi - object-oriented interface
 * @export
 * @class OppiaineenVuosiluokatApi
 * @extends {BaseAPI}
 */
export class OppiaineenVuosiluokatApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} kokonaisuusId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineenVuosiluokatApi
     */
    public getOppiaineenvuosiluokka(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, options?: any) {
        return OppiaineenVuosiluokatApiFp(this.configuration).getOppiaineenvuosiluokka(opsId, oppiaineId, kokonaisuusId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} kokonaisuusId 
     * @param {number} id 
     * @param {Array<OpetuksenTavoiteDto>} OpetuksenTavoiteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineenVuosiluokatApi
     */
    public updateValinnaisenVuosiluokanSisalto(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, OpetuksenTavoiteDto: Array<OpetuksenTavoiteDto>, options?: any) {
        return OppiaineenVuosiluokatApiFp(this.configuration).updateValinnaisenVuosiluokanSisalto(opsId, oppiaineId, kokonaisuusId, id, OpetuksenTavoiteDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} kokonaisuusId 
     * @param {number} id 
     * @param {OppiaineenVuosiluokkaDto} OppiaineenVuosiluokkaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineenVuosiluokatApi
     */
    public updateVuosiluokanSisalto(opsId: number, oppiaineId: number, kokonaisuusId: number, id: number, OppiaineenVuosiluokkaDto: OppiaineenVuosiluokkaDto, options?: any) {
        return OppiaineenVuosiluokatApiFp(this.configuration).updateVuosiluokanSisalto(opsId, oppiaineId, kokonaisuusId, id, OppiaineenVuosiluokkaDto, options)(this.axios, this.basePath);
    }

}


/**
 * OppiaineenVuosiluokkakokonaisuudetApi - axios parameter creator
 * @export
 */
export const OppiaineenVuosiluokkakokonaisuudetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto(opsId: number, oppiaineId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{id}/peruste`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkakokonaisuus(opsId: number, oppiaineId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOppiaineenVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getOppiaineenVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineenVuosiluokkakokonaisuus.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkienTavoitteet(opsId: number, oppiaineId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getVuosiluokkienTavoitteet.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getVuosiluokkienTavoitteet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVuosiluokkienTavoitteet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{id}/tavoitteet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {OppiaineenVuosiluokkakokonaisuusDto} OppiaineenVuosiluokkakokonaisuusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkakokonaisuudenSisalto(opsId: number, oppiaineId: number, id: number, OppiaineenVuosiluokkakokonaisuusDto: OppiaineenVuosiluokkakokonaisuusDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateVuosiluokkakokonaisuudenSisalto.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateVuosiluokkakokonaisuudenSisalto.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateVuosiluokkakokonaisuudenSisalto.');
            }
            // verify required parameter 'OppiaineenVuosiluokkakokonaisuusDto' is not null or undefined
            if (OppiaineenVuosiluokkakokonaisuusDto === null || OppiaineenVuosiluokkakokonaisuusDto === undefined) {
                throw new RequiredError('OppiaineenVuosiluokkakokonaisuusDto','Required parameter OppiaineenVuosiluokkakokonaisuusDto was null or undefined when calling updateVuosiluokkakokonaisuudenSisalto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppiaineenVuosiluokkakokonaisuusDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppiaineenVuosiluokkakokonaisuusDto !== undefined ? OppiaineenVuosiluokkakokonaisuusDto : {}) : (OppiaineenVuosiluokkakokonaisuusDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {{ [key: string]: Array<string>; }} request_body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkienTavoitteet(opsId: number, oppiaineId: number, id: number, request_body: { [key: string]: Array<string>; }, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateVuosiluokkienTavoitteet.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateVuosiluokkienTavoitteet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateVuosiluokkienTavoitteet.');
            }
            // verify required parameter 'request_body' is not null or undefined
            if (request_body === null || request_body === undefined) {
                throw new RequiredError('request_body','Required parameter request_body was null or undefined when calling updateVuosiluokkienTavoitteet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{id}/tavoitteet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof request_body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request_body !== undefined ? request_body : {}) : (request_body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OppiaineenVuosiluokkakokonaisuudetApi - functional programming interface
 * @export
 */
export const OppiaineenVuosiluokkakokonaisuudetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto(opsId: number, oppiaineId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteOppiaineenVuosiluokkakokonaisuusDto> {
            const localVarAxiosArgs = OppiaineenVuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto(opsId, oppiaineId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkakokonaisuus(opsId: number, oppiaineId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineenVuosiluokkakokonaisuusDto> {
            const localVarAxiosArgs = OppiaineenVuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).getOppiaineenVuosiluokkakokonaisuus(opsId, oppiaineId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkienTavoitteet(opsId: number, oppiaineId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }> {
            const localVarAxiosArgs = OppiaineenVuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).getVuosiluokkienTavoitteet(opsId, oppiaineId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {OppiaineenVuosiluokkakokonaisuusDto} OppiaineenVuosiluokkakokonaisuusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkakokonaisuudenSisalto(opsId: number, oppiaineId: number, id: number, OppiaineenVuosiluokkakokonaisuusDto: OppiaineenVuosiluokkakokonaisuusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineenVuosiluokkakokonaisuusDto> {
            const localVarAxiosArgs = OppiaineenVuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).updateVuosiluokkakokonaisuudenSisalto(opsId, oppiaineId, id, OppiaineenVuosiluokkakokonaisuusDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {{ [key: string]: Array<string>; }} request_body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkienTavoitteet(opsId: number, oppiaineId: number, id: number, request_body: { [key: string]: Array<string>; }, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OppiaineenVuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).updateVuosiluokkienTavoitteet(opsId, oppiaineId, id, request_body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OppiaineenVuosiluokkakokonaisuudetApi - factory interface
 * @export
 */
export const OppiaineenVuosiluokkakokonaisuudetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto(opsId: number, oppiaineId: number, id: number, options?: any): AxiosPromise<PerusteOppiaineenVuosiluokkakokonaisuusDto> {
            return OppiaineenVuosiluokkakokonaisuudetApiFp(configuration).getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto(opsId, oppiaineId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkakokonaisuus(opsId: number, oppiaineId: number, id: number, options?: any): AxiosPromise<OppiaineenVuosiluokkakokonaisuusDto> {
            return OppiaineenVuosiluokkakokonaisuudetApiFp(configuration).getOppiaineenVuosiluokkakokonaisuus(opsId, oppiaineId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkienTavoitteet(opsId: number, oppiaineId: number, id: number, options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return OppiaineenVuosiluokkakokonaisuudetApiFp(configuration).getVuosiluokkienTavoitteet(opsId, oppiaineId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {OppiaineenVuosiluokkakokonaisuusDto} OppiaineenVuosiluokkakokonaisuusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkakokonaisuudenSisalto(opsId: number, oppiaineId: number, id: number, OppiaineenVuosiluokkakokonaisuusDto: OppiaineenVuosiluokkakokonaisuusDto, options?: any): AxiosPromise<OppiaineenVuosiluokkakokonaisuusDto> {
            return OppiaineenVuosiluokkakokonaisuudetApiFp(configuration).updateVuosiluokkakokonaisuudenSisalto(opsId, oppiaineId, id, OppiaineenVuosiluokkakokonaisuusDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {{ [key: string]: Array<string>; }} request_body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkienTavoitteet(opsId: number, oppiaineId: number, id: number, request_body: { [key: string]: Array<string>; }, options?: any): AxiosPromise<void> {
            return OppiaineenVuosiluokkakokonaisuudetApiFp(configuration).updateVuosiluokkienTavoitteet(opsId, oppiaineId, id, request_body, options)(axios, basePath);
        },
    };
};

/**
 * OppiaineenVuosiluokkakokonaisuudetApi - object-oriented interface
 * @export
 * @class OppiaineenVuosiluokkakokonaisuudetApi
 * @extends {BaseAPI}
 */
export class OppiaineenVuosiluokkakokonaisuudetApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineenVuosiluokkakokonaisuudetApi
     */
    public getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto(opsId: number, oppiaineId: number, id: number, options?: any) {
        return OppiaineenVuosiluokkakokonaisuudetApiFp(this.configuration).getOppiaineenVuosiluokkakokonaisuudenPerusteSisalto(opsId, oppiaineId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineenVuosiluokkakokonaisuudetApi
     */
    public getOppiaineenVuosiluokkakokonaisuus(opsId: number, oppiaineId: number, id: number, options?: any) {
        return OppiaineenVuosiluokkakokonaisuudetApiFp(this.configuration).getOppiaineenVuosiluokkakokonaisuus(opsId, oppiaineId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineenVuosiluokkakokonaisuudetApi
     */
    public getVuosiluokkienTavoitteet(opsId: number, oppiaineId: number, id: number, options?: any) {
        return OppiaineenVuosiluokkakokonaisuudetApiFp(this.configuration).getVuosiluokkienTavoitteet(opsId, oppiaineId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} id 
     * @param {OppiaineenVuosiluokkakokonaisuusDto} OppiaineenVuosiluokkakokonaisuusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineenVuosiluokkakokonaisuudetApi
     */
    public updateVuosiluokkakokonaisuudenSisalto(opsId: number, oppiaineId: number, id: number, OppiaineenVuosiluokkakokonaisuusDto: OppiaineenVuosiluokkakokonaisuusDto, options?: any) {
        return OppiaineenVuosiluokkakokonaisuudetApiFp(this.configuration).updateVuosiluokkakokonaisuudenSisalto(opsId, oppiaineId, id, OppiaineenVuosiluokkakokonaisuusDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} id 
     * @param {{ [key: string]: Array<string>; }} request_body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineenVuosiluokkakokonaisuudetApi
     */
    public updateVuosiluokkienTavoitteet(opsId: number, oppiaineId: number, id: number, request_body: { [key: string]: Array<string>; }, options?: any) {
        return OppiaineenVuosiluokkakokonaisuudetApiFp(this.configuration).updateVuosiluokkienTavoitteet(opsId, oppiaineId, id, request_body, options)(this.axios, this.basePath);
    }

}


/**
 * OppiaineetApi - axios parameter creator
 * @export
 */
export const OppiaineetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {OppiaineDto} OppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaine(opsId: number, OppiaineDto: OppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addOppiaine.');
            }
            // verify required parameter 'OppiaineDto' is not null or undefined
            if (OppiaineDto === null || OppiaineDto === undefined) {
                throw new RequiredError('OppiaineDto','Required parameter OppiaineDto was null or undefined when calling addOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppiaineDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppiaineDto !== undefined ? OppiaineDto : {}) : (OppiaineDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {KopioOppimaaraDto} KopioOppimaaraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppimaara(opsId: number, id: number, KopioOppimaaraDto: KopioOppimaaraDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addOppimaara.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addOppimaara.');
            }
            // verify required parameter 'KopioOppimaaraDto' is not null or undefined
            if (KopioOppimaaraDto === null || KopioOppimaaraDto === undefined) {
                throw new RequiredError('KopioOppimaaraDto','Required parameter KopioOppimaaraDto was null or undefined when calling addOppimaara.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/oppimaara`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof KopioOppimaaraDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(KopioOppimaaraDto !== undefined ? KopioOppimaaraDto : {}) : (KopioOppimaaraDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addValinnainen(opsId: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addValinnainen.');
            }
            // verify required parameter 'OppiaineenTallennusDto' is not null or undefined
            if (OppiaineenTallennusDto === null || OppiaineenTallennusDto === undefined) {
                throw new RequiredError('OppiaineenTallennusDto','Required parameter OppiaineenTallennusDto was null or undefined when calling addValinnainen.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/valinnainen`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppiaineenTallennusDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppiaineenTallennusDto !== undefined ? OppiaineenTallennusDto : {}) : (OppiaineenTallennusDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaine(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling deleteOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {'YHTEINEN' | 'TAIDE_TAITOAINE' | 'MUU_VALINNAINEN' | 'LUKIO'} [tyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppiaineet(opsId: number, tyyppi?: 'YHTEINEN' | 'TAIDE_TAITOAINE' | 'MUU_VALINNAINEN' | 'LUKIO', options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllOppiaineet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaine(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineParent(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOppiaineParent.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineParent.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/parent`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineRevision(opsId: number, id: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOppiaineRevision.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineRevision.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling getOppiaineRevision.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/raakaversio/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineVersion(opsId: number, id: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOppiaineVersion.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineVersion.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling getOppiaineVersion.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/versio/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineVersionHistory(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getOppiaineVersionHistory.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineVersionHistory.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/versiot`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteSisalto(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPerusteSisalto.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteSisalto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/peruste`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjanVastaavaOppiaine(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPohjanVastaavaOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPohjanVastaavaOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/pohja`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemovedOppiaineet(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getRemovedOppiaineet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/poistetut`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValinnaiset(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getValinnaiset.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/valinnaiset`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYhteiset(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getYhteiset.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/yhteiset`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {boolean} [asetaPohjanOppiaine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiMuokattavaksi(opsId: number, id: number, asetaPohjanOppiaine?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling kopioiMuokattavaksi.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling kopioiMuokattavaksi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/muokattavakopio`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (asetaPohjanOppiaine !== undefined) {
                localVarQueryParameter['asetaPohjanOppiaine'] = asetaPohjanOppiaine;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaYlempi(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling palautaYlempi.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling palautaYlempi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/palautaYlempi`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOppiaine(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling restoreOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling restoreOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/palauta`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} oppimaaraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOppiaineOppimaara(opsId: number, id: number, oppimaaraId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling restoreOppiaineOppimaara.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling restoreOppiaineOppimaara.');
            }
            // verify required parameter 'oppimaaraId' is not null or undefined
            if (oppimaaraId === null || oppimaaraId === undefined) {
                throw new RequiredError('oppimaaraId','Required parameter oppimaaraId was null or undefined when calling restoreOppiaineOppimaara.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/palauta/{oppimaaraId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"oppimaaraId"}}`, encodeURIComponent(String(oppimaaraId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} oppimaaraId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOppiaineOppimaaraVersio(opsId: number, id: number, oppimaaraId: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling restoreOppiaineOppimaaraVersio.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling restoreOppiaineOppimaaraVersio.');
            }
            // verify required parameter 'oppimaaraId' is not null or undefined
            if (oppimaaraId === null || oppimaaraId === undefined) {
                throw new RequiredError('oppimaaraId','Required parameter oppimaaraId was null or undefined when calling restoreOppiaineOppimaaraVersio.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling restoreOppiaineOppimaaraVersio.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/palauta/{oppimaaraId}/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"oppimaaraId"}}`, encodeURIComponent(String(oppimaaraId)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertOppiaineToVersion(opsId: number, id: number, versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling revertOppiaineToVersion.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling revertOppiaineToVersion.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling revertOppiaineToVersion.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/versio/{versio}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {OppiaineDto} OppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaine(opsId: number, id: number, OppiaineDto: OppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOppiaine.');
            }
            // verify required parameter 'OppiaineDto' is not null or undefined
            if (OppiaineDto === null || OppiaineDto === undefined) {
                throw new RequiredError('OppiaineDto','Required parameter OppiaineDto was null or undefined when calling updateOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppiaineDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppiaineDto !== undefined ? OppiaineDto : {}) : (OppiaineDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} id 
         * @param {OppiaineDto} OppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineWithVlk(opsId: number, vlkId: number, id: number, OppiaineDto: OppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateOppiaineWithVlk.');
            }
            // verify required parameter 'vlkId' is not null or undefined
            if (vlkId === null || vlkId === undefined) {
                throw new RequiredError('vlkId','Required parameter vlkId was null or undefined when calling updateOppiaineWithVlk.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOppiaineWithVlk.');
            }
            // verify required parameter 'OppiaineDto' is not null or undefined
            if (OppiaineDto === null || OppiaineDto === undefined) {
                throw new RequiredError('OppiaineDto','Required parameter OppiaineDto was null or undefined when calling updateOppiaineWithVlk.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/vlk/{vlkId}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"vlkId"}}`, encodeURIComponent(String(vlkId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppiaineDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppiaineDto !== undefined ? OppiaineDto : {}) : (OppiaineDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValinnainen(opsId: number, id: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateValinnainen.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateValinnainen.');
            }
            // verify required parameter 'OppiaineenTallennusDto' is not null or undefined
            if (OppiaineenTallennusDto === null || OppiaineenTallennusDto === undefined) {
                throw new RequiredError('OppiaineenTallennusDto','Required parameter OppiaineenTallennusDto was null or undefined when calling updateValinnainen.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/valinnainen`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppiaineenTallennusDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppiaineenTallennusDto !== undefined ? OppiaineenTallennusDto : {}) : (OppiaineenTallennusDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYksinkertainen(opsId: number, id: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateYksinkertainen.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateYksinkertainen.');
            }
            // verify required parameter 'OppiaineenTallennusDto' is not null or undefined
            if (OppiaineenTallennusDto === null || OppiaineenTallennusDto === undefined) {
                throw new RequiredError('OppiaineenTallennusDto','Required parameter OppiaineenTallennusDto was null or undefined when calling updateYksinkertainen.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/oppiaineet/{id}/yksinkertainen`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof OppiaineenTallennusDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(OppiaineenTallennusDto !== undefined ? OppiaineenTallennusDto : {}) : (OppiaineenTallennusDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OppiaineetApi - functional programming interface
 * @export
 */
export const OppiaineetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {OppiaineDto} OppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaine(opsId: number, OppiaineDto: OppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).addOppiaine(opsId, OppiaineDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {KopioOppimaaraDto} KopioOppimaaraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppimaara(opsId: number, id: number, KopioOppimaaraDto: KopioOppimaaraDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).addOppimaara(opsId, id, KopioOppimaaraDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addValinnainen(opsId: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).addValinnainen(opsId, OppiaineenTallennusDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaine(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).deleteOppiaine(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {'YHTEINEN' | 'TAIDE_TAITOAINE' | 'MUU_VALINNAINEN' | 'LUKIO'} [tyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppiaineet(opsId: number, tyyppi?: 'YHTEINEN' | 'TAIDE_TAITOAINE' | 'MUU_VALINNAINEN' | 'LUKIO', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OppiaineDto>> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getAllOppiaineet(opsId, tyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaine(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsOppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getOppiaine(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineParent(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getOppiaineParent(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineRevision(opsId: number, id: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getOppiaineRevision(opsId, id, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineVersion(opsId: number, id: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsOppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getOppiaineVersion(opsId, id, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineVersionHistory(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RevisionDto>> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getOppiaineVersionHistory(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteSisalto(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteOppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getPerusteSisalto(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjanVastaavaOppiaine(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getPohjanVastaavaOppiaine(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemovedOppiaineet(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PoistettuOppiaineDto>> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getRemovedOppiaineet(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValinnaiset(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OppiaineDto>> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getValinnaiset(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYhteiset(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OppiaineDto>> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).getYhteiset(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {boolean} [asetaPohjanOppiaine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiMuokattavaksi(opsId: number, id: number, asetaPohjanOppiaine?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsOppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).kopioiMuokattavaksi(opsId, id, asetaPohjanOppiaine, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaYlempi(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsOppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).palautaYlempi(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOppiaine(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiainePalautettuDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).restoreOppiaine(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} oppimaaraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOppiaineOppimaara(opsId: number, id: number, oppimaaraId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiainePalautettuDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).restoreOppiaineOppimaara(opsId, id, oppimaaraId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} oppimaaraId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOppiaineOppimaaraVersio(opsId: number, id: number, oppimaaraId: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiainePalautettuDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).restoreOppiaineOppimaaraVersio(opsId, id, oppimaaraId, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertOppiaineToVersion(opsId: number, id: number, versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsOppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).revertOppiaineToVersion(opsId, id, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {OppiaineDto} OppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaine(opsId: number, id: number, OppiaineDto: OppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsOppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).updateOppiaine(opsId, id, OppiaineDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} id 
         * @param {OppiaineDto} OppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineWithVlk(opsId: number, vlkId: number, id: number, OppiaineDto: OppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsOppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).updateOppiaineWithVlk(opsId, vlkId, id, OppiaineDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValinnainen(opsId: number, id: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).updateValinnainen(opsId, id, OppiaineenTallennusDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYksinkertainen(opsId: number, id: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = OppiaineetApiAxiosParamCreator(configuration).updateYksinkertainen(opsId, id, OppiaineenTallennusDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OppiaineetApi - factory interface
 * @export
 */
export const OppiaineetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {OppiaineDto} OppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaine(opsId: number, OppiaineDto: OppiaineDto, options?: any): AxiosPromise<OppiaineDto> {
            return OppiaineetApiFp(configuration).addOppiaine(opsId, OppiaineDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {KopioOppimaaraDto} KopioOppimaaraDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppimaara(opsId: number, id: number, KopioOppimaaraDto: KopioOppimaaraDto, options?: any): AxiosPromise<OppiaineDto> {
            return OppiaineetApiFp(configuration).addOppimaara(opsId, id, KopioOppimaaraDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addValinnainen(opsId: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options?: any): AxiosPromise<OppiaineDto> {
            return OppiaineetApiFp(configuration).addValinnainen(opsId, OppiaineenTallennusDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaine(opsId: number, id: number, options?: any): AxiosPromise<void> {
            return OppiaineetApiFp(configuration).deleteOppiaine(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {'YHTEINEN' | 'TAIDE_TAITOAINE' | 'MUU_VALINNAINEN' | 'LUKIO'} [tyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppiaineet(opsId: number, tyyppi?: 'YHTEINEN' | 'TAIDE_TAITOAINE' | 'MUU_VALINNAINEN' | 'LUKIO', options?: any): AxiosPromise<Array<OppiaineDto>> {
            return OppiaineetApiFp(configuration).getAllOppiaineet(opsId, tyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaine(opsId: number, id: number, options?: any): AxiosPromise<UnwrappedOpsOppiaineDto> {
            return OppiaineetApiFp(configuration).getOppiaine(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineParent(opsId: number, id: number, options?: any): AxiosPromise<OppiaineDto> {
            return OppiaineetApiFp(configuration).getOppiaineParent(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineRevision(opsId: number, id: number, versio: number, options?: any): AxiosPromise<OppiaineDto> {
            return OppiaineetApiFp(configuration).getOppiaineRevision(opsId, id, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineVersion(opsId: number, id: number, versio: number, options?: any): AxiosPromise<UnwrappedOpsOppiaineDto> {
            return OppiaineetApiFp(configuration).getOppiaineVersion(opsId, id, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineVersionHistory(opsId: number, id: number, options?: any): AxiosPromise<Array<RevisionDto>> {
            return OppiaineetApiFp(configuration).getOppiaineVersionHistory(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteSisalto(opsId: number, id: number, options?: any): AxiosPromise<PerusteOppiaineDto> {
            return OppiaineetApiFp(configuration).getPerusteSisalto(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjanVastaavaOppiaine(opsId: number, id: number, options?: any): AxiosPromise<OppiaineDto> {
            return OppiaineetApiFp(configuration).getPohjanVastaavaOppiaine(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemovedOppiaineet(opsId: number, options?: any): AxiosPromise<Array<PoistettuOppiaineDto>> {
            return OppiaineetApiFp(configuration).getRemovedOppiaineet(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValinnaiset(opsId: number, options?: any): AxiosPromise<Array<OppiaineDto>> {
            return OppiaineetApiFp(configuration).getValinnaiset(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYhteiset(opsId: number, options?: any): AxiosPromise<Array<OppiaineDto>> {
            return OppiaineetApiFp(configuration).getYhteiset(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {boolean} [asetaPohjanOppiaine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiMuokattavaksi(opsId: number, id: number, asetaPohjanOppiaine?: boolean, options?: any): AxiosPromise<UnwrappedOpsOppiaineDto> {
            return OppiaineetApiFp(configuration).kopioiMuokattavaksi(opsId, id, asetaPohjanOppiaine, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaYlempi(opsId: number, id: number, options?: any): AxiosPromise<UnwrappedOpsOppiaineDto> {
            return OppiaineetApiFp(configuration).palautaYlempi(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOppiaine(opsId: number, id: number, options?: any): AxiosPromise<OppiainePalautettuDto> {
            return OppiaineetApiFp(configuration).restoreOppiaine(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} oppimaaraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOppiaineOppimaara(opsId: number, id: number, oppimaaraId: number, options?: any): AxiosPromise<OppiainePalautettuDto> {
            return OppiaineetApiFp(configuration).restoreOppiaineOppimaara(opsId, id, oppimaaraId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} oppimaaraId 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreOppiaineOppimaaraVersio(opsId: number, id: number, oppimaaraId: number, versio: number, options?: any): AxiosPromise<OppiainePalautettuDto> {
            return OppiaineetApiFp(configuration).restoreOppiaineOppimaaraVersio(opsId, id, oppimaaraId, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertOppiaineToVersion(opsId: number, id: number, versio: number, options?: any): AxiosPromise<UnwrappedOpsOppiaineDto> {
            return OppiaineetApiFp(configuration).revertOppiaineToVersion(opsId, id, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {OppiaineDto} OppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaine(opsId: number, id: number, OppiaineDto: OppiaineDto, options?: any): AxiosPromise<UnwrappedOpsOppiaineDto> {
            return OppiaineetApiFp(configuration).updateOppiaine(opsId, id, OppiaineDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} vlkId 
         * @param {number} id 
         * @param {OppiaineDto} OppiaineDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineWithVlk(opsId: number, vlkId: number, id: number, OppiaineDto: OppiaineDto, options?: any): AxiosPromise<UnwrappedOpsOppiaineDto> {
            return OppiaineetApiFp(configuration).updateOppiaineWithVlk(opsId, vlkId, id, OppiaineDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValinnainen(opsId: number, id: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options?: any): AxiosPromise<OppiaineDto> {
            return OppiaineetApiFp(configuration).updateValinnainen(opsId, id, OppiaineenTallennusDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYksinkertainen(opsId: number, id: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options?: any): AxiosPromise<OppiaineDto> {
            return OppiaineetApiFp(configuration).updateYksinkertainen(opsId, id, OppiaineenTallennusDto, options)(axios, basePath);
        },
    };
};

/**
 * OppiaineetApi - object-oriented interface
 * @export
 * @class OppiaineetApi
 * @extends {BaseAPI}
 */
export class OppiaineetApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {OppiaineDto} OppiaineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public addOppiaine(opsId: number, OppiaineDto: OppiaineDto, options?: any) {
        return OppiaineetApiFp(this.configuration).addOppiaine(opsId, OppiaineDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {KopioOppimaaraDto} KopioOppimaaraDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public addOppimaara(opsId: number, id: number, KopioOppimaaraDto: KopioOppimaaraDto, options?: any) {
        return OppiaineetApiFp(this.configuration).addOppimaara(opsId, id, KopioOppimaaraDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public addValinnainen(opsId: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options?: any) {
        return OppiaineetApiFp(this.configuration).addValinnainen(opsId, OppiaineenTallennusDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public deleteOppiaine(opsId: number, id: number, options?: any) {
        return OppiaineetApiFp(this.configuration).deleteOppiaine(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {'YHTEINEN' | 'TAIDE_TAITOAINE' | 'MUU_VALINNAINEN' | 'LUKIO'} [tyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getAllOppiaineet(opsId: number, tyyppi?: 'YHTEINEN' | 'TAIDE_TAITOAINE' | 'MUU_VALINNAINEN' | 'LUKIO', options?: any) {
        return OppiaineetApiFp(this.configuration).getAllOppiaineet(opsId, tyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getOppiaine(opsId: number, id: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getOppiaine(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getOppiaineParent(opsId: number, id: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getOppiaineParent(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getOppiaineRevision(opsId: number, id: number, versio: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getOppiaineRevision(opsId, id, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getOppiaineVersion(opsId: number, id: number, versio: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getOppiaineVersion(opsId, id, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getOppiaineVersionHistory(opsId: number, id: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getOppiaineVersionHistory(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getPerusteSisalto(opsId: number, id: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getPerusteSisalto(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getPohjanVastaavaOppiaine(opsId: number, id: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getPohjanVastaavaOppiaine(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getRemovedOppiaineet(opsId: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getRemovedOppiaineet(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getValinnaiset(opsId: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getValinnaiset(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public getYhteiset(opsId: number, options?: any) {
        return OppiaineetApiFp(this.configuration).getYhteiset(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {boolean} [asetaPohjanOppiaine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public kopioiMuokattavaksi(opsId: number, id: number, asetaPohjanOppiaine?: boolean, options?: any) {
        return OppiaineetApiFp(this.configuration).kopioiMuokattavaksi(opsId, id, asetaPohjanOppiaine, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public palautaYlempi(opsId: number, id: number, options?: any) {
        return OppiaineetApiFp(this.configuration).palautaYlempi(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public restoreOppiaine(opsId: number, id: number, options?: any) {
        return OppiaineetApiFp(this.configuration).restoreOppiaine(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {number} oppimaaraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public restoreOppiaineOppimaara(opsId: number, id: number, oppimaaraId: number, options?: any) {
        return OppiaineetApiFp(this.configuration).restoreOppiaineOppimaara(opsId, id, oppimaaraId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {number} oppimaaraId 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public restoreOppiaineOppimaaraVersio(opsId: number, id: number, oppimaaraId: number, versio: number, options?: any) {
        return OppiaineetApiFp(this.configuration).restoreOppiaineOppimaaraVersio(opsId, id, oppimaaraId, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public revertOppiaineToVersion(opsId: number, id: number, versio: number, options?: any) {
        return OppiaineetApiFp(this.configuration).revertOppiaineToVersion(opsId, id, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {OppiaineDto} OppiaineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public updateOppiaine(opsId: number, id: number, OppiaineDto: OppiaineDto, options?: any) {
        return OppiaineetApiFp(this.configuration).updateOppiaine(opsId, id, OppiaineDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} vlkId 
     * @param {number} id 
     * @param {OppiaineDto} OppiaineDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public updateOppiaineWithVlk(opsId: number, vlkId: number, id: number, OppiaineDto: OppiaineDto, options?: any) {
        return OppiaineetApiFp(this.configuration).updateOppiaineWithVlk(opsId, vlkId, id, OppiaineDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public updateValinnainen(opsId: number, id: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options?: any) {
        return OppiaineetApiFp(this.configuration).updateValinnainen(opsId, id, OppiaineenTallennusDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {OppiaineenTallennusDto} OppiaineenTallennusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppiaineetApi
     */
    public updateYksinkertainen(opsId: number, id: number, OppiaineenTallennusDto: OppiaineenTallennusDto, options?: any) {
        return OppiaineetApiFp(this.configuration).updateYksinkertainen(opsId, id, OppiaineenTallennusDto, options)(this.axios, this.basePath);
    }

}


/**
 * TermistoApi - axios parameter creator
 * @export
 */
export const TermistoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {TermiDto} TermiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermi(opsId: number, TermiDto: TermiDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling addTermi.');
            }
            // verify required parameter 'TermiDto' is not null or undefined
            if (TermiDto === null || TermiDto === undefined) {
                throw new RequiredError('TermiDto','Required parameter TermiDto was null or undefined when calling addTermi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/termisto`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof TermiDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(TermiDto !== undefined ? TermiDto : {}) : (TermiDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermi(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling deleteTermi.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTermi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/termisto/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTermit(opsId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getAllTermit.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/termisto`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} avain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermi(opsId: number, avain: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getTermi.');
            }
            // verify required parameter 'avain' is not null or undefined
            if (avain === null || avain === undefined) {
                throw new RequiredError('avain','Required parameter avain was null or undefined when calling getTermi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/termi/{avain}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"avain"}}`, encodeURIComponent(String(avain)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {TermiDto} TermiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermi(opsId: number, id: number, TermiDto: TermiDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateTermi.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTermi.');
            }
            // verify required parameter 'TermiDto' is not null or undefined
            if (TermiDto === null || TermiDto === undefined) {
                throw new RequiredError('TermiDto','Required parameter TermiDto was null or undefined when calling updateTermi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/termisto/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof TermiDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(TermiDto !== undefined ? TermiDto : {}) : (TermiDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TermistoApi - functional programming interface
 * @export
 */
export const TermistoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {TermiDto} TermiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermi(opsId: number, TermiDto: TermiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermiDto> {
            const localVarAxiosArgs = TermistoApiAxiosParamCreator(configuration).addTermi(opsId, TermiDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermi(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TermistoApiAxiosParamCreator(configuration).deleteTermi(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTermit(opsId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TermiDto>> {
            const localVarAxiosArgs = TermistoApiAxiosParamCreator(configuration).getAllTermit(opsId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} avain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermi(opsId: number, avain: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermiDto> {
            const localVarAxiosArgs = TermistoApiAxiosParamCreator(configuration).getTermi(opsId, avain, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {TermiDto} TermiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermi(opsId: number, id: number, TermiDto: TermiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermiDto> {
            const localVarAxiosArgs = TermistoApiAxiosParamCreator(configuration).updateTermi(opsId, id, TermiDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TermistoApi - factory interface
 * @export
 */
export const TermistoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {TermiDto} TermiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermi(opsId: number, TermiDto: TermiDto, options?: any): AxiosPromise<TermiDto> {
            return TermistoApiFp(configuration).addTermi(opsId, TermiDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermi(opsId: number, id: number, options?: any): AxiosPromise<void> {
            return TermistoApiFp(configuration).deleteTermi(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTermit(opsId: number, options?: any): AxiosPromise<Array<TermiDto>> {
            return TermistoApiFp(configuration).getAllTermit(opsId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} avain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermi(opsId: number, avain: string, options?: any): AxiosPromise<TermiDto> {
            return TermistoApiFp(configuration).getTermi(opsId, avain, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {TermiDto} TermiDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermi(opsId: number, id: number, TermiDto: TermiDto, options?: any): AxiosPromise<TermiDto> {
            return TermistoApiFp(configuration).updateTermi(opsId, id, TermiDto, options)(axios, basePath);
        },
    };
};

/**
 * TermistoApi - object-oriented interface
 * @export
 * @class TermistoApi
 * @extends {BaseAPI}
 */
export class TermistoApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {TermiDto} TermiDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermistoApi
     */
    public addTermi(opsId: number, TermiDto: TermiDto, options?: any) {
        return TermistoApiFp(this.configuration).addTermi(opsId, TermiDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermistoApi
     */
    public deleteTermi(opsId: number, id: number, options?: any) {
        return TermistoApiFp(this.configuration).deleteTermi(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermistoApi
     */
    public getAllTermit(opsId: number, options?: any) {
        return TermistoApiFp(this.configuration).getAllTermit(opsId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} avain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermistoApi
     */
    public getTermi(opsId: number, avain: string, options?: any) {
        return TermistoApiFp(this.configuration).getTermi(opsId, avain, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {TermiDto} TermiDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermistoApi
     */
    public updateTermi(opsId: number, id: number, TermiDto: TermiDto, options?: any) {
        return TermistoApiFp(this.configuration).updateTermi(opsId, id, TermiDto, options)(this.axios, this.basePath);
    }

}


/**
 * UlkopuolisetApi - axios parameter creator
 * @export
 */
export const UlkopuolisetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanTiedot(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getKayttajanTiedot.');
            }
            const localVarPath = `/api/ulkopuoliset/kayttajatiedot/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [kunta] 
         * @param {Array<string>} [oppilaitostyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijat(kunta?: Array<string>, oppilaitostyyppi?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/organisaatiot/koulutustoimijat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kunta) {
                localVarQueryParameter['kunta'] = kunta;
            }

            if (oppilaitostyyppi) {
                localVarQueryParameter['oppilaitostyyppi'] = oppilaitostyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLokalisoinnit(options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/lokalisoinnit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiokoulutusperuste(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLukiokoulutusperuste.');
            }
            const localVarPath = `/api/ulkopuoliset/lukiokoulutusperusteet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiokoulutusperusteet(options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/lukiokoulutusperusteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} kuntaIdt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiot(kuntaIdt: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'kuntaIdt' is not null or undefined
            if (kuntaIdt === null || kuntaIdt === undefined) {
                throw new RequiredError('kuntaIdt','Required parameter kuntaIdt was null or undefined when calling getLukiot.');
            }
            const localVarPath = `/api/ulkopuoliset/organisaatiot/lukiotoimijat/{kuntaIdt}`
                .replace(`{${"kuntaIdt"}}`, encodeURIComponent(String(kuntaIdt)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kuntaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiotByKuntaId(kuntaId: string, options: any = {}): RequestArgs {
            // verify required parameter 'kuntaId' is not null or undefined
            if (kuntaId === null || kuntaId === undefined) {
                throw new RequiredError('kuntaId','Required parameter kuntaId was null or undefined when calling getLukiotByKuntaId.');
            }
            const localVarPath = `/api/ulkopuoliset/organisaatiot/lukiot/{kuntaId}`
                .replace(`{${"kuntaId"}}`, encodeURIComponent(String(kuntaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiotByOid(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getLukiotByOid.');
            }
            const localVarPath = `/api/ulkopuoliset/organisaatiot/lukiot/oid/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} julkaisuhetki 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanJulkaisuhetkenPeruste(id: number, julkaisuhetki: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOpetussuunnitelmanJulkaisuhetkenPeruste.');
            }
            // verify required parameter 'julkaisuhetki' is not null or undefined
            if (julkaisuhetki === null || julkaisuhetki === undefined) {
                throw new RequiredError('julkaisuhetki','Required parameter julkaisuhetki was null or undefined when calling getOpetussuunnitelmanJulkaisuhetkenPeruste.');
            }
            const localVarPath = `/api/ulkopuoliset/peruste/{id}/julkaisuhetki/{julkaisuhetki}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"julkaisuhetki"}}`, encodeURIComponent(String(julkaisuhetki)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatio(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getOrganisaatio.');
            }
            const localVarPath = `/api/ulkopuoliset/organisaatiot/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioRyhmat(options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/organisaatioryhmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary virkailijoiden haku
         * @param {Array<string>} [oid] organisaation oid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioVirkailijat(oid?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/organisaatiot/virkailijat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (oid) {
                localVarQueryParameter['oid'] = oid;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} kuntaIdt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruskoulut(kuntaIdt: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'kuntaIdt' is not null or undefined
            if (kuntaIdt === null || kuntaIdt === undefined) {
                throw new RequiredError('kuntaIdt','Required parameter kuntaIdt was null or undefined when calling getPeruskoulut.');
            }
            const localVarPath = `/api/ulkopuoliset/organisaatiot/peruskoulutoimijat/{kuntaIdt}`
                .replace(`{${"kuntaIdt"}}`, encodeURIComponent(String(kuntaIdt)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kuntaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruskoulutByKuntaId(kuntaId: string, options: any = {}): RequestArgs {
            // verify required parameter 'kuntaId' is not null or undefined
            if (kuntaId === null || kuntaId === undefined) {
                throw new RequiredError('kuntaId','Required parameter kuntaId was null or undefined when calling getPeruskoulutByKuntaId.');
            }
            const localVarPath = `/api/ulkopuoliset/organisaatiot/peruskoulut/{kuntaId}`
                .replace(`{${"kuntaId"}}`, encodeURIComponent(String(kuntaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruskoulutByOid(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getPeruskoulutByOid.');
            }
            const localVarPath = `/api/ulkopuoliset/organisaatiot/peruskoulut/oid/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusperuste(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusopetusperuste.');
            }
            const localVarPath = `/api/ulkopuoliset/perusopetusperusteet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusperusteet(options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/perusopetusperusteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteet(options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/julkaistutperusteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [jalkeen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteet(jalkeen?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/tiedotteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jalkeen !== undefined) {
                localVarQueryParameter['jalkeen'] = jalkeen;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary tiedotteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {Array<string>} [kieli] tiedotteen kieli
         * @param {string} [nimi] hae nimellä
         * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
         * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
         * @param {boolean} [julkinen] hae julkiset tiedotteet
         * @param {boolean} [yleinen] hae yleiset tiedotteet
         * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
         * @param {Array<number>} [perusteIds] tiedotteen perusteiden
         * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
         * @param {string} [jarjestys] tiedotteen jarjestys
         * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteetHaku(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, tiedoteJulkaisuPaikka?: Array<string>, perusteIds?: Array<number>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/tiedotteet/haku`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (kieli) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (perusteeton !== undefined) {
                localVarQueryParameter['perusteeton'] = perusteeton;
            }

            if (julkinen !== undefined) {
                localVarQueryParameter['julkinen'] = julkinen;
            }

            if (yleinen !== undefined) {
                localVarQueryParameter['yleinen'] = yleinen;
            }

            if (tiedoteJulkaisuPaikka) {
                localVarQueryParameter['tiedoteJulkaisuPaikka'] = tiedoteJulkaisuPaikka;
            }

            if (perusteIds) {
                localVarQueryParameter['perusteIds'] = perusteIds;
            }

            if (koulutusTyyppi) {
                localVarQueryParameter['koulutusTyyppi'] = koulutusTyyppi;
            }

            if (jarjestys !== undefined) {
                localVarQueryParameter['jarjestys'] = jarjestys;
            }

            if (jarjestysNouseva !== undefined) {
                localVarQueryParameter['jarjestysNouseva'] = jarjestysNouseva;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrganisations(options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/organisaatiot`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYlopsPeruste(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getYlopsPeruste.');
            }
            const localVarPath = `/api/ulkopuoliset/peruste/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikkiKoodistonKoodit(koodisto: string, haku?: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling kaikkiKoodistonKoodit.');
            }
            const localVarPath = `/api/ulkopuoliset/koodisto/{koodisto}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (haku !== undefined) {
                localVarQueryParameter['haku'] = haku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        koodinAlarelaatiot(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling koodinAlarelaatiot.');
            }
            const localVarPath = `/api/ulkopuoliset/koodisto/relaatio/sisaltyy-alakoodit/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        koodinYlarelaatiot(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling koodinYlarelaatiot.');
            }
            const localVarPath = `/api/ulkopuoliset/koodisto/relaatio/sisaltyy-ylakoodit/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yksiKoodistokoodi(koodisto: string, koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling yksiKoodistokoodi.');
            }
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling yksiKoodistokoodi.');
            }
            const localVarPath = `/api/ulkopuoliset/koodisto/{koodisto}/{koodi}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)))
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UlkopuolisetApi - functional programming interface
 * @export
 */
export const UlkopuolisetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanTiedot(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajanTietoDto> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getKayttajanTiedot(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} [kunta] 
         * @param {Array<string>} [oppilaitostyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijat(kunta?: Array<string>, oppilaitostyyppi?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisaatioLaajaDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getKoulutustoimijat(kunta, oppilaitostyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLokalisoinnit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<LokalisointiDto>; }> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getLokalisoinnit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiokoulutusperuste(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteDto> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getLukiokoulutusperuste(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiokoulutusperusteet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteInfoDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getLukiokoulutusperusteet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} kuntaIdt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiot(kuntaIdt: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getLukiot(kuntaIdt, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} kuntaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiotByKuntaId(kuntaId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getLukiotByKuntaId(kuntaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiotByOid(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getLukiotByOid(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} julkaisuhetki 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanJulkaisuhetkenPeruste(id: number, julkaisuhetki: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteDto> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getOpetussuunnitelmanJulkaisuhetkenPeruste(id, julkaisuhetki, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatio(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getOrganisaatio(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioRyhmat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getOrganisaatioRyhmat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary virkailijoiden haku
         * @param {Array<string>} [oid] organisaation oid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioVirkailijat(oid?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getOrganisaatioVirkailijat(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} kuntaIdt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruskoulut(kuntaIdt: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getPeruskoulut(kuntaIdt, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} kuntaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruskoulutByKuntaId(kuntaId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getPeruskoulutByKuntaId(kuntaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruskoulutByOid(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getPeruskoulutByOid(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusperuste(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteDto> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getPerusopetusperuste(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusperusteet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteInfoDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getPerusopetusperusteet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteInfoDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getPerusteet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [jalkeen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteet(jalkeen?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getTiedotteet(jalkeen, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary tiedotteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {Array<string>} [kieli] tiedotteen kieli
         * @param {string} [nimi] hae nimellä
         * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
         * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
         * @param {boolean} [julkinen] hae julkiset tiedotteet
         * @param {boolean} [yleinen] hae yleiset tiedotteet
         * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
         * @param {Array<number>} [perusteIds] tiedotteen perusteiden
         * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
         * @param {string} [jarjestys] tiedotteen jarjestys
         * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteetHaku(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, tiedoteJulkaisuPaikka?: Array<string>, perusteIds?: Array<number>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getTiedotteetHaku(sivu, sivukoko, kieli, nimi, perusteId, perusteeton, julkinen, yleinen, tiedoteJulkaisuPaikka, perusteIds, koulutusTyyppi, jarjestys, jarjestysNouseva, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrganisations(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getUserOrganisations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYlopsPeruste(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteDto> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getYlopsPeruste(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikkiKoodistonKoodit(koodisto: string, haku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).kaikkiKoodistonKoodit(koodisto, haku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        koodinAlarelaatiot(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).koodinAlarelaatiot(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        koodinYlarelaatiot(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).koodinYlarelaatiot(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yksiKoodistokoodi(koodisto: string, koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoodistoKoodiDto> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).yksiKoodistokoodi(koodisto, koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UlkopuolisetApi - factory interface
 * @export
 */
export const UlkopuolisetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanTiedot(oid: string, options?: any): AxiosPromise<KayttajanTietoDto> {
            return UlkopuolisetApiFp(configuration).getKayttajanTiedot(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} [kunta] 
         * @param {Array<string>} [oppilaitostyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustoimijat(kunta?: Array<string>, oppilaitostyyppi?: Array<string>, options?: any): AxiosPromise<Array<OrganisaatioLaajaDto>> {
            return UlkopuolisetApiFp(configuration).getKoulutustoimijat(kunta, oppilaitostyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLokalisoinnit(options?: any): AxiosPromise<{ [key: string]: Array<LokalisointiDto>; }> {
            return UlkopuolisetApiFp(configuration).getLokalisoinnit(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiokoulutusperuste(id: number, options?: any): AxiosPromise<PerusteDto> {
            return UlkopuolisetApiFp(configuration).getLukiokoulutusperuste(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiokoulutusperusteet(options?: any): AxiosPromise<Array<PerusteInfoDto>> {
            return UlkopuolisetApiFp(configuration).getLukiokoulutusperusteet(options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} kuntaIdt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiot(kuntaIdt: Array<string>, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getLukiot(kuntaIdt, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} kuntaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiotByKuntaId(kuntaId: string, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getLukiotByKuntaId(kuntaId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLukiotByOid(oid: string, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getLukiotByOid(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} julkaisuhetki 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmanJulkaisuhetkenPeruste(id: number, julkaisuhetki: number, options?: any): AxiosPromise<PerusteDto> {
            return UlkopuolisetApiFp(configuration).getOpetussuunnitelmanJulkaisuhetkenPeruste(id, julkaisuhetki, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatio(oid: string, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getOrganisaatio(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioRyhmat(options?: any): AxiosPromise<Array<object>> {
            return UlkopuolisetApiFp(configuration).getOrganisaatioRyhmat(options)(axios, basePath);
        },
        /**
         * 
         * @summary virkailijoiden haku
         * @param {Array<string>} [oid] organisaation oid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioVirkailijat(oid?: Array<string>, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getOrganisaatioVirkailijat(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} kuntaIdt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruskoulut(kuntaIdt: Array<string>, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getPeruskoulut(kuntaIdt, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} kuntaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruskoulutByKuntaId(kuntaId: string, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getPeruskoulutByKuntaId(kuntaId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruskoulutByOid(oid: string, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getPeruskoulutByOid(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusperuste(id: number, options?: any): AxiosPromise<PerusteDto> {
            return UlkopuolisetApiFp(configuration).getPerusopetusperuste(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusperusteet(options?: any): AxiosPromise<Array<PerusteInfoDto>> {
            return UlkopuolisetApiFp(configuration).getPerusopetusperusteet(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteet(options?: any): AxiosPromise<Array<PerusteInfoDto>> {
            return UlkopuolisetApiFp(configuration).getPerusteet(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [jalkeen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteet(jalkeen?: number, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getTiedotteet(jalkeen, options)(axios, basePath);
        },
        /**
         * 
         * @summary tiedotteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {Array<string>} [kieli] tiedotteen kieli
         * @param {string} [nimi] hae nimellä
         * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
         * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
         * @param {boolean} [julkinen] hae julkiset tiedotteet
         * @param {boolean} [yleinen] hae yleiset tiedotteet
         * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
         * @param {Array<number>} [perusteIds] tiedotteen perusteiden
         * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
         * @param {string} [jarjestys] tiedotteen jarjestys
         * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedotteetHaku(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, tiedoteJulkaisuPaikka?: Array<string>, perusteIds?: Array<number>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getTiedotteetHaku(sivu, sivukoko, kieli, nimi, perusteId, perusteeton, julkinen, yleinen, tiedoteJulkaisuPaikka, perusteIds, koulutusTyyppi, jarjestys, jarjestysNouseva, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrganisations(options?: any): AxiosPromise<Array<object>> {
            return UlkopuolisetApiFp(configuration).getUserOrganisations(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYlopsPeruste(id: number, options?: any): AxiosPromise<PerusteDto> {
            return UlkopuolisetApiFp(configuration).getYlopsPeruste(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikkiKoodistonKoodit(koodisto: string, haku?: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return UlkopuolisetApiFp(configuration).kaikkiKoodistonKoodit(koodisto, haku, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        koodinAlarelaatiot(koodi: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return UlkopuolisetApiFp(configuration).koodinAlarelaatiot(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        koodinYlarelaatiot(koodi: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return UlkopuolisetApiFp(configuration).koodinYlarelaatiot(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yksiKoodistokoodi(koodisto: string, koodi: string, options?: any): AxiosPromise<KoodistoKoodiDto> {
            return UlkopuolisetApiFp(configuration).yksiKoodistokoodi(koodisto, koodi, options)(axios, basePath);
        },
    };
};

/**
 * UlkopuolisetApi - object-oriented interface
 * @export
 * @class UlkopuolisetApi
 * @extends {BaseAPI}
 */
export class UlkopuolisetApi extends BaseAPI {
    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getKayttajanTiedot(oid: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getKayttajanTiedot(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} [kunta] 
     * @param {Array<string>} [oppilaitostyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getKoulutustoimijat(kunta?: Array<string>, oppilaitostyyppi?: Array<string>, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getKoulutustoimijat(kunta, oppilaitostyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getLokalisoinnit(options?: any) {
        return UlkopuolisetApiFp(this.configuration).getLokalisoinnit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getLukiokoulutusperuste(id: number, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getLukiokoulutusperuste(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getLukiokoulutusperusteet(options?: any) {
        return UlkopuolisetApiFp(this.configuration).getLukiokoulutusperusteet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} kuntaIdt 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getLukiot(kuntaIdt: Array<string>, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getLukiot(kuntaIdt, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} kuntaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getLukiotByKuntaId(kuntaId: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getLukiotByKuntaId(kuntaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getLukiotByOid(oid: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getLukiotByOid(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} julkaisuhetki 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getOpetussuunnitelmanJulkaisuhetkenPeruste(id: number, julkaisuhetki: number, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getOpetussuunnitelmanJulkaisuhetkenPeruste(id, julkaisuhetki, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getOrganisaatio(oid: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getOrganisaatio(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getOrganisaatioRyhmat(options?: any) {
        return UlkopuolisetApiFp(this.configuration).getOrganisaatioRyhmat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary virkailijoiden haku
     * @param {Array<string>} [oid] organisaation oid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getOrganisaatioVirkailijat(oid?: Array<string>, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getOrganisaatioVirkailijat(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} kuntaIdt 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getPeruskoulut(kuntaIdt: Array<string>, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getPeruskoulut(kuntaIdt, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} kuntaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getPeruskoulutByKuntaId(kuntaId: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getPeruskoulutByKuntaId(kuntaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getPeruskoulutByOid(oid: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getPeruskoulutByOid(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getPerusopetusperuste(id: number, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getPerusopetusperuste(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getPerusopetusperusteet(options?: any) {
        return UlkopuolisetApiFp(this.configuration).getPerusopetusperusteet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getPerusteet(options?: any) {
        return UlkopuolisetApiFp(this.configuration).getPerusteet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [jalkeen] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getTiedotteet(jalkeen?: number, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getTiedotteet(jalkeen, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary tiedotteiden haku
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {Array<string>} [kieli] tiedotteen kieli
     * @param {string} [nimi] hae nimellä
     * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
     * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
     * @param {boolean} [julkinen] hae julkiset tiedotteet
     * @param {boolean} [yleinen] hae yleiset tiedotteet
     * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
     * @param {Array<number>} [perusteIds] tiedotteen perusteiden
     * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
     * @param {string} [jarjestys] tiedotteen jarjestys
     * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getTiedotteetHaku(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, tiedoteJulkaisuPaikka?: Array<string>, perusteIds?: Array<number>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getTiedotteetHaku(sivu, sivukoko, kieli, nimi, perusteId, perusteeton, julkinen, yleinen, tiedoteJulkaisuPaikka, perusteIds, koulutusTyyppi, jarjestys, jarjestysNouseva, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getUserOrganisations(options?: any) {
        return UlkopuolisetApiFp(this.configuration).getUserOrganisations(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getYlopsPeruste(id: number, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getYlopsPeruste(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} [haku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public kaikkiKoodistonKoodit(koodisto: string, haku?: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).kaikkiKoodistonKoodit(koodisto, haku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public koodinAlarelaatiot(koodi: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).koodinAlarelaatiot(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public koodinYlarelaatiot(koodi: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).koodinYlarelaatiot(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public yksiKoodistokoodi(koodisto: string, koodi: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).yksiKoodistokoodi(koodisto, koodi, options)(this.axios, this.basePath);
    }

}


/**
 * VuosiluokkakokonaisuudetApi - axios parameter creator
 * @export
 */
export const VuosiluokkakokonaisuudetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {VuosiluokkakokonaisuusDto} VuosiluokkakokonaisuusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(opsId: number, VuosiluokkakokonaisuusDto: VuosiluokkakokonaisuusDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling add.');
            }
            // verify required parameter 'VuosiluokkakokonaisuusDto' is not null or undefined
            if (VuosiluokkakokonaisuusDto === null || VuosiluokkakokonaisuusDto === undefined) {
                throw new RequiredError('VuosiluokkakokonaisuusDto','Required parameter VuosiluokkakokonaisuusDto was null or undefined when calling add.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof VuosiluokkakokonaisuusDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(VuosiluokkakokonaisuusDto !== undefined ? VuosiluokkakokonaisuusDto : {}) : (VuosiluokkakokonaisuusDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVuosiluokkakokonaisuus(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling deleteVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteVuosiluokkakokonaisuus.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOppiaineet(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling findOppiaineet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findOppiaineet.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet/{id}/oppiaineet`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tunniste 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjanVuosiluokkakokonaisuus(opsId: number, tunniste: string, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getPohjanVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'tunniste' is not null or undefined
            if (tunniste === null || tunniste === undefined) {
                throw new RequiredError('tunniste','Required parameter tunniste was null or undefined when calling getPohjanVuosiluokkakokonaisuus.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet/{tunniste}/pohja`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"tunniste"}}`, encodeURIComponent(String(tunniste)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkakokonaisuudenPerusteSisalto(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getVuosiluokkakokonaisuudenPerusteSisalto.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVuosiluokkakokonaisuudenPerusteSisalto.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet/{id}/peruste`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkakokonaisuus(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling getVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVuosiluokkakokonaisuus.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiVuosiluokkakokonaisuusMuokattavaksi(opsId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling kopioiVuosiluokkakokonaisuusMuokattavaksi.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling kopioiVuosiluokkakokonaisuusMuokattavaksi.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet/{id}/muokattavakopio`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} vuosiluokkakokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOppiaine(opsId: number, oppiaineId: number, vuosiluokkakokonaisuusId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling palautaOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling palautaOppiaine.');
            }
            // verify required parameter 'vuosiluokkakokonaisuusId' is not null or undefined
            if (vuosiluokkakokonaisuusId === null || vuosiluokkakokonaisuusId === undefined) {
                throw new RequiredError('vuosiluokkakokonaisuusId','Required parameter vuosiluokkakokonaisuusId was null or undefined when calling palautaOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet/{vuosiluokkakokonaisuusId}/{oppiaineId}/palauta`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"vuosiluokkakokonaisuusId"}}`, encodeURIComponent(String(vuosiluokkakokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} vuosiluokkakokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        piilotaOppiaine(opsId: number, oppiaineId: number, vuosiluokkakokonaisuusId: number, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling piilotaOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling piilotaOppiaine.');
            }
            // verify required parameter 'vuosiluokkakokonaisuusId' is not null or undefined
            if (vuosiluokkakokonaisuusId === null || vuosiluokkakokonaisuusId === undefined) {
                throw new RequiredError('vuosiluokkakokonaisuusId','Required parameter vuosiluokkakokonaisuusId was null or undefined when calling piilotaOppiaine.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet/{vuosiluokkakokonaisuusId}/{oppiaineId}/piilota`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"vuosiluokkakokonaisuusId"}}`, encodeURIComponent(String(vuosiluokkakokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {VuosiluokkakokonaisuusDto} VuosiluokkakokonaisuusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkakokonaisuus(opsId: number, id: number, VuosiluokkakokonaisuusDto: VuosiluokkakokonaisuusDto, options: any = {}): RequestArgs {
            // verify required parameter 'opsId' is not null or undefined
            if (opsId === null || opsId === undefined) {
                throw new RequiredError('opsId','Required parameter opsId was null or undefined when calling updateVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'VuosiluokkakokonaisuusDto' is not null or undefined
            if (VuosiluokkakokonaisuusDto === null || VuosiluokkakokonaisuusDto === undefined) {
                throw new RequiredError('VuosiluokkakokonaisuusDto','Required parameter VuosiluokkakokonaisuusDto was null or undefined when calling updateVuosiluokkakokonaisuus.');
            }
            const localVarPath = `/api/opetussuunnitelmat/{opsId}/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"opsId"}}`, encodeURIComponent(String(opsId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof VuosiluokkakokonaisuusDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(VuosiluokkakokonaisuusDto !== undefined ? VuosiluokkakokonaisuusDto : {}) : (VuosiluokkakokonaisuusDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VuosiluokkakokonaisuudetApi - functional programming interface
 * @export
 */
export const VuosiluokkakokonaisuudetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {VuosiluokkakokonaisuusDto} VuosiluokkakokonaisuusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(opsId: number, VuosiluokkakokonaisuusDto: VuosiluokkakokonaisuusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VuosiluokkakokonaisuusDto> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).add(opsId, VuosiluokkakokonaisuusDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVuosiluokkakokonaisuus(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).deleteVuosiluokkakokonaisuus(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOppiaineet(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OppiaineDto>> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).findOppiaineet(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tunniste 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjanVuosiluokkakokonaisuus(opsId: number, tunniste: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsVuosiluokkakokonaisuusDto> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).getPohjanVuosiluokkakokonaisuus(opsId, tunniste, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkakokonaisuudenPerusteSisalto(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteVuosiluokkakokonaisuusDto> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).getVuosiluokkakokonaisuudenPerusteSisalto(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkakokonaisuus(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsVuosiluokkakokonaisuusDto> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).getVuosiluokkakokonaisuus(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiVuosiluokkakokonaisuusMuokattavaksi(opsId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsVuosiluokkakokonaisuusDto> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).kopioiVuosiluokkakokonaisuusMuokattavaksi(opsId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} vuosiluokkakokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOppiaine(opsId: number, oppiaineId: number, vuosiluokkakokonaisuusId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).palautaOppiaine(opsId, oppiaineId, vuosiluokkakokonaisuusId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} vuosiluokkakokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        piilotaOppiaine(opsId: number, oppiaineId: number, vuosiluokkakokonaisuusId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).piilotaOppiaine(opsId, oppiaineId, vuosiluokkakokonaisuusId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {VuosiluokkakokonaisuusDto} VuosiluokkakokonaisuusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkakokonaisuus(opsId: number, id: number, VuosiluokkakokonaisuusDto: VuosiluokkakokonaisuusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnwrappedOpsVuosiluokkakokonaisuusDto> {
            const localVarAxiosArgs = VuosiluokkakokonaisuudetApiAxiosParamCreator(configuration).updateVuosiluokkakokonaisuus(opsId, id, VuosiluokkakokonaisuusDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VuosiluokkakokonaisuudetApi - factory interface
 * @export
 */
export const VuosiluokkakokonaisuudetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} opsId 
         * @param {VuosiluokkakokonaisuusDto} VuosiluokkakokonaisuusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(opsId: number, VuosiluokkakokonaisuusDto: VuosiluokkakokonaisuusDto, options?: any): AxiosPromise<VuosiluokkakokonaisuusDto> {
            return VuosiluokkakokonaisuudetApiFp(configuration).add(opsId, VuosiluokkakokonaisuusDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVuosiluokkakokonaisuus(opsId: number, id: number, options?: any): AxiosPromise<void> {
            return VuosiluokkakokonaisuudetApiFp(configuration).deleteVuosiluokkakokonaisuus(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOppiaineet(opsId: number, id: number, options?: any): AxiosPromise<Array<OppiaineDto>> {
            return VuosiluokkakokonaisuudetApiFp(configuration).findOppiaineet(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {string} tunniste 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjanVuosiluokkakokonaisuus(opsId: number, tunniste: string, options?: any): AxiosPromise<UnwrappedOpsVuosiluokkakokonaisuusDto> {
            return VuosiluokkakokonaisuudetApiFp(configuration).getPohjanVuosiluokkakokonaisuus(opsId, tunniste, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkakokonaisuudenPerusteSisalto(opsId: number, id: number, options?: any): AxiosPromise<PerusteVuosiluokkakokonaisuusDto> {
            return VuosiluokkakokonaisuudetApiFp(configuration).getVuosiluokkakokonaisuudenPerusteSisalto(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkakokonaisuus(opsId: number, id: number, options?: any): AxiosPromise<UnwrappedOpsVuosiluokkakokonaisuusDto> {
            return VuosiluokkakokonaisuudetApiFp(configuration).getVuosiluokkakokonaisuus(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiVuosiluokkakokonaisuusMuokattavaksi(opsId: number, id: number, options?: any): AxiosPromise<UnwrappedOpsVuosiluokkakokonaisuusDto> {
            return VuosiluokkakokonaisuudetApiFp(configuration).kopioiVuosiluokkakokonaisuusMuokattavaksi(opsId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} vuosiluokkakokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOppiaine(opsId: number, oppiaineId: number, vuosiluokkakokonaisuusId: number, options?: any): AxiosPromise<void> {
            return VuosiluokkakokonaisuudetApiFp(configuration).palautaOppiaine(opsId, oppiaineId, vuosiluokkakokonaisuusId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} oppiaineId 
         * @param {number} vuosiluokkakokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        piilotaOppiaine(opsId: number, oppiaineId: number, vuosiluokkakokonaisuusId: number, options?: any): AxiosPromise<void> {
            return VuosiluokkakokonaisuudetApiFp(configuration).piilotaOppiaine(opsId, oppiaineId, vuosiluokkakokonaisuusId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} opsId 
         * @param {number} id 
         * @param {VuosiluokkakokonaisuusDto} VuosiluokkakokonaisuusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkakokonaisuus(opsId: number, id: number, VuosiluokkakokonaisuusDto: VuosiluokkakokonaisuusDto, options?: any): AxiosPromise<UnwrappedOpsVuosiluokkakokonaisuusDto> {
            return VuosiluokkakokonaisuudetApiFp(configuration).updateVuosiluokkakokonaisuus(opsId, id, VuosiluokkakokonaisuusDto, options)(axios, basePath);
        },
    };
};

/**
 * VuosiluokkakokonaisuudetApi - object-oriented interface
 * @export
 * @class VuosiluokkakokonaisuudetApi
 * @extends {BaseAPI}
 */
export class VuosiluokkakokonaisuudetApi extends BaseAPI {
    /**
     * 
     * @param {number} opsId 
     * @param {VuosiluokkakokonaisuusDto} VuosiluokkakokonaisuusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public add(opsId: number, VuosiluokkakokonaisuusDto: VuosiluokkakokonaisuusDto, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).add(opsId, VuosiluokkakokonaisuusDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public deleteVuosiluokkakokonaisuus(opsId: number, id: number, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).deleteVuosiluokkakokonaisuus(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public findOppiaineet(opsId: number, id: number, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).findOppiaineet(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {string} tunniste 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public getPohjanVuosiluokkakokonaisuus(opsId: number, tunniste: string, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).getPohjanVuosiluokkakokonaisuus(opsId, tunniste, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public getVuosiluokkakokonaisuudenPerusteSisalto(opsId: number, id: number, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).getVuosiluokkakokonaisuudenPerusteSisalto(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public getVuosiluokkakokonaisuus(opsId: number, id: number, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).getVuosiluokkakokonaisuus(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public kopioiVuosiluokkakokonaisuusMuokattavaksi(opsId: number, id: number, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).kopioiVuosiluokkakokonaisuusMuokattavaksi(opsId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} vuosiluokkakokonaisuusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public palautaOppiaine(opsId: number, oppiaineId: number, vuosiluokkakokonaisuusId: number, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).palautaOppiaine(opsId, oppiaineId, vuosiluokkakokonaisuusId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} oppiaineId 
     * @param {number} vuosiluokkakokonaisuusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public piilotaOppiaine(opsId: number, oppiaineId: number, vuosiluokkakokonaisuusId: number, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).piilotaOppiaine(opsId, oppiaineId, vuosiluokkakokonaisuusId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} opsId 
     * @param {number} id 
     * @param {VuosiluokkakokonaisuusDto} VuosiluokkakokonaisuusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VuosiluokkakokonaisuudetApi
     */
    public updateVuosiluokkakokonaisuus(opsId: number, id: number, VuosiluokkakokonaisuusDto: VuosiluokkakokonaisuusDto, options?: any) {
        return VuosiluokkakokonaisuudetApiFp(this.configuration).updateVuosiluokkakokonaisuus(opsId, id, VuosiluokkakokonaisuusDto, options)(this.axios, this.basePath);
    }

}


